import React, { useState } from "react";

import { Link } from "react-router-dom";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";

import { useAuth } from "../context/auth";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const LoginForm = ({ onLogin }) => {
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/api/login', formData);
      const { role } = response.data;
      toast.success(`Login successful!`, {
        position: `top-right`,
        autoClose: 2000,
        onClose: () => {
          if (role === 'admin') {
            onLogin(true); // Call onLogin callback with true for admin login
            navigate('/');

          }
        }
      });
    } catch (error) {
      console.error(error);
      toast.error(`Login failed. Please check your credentials.`, { position: `top-right` });
    }
  };

  const handleLogout = () => {
    // Perform logout actions here
    navigate('/');
  };
  return (
    <form onSubmit={handleSubmit}
      className="form_main"
      style={{ alignItems: "center" }}
    // onSubmit={handleSubmit}
    >
      <p className="heading">Login</p>

      <div className="inputContainer">
        <svg
          className="inputIcon"
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="#2e2e2e"
          viewBox="0 0 16 16"
        >
          {/* SVG path here */}
        </svg>
        <input
          type="text"
          placeholder="email"
          className="inputField"

          value={formData.email}
          onChange={handleChange}

          required
        />
        <input type="email" name="email" placeholder="Email" value={formData.email} onChange={handleChange} required />
      </div>

      <div className="inputContainer">
        <svg
          className="inputIcon"
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="#2e2e2e"
          viewBox="0 0 16 16"
        >
          {/* SVG path here */}
        </svg>
        <input
          type="password"
          className="inputField"
          name="password"
          placeholder="password"
          value={formData.password}
          onChange={handleChange}
          required

        />
      </div>

      <button type="submit" id="button" >
        Submit
      </button>

      {/* <a className="forgotLink" href="#">
        Forgot your password?
      </a> */}
      <ToastContainer position="top-right" autoClose={5000} />
      <Link to='/register'>Register</Link>
    </form>
  );
};

export default LoginForm
