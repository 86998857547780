// App.js

import React from "react";
import "../placement.css";

const data = [
  {
    id: 1,
    name: "LAVINA YOGI",
    course: "Interior Design",
    companyName: "Cad Desk Jaipur",
    image:
      "https://caddeskindia.com/wp-content/uploads/2021/01/lavina-yogi.png", // Replace with actual image URL
  },
  {
    id: 2,
    name: "ROHIT SAXENA",
    course: "AutoCAD, 3ds Max, Revit Architecture (Master Diploma)",
    companyName: "Cad Desk Jaipur",
    image:
      "https://caddeskindia.com/wp-content/uploads/2021/01/rohitsexena.png", // Replace with actual image URL
  },
  {
    id: 3,
    name: "SURESH SHARMA",
    course: "AutoCAD",
    companyName: "Cad Desk Jaipur",
    image:
      "https://caddeskindia.com/wp-content/uploads/2021/01/sureshsharma.png", // Replace with actual image URL
  },
  {
    id: 4,
    name: "RAGHU NANDAN SHARMA",
    course: "AutoCAD",
    companyName: "Cad Desk Jaipur",
    image:
      "https://caddeskindia.com/wp-content/uploads/2021/01/ragunandsharma.png", // Replace with actual image URL
  },
  {
    id: 5,
    name: "LOKESH SHARMA",
    course: "AutoCAD",
    companyName: "Cad Desk Jaipur",
    image:
      "https://caddeskindia.com/wp-content/uploads/2021/01/lokeshsharma.png", // Replace with actual image URL
  },
  {
    id: 6,
    name: " LAXMIKANT PRAJAPATI",
    course: "AutoCAD",
    companyName: "Cad Desk Jaipur",
    image:
      "https://caddeskindia.com/wp-content/uploads/2021/01/lakshmikant.png", // Replace with actual image URL
  },
  {
    id: 7,
    name: "EKTA SHARMA",
    course: "Sketch Up",
    companyName: "Cad Desk Jaipur",
    image: "https://caddeskindia.com/wp-content/uploads/2021/01/ektasharma.png", // Replace with actual image URL
  },
  {
    id: 8,
    name: "RAJESH MAHERA",
    course: "AutoCAD, Staad Pro",
    companyName: "Cad Desk Jaipur",
    image:
      "https://caddeskindia.com/wp-content/uploads/2021/01/rajeshmehara.png", // Replace with actual image URL
  },
  {
    id: 9,
    name: "LALIT",
    course: "AutoCAD, Staad Pro, 3Ds Max",
    companyName: "Cad Desk Jaipur",
    image: "https://caddeskindia.com/wp-content/uploads/2021/01/lalit.png", // Replace with actual image URL
  },
  {
    id: 10,
    name: "SHUBHAM SHUKLA",
    course: "AutoCAD, 3Ds Max",
    companyName: "Cad Desk Jaipur",
    image:
      "https://caddeskindia.com/wp-content/uploads/2021/01/subhamsukala.png", // Replace with actual image URL
  },
  // Repeat similar data for other cards
];

const Card = ({ id, name, course, companyName, image }) => (
  <div className="card" key={id}>
    <img src={image} alt={`${name}'s profile`} className="card-image" />
    <div className="card-content">
      <h3 className="card-name">{name}</h3>
      <p className="card-details">{course}</p>
      <p className="card-details">{companyName}</p>
    </div>
  </div>
);

const OurPlacement = () => {
  return (
    <div className="ourplacement-container">
      {data.map((cardData) => (
        <Card {...cardData} />
      ))}
    </div>
  );
};

export default OurPlacement;
