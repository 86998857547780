import React, { useEffect } from "react";
import "../Feedback.css";
import TrendingCourses from "./../pages/Trendingcourse";

function Reversemove() {
  useEffect(() => {
    const scrollers = document.querySelectorAll(".scroller");

    // If a user hasn't opted in for reduced motion, then we add the animation
    if (!window.matchMedia("(prefers-reduced-motion: reduce)").matches) {
      addAnimation();
    }

    function addAnimation() {
      scrollers.forEach((scroller) => {
        // add data-animated="true" to every .scroller on the page
        scroller.setAttribute("data-animated", true);

        // Make an array from the elements within .scroller-inner
        const scrollerInner = scroller.querySelector(".scroller__inner");
        const scrollerContent = Array.from(scrollerInner.children);

        // For each item in the array, clone it
        // add aria-hidden to it
        // add it into the .scroller-inner
        scrollerContent.forEach((item) => {
          const duplicatedItem = item.cloneNode(true);
          duplicatedItem.setAttribute("aria-hidden", true);
          scrollerInner.appendChild(duplicatedItem);
        });
      });
    }
  }, []); // Empty dependency array ensures that useEffect runs only once after the initial render

  return (
    <>
      <h1 className="display-5 py-3" style={{ textAlign: "center" }}>
        What Our People Says
      </h1>
      <div className="scroller txt" data-speed="slow">
        <div className=" tag-list scroller__inner">
          <li>
            <img
              src="img/feedbackperson1.png"
              alt=""
              className="feed_back_image"
            />
            <div>
              <p>
                {" "}
                I was fresher with 0 knowledge after coming here I gained lots
                of knowledge with best experience and we can lead best job from
                Them
              </p>
            </div>
          </li>
          <li>
            <img
              src="img/feedbackperson2.png"
              alt=""
              className="feed_back_image"
            />
            <div>
              <p>
                A very good experience by the industrial class done here,team
                for an such a good teaching i am very Happy to CAD DESK Bangolre
                Mahadevapura
              </p>
            </div>
          </li>
          <li>
            <img
              src="img/feedbackperson3.png"
              alt=""
              className="feed_back_image"
            />
            <div>
              <p>
                It was good to learn under CAD desk Mahadevapura, and we had a
                great experience and knowledge about AUTO CAD security.We
                learned many new things
              </p>
            </div>
          </li>
          <li>
            <img src="img/feed1.jpeg" alt="" className="feed_back_image" />
            <div>
              <p>
                We had a very good experience in the industrial visit class,
                thank you for spending your precious time with us and for
                teaching us too
              </p>
            </div>
          </li>
          <li>
            <img
              src="img/feedbackperson5.png"
              alt=""
              className="feed_back_image"
            />
            <div>
              <p>
                It was a good experience with this industrial classes, Thankyou
                for teaching as we have learnt a lot of information in Catia
              </p>
            </div>
          </li>
          <li>
            <img src="img/feed2.jpeg" alt="" className="feed_back_image" />
            <div>
              <p>
                It was good class.We are greatefull for this experience you'll
                offered usThankyou for your knowledge,time and the efforts
                you'll took to guide
              </p>
            </div>
          </li>
        </div>
      </div>

      <div className="scroller txt" data-direction="right" data-speed="slow">
        <div className="scroller__inner">
          <div className="feed_back_item">
            <img src="img/feed3.jpeg" alt="" className="feed_back_image" />
            <div>
              <p>
                A very good experience by the industrial class done here ,
                thanks to the CAD desk Mahadevpura team for an such a good
                teaching{" "}
              </p>
            </div>
          </div>

          <div className="feed_back_item">
            <img src="img/feed4.jpeg" alt="" className="feed_back_image" />
            <div>
              <p>
                The classes taken by the by the experts in cyber security field
                was great and it was useful to visualize on future progress
              </p>
            </div>
          </div>

          <div className="feed_back_item">
            <img src="img/feed6.jpeg" alt="" className="feed_back_image" />
            <div>
              <p>
                Good experience and environment. The way of teaching the topic,
                explaining the projects and intership is helpful for us.
              </p>
            </div>
          </div>

          <div className="feed_back_item">
            <img
              src="img/feedbackperson10.png"
              alt=""
              className="feed_back_image"
            />
            <div>
              <p>
                It was a good experience and explanation of topics by the
                teachers was good. And nice friendly environment
              </p>
            </div>
          </div>

          <div className="feed_back_item">
            <img src="img/feed5.jpeg" alt="" className="feed_back_image" />
            <div>
              <p>
                The the industrial classes by taken by experts in cyber security
                field was good and it was a very useful
              </p>
            </div>
          </div>

          <div className="feed_back_item">
            <img
              src="img/feedbackperson12.jpg"
              alt=""
              className="feed_back_image"
            />
            <div>
              <p>
                I have learned more topics about Creo and good teaching Thankyou
                for this good teaching
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Reversemove;
