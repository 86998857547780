import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";

const SignUp = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(
        `http://localhost:8081/api/v1/auth/register`,
        {
          name,
          email,
          password,
          phone,
          address,
        }
      );
      if (res.data.success) {
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error("something wrong");
    }
  };

  return (
    <form
      className="form_main"
      style={{ alignItems: "center", paddingBottom: "-20px" }}
      onSubmit={handleSubmit}
    >
      <p className="heading">Sign-Up</p>

      <div className="inputContainer">
        <svg
          className="inputIcon"
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="#2e2e2e"
          viewBox="0 0 16 16"
        >
          {/* SVG path here */}
        </svg>
        <input
          type="text"
          className="inputField"
          value={name}
          placeholder="Name"
          onChange={(e) => setName(e.target.value)}
          required
        />
      </div>

      <div className="inputContainer">
        <svg
          className="inputIcon"
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="#2e2e2e"
          viewBox="0 0 16 16"
        >
          {/* SVG path here */}
        </svg>
        <input
          type="text"
          className="inputField"
          value={phone}
          placeholder="91-------"
          onChange={(e) => setPhone(e.target.value)}
          required
        />
      </div>

      <div className="inputContainer">
        <svg
          className="inputIcon"
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="#2e2e2e"
          viewBox="0 0 16 16"
        >
          {/* SVG path here */}
        </svg>
        <input
          type="text"
          className="inputField"
          value={email}
          placeholder="Email"
          onChange={(e) => setEmail(e.target.value)}
          required
        />
      </div>

      <div className="inputContainer">
        <svg
          className="inputIcon"
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="#2e2e2e"
          viewBox="0 0 16 16"
        >
          {/* SVG path here */}
        </svg>
        <input
          type="password"
          className="inputField"
          value={password}
          placeholder="password"
          onChange={(e) => setPassword(e.target.value)}
          required
        />
      </div>

      <button type="submit" id="button">
        Submit
      </button>
    </form>
  );
};

export default SignUp;
