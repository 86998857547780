import React from 'react'

import Certificate1 from '../Components/Certificate1'
import CertificateGenerator from '../Components/CertificateGenerator'


import CourseStudentList from './CourseStudentList'


const Certificate = () => {
  return (
    <div>
     <Certificate1 />
        <CertificateGenerator />
        {/* <Dashbord1 /> */}
        {/* <StudentForm /> */}
        <CourseStudentList />
   
       
    </div>
  )
}

export default Certificate
