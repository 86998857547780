import React, { useState, useEffect } from 'react';


import './Certificate.css';
import PropTypes from 'prop-types'; // Import PropTypes




function CertificateGenerator({ firstName, CollegeName, FATHER_NAME, REG_NO, coursename, certificate_type, yop, coursecertificatr, GENDER, Role }) {
  console.log("Received props:", { firstName, CollegeName, FATHER_NAME, REG_NO, coursename, certificate_type, yop, GENDER, Role });
  const defaultImage = '/image/default.png';
  const [selectedDate, setSelectedDate] = useState('');

  const getImageSource = (regNo) => {
    return `/image/${regNo}.jpg`; // Assuming the image file format is .jpg
  };



 


  




  return (

    <>
      <div className="card shadow" style={{width:"400px"}}>
        <div style={{ textAlign: "justify" }}>
          <img className='image' src={getImageSource(REG_NO) || defaultImage} alt="Student" />
          <br />
          <br />
          <p><span className="detail-label">NAME:</span> {firstName}</p>
          <p><span className="detail-label">REG_NO:</span> {REG_NO}</p>
          <p><span className="detail-label">FATHER_NAME:</span> {FATHER_NAME}</p>
          <p><span className="detail-label">COURSETITLE:</span> {coursename}</p>
          <p><span className="detail-label">COOLEGENAME:</span> {CollegeName}</p>
          <p><span className="detail-label">CERTIFICATION:</span> {certificate_type}</p>
          <p><span className="detail-label">YOP:</span> {yop}</p>
          <p><span className="detail-label">COURSECERTIFICATION:</span> {coursecertificatr}</p>
          <p><span className="detail-label">GENDER:</span> {GENDER}</p>
          <p><span className="detail-label">Role:</span> {Role}</p>
        


        </div>
       
      </div>

    </>
  );
}

CertificateGenerator.propTypes = {
  firstName: PropTypes.string.isRequired,
  REG_NO: PropTypes.string.isRequired,
  FATHER_NAME: PropTypes.string.isRequired,
  coursename: PropTypes.string.isRequired,
  CollegeName: PropTypes.string.isRequired,
  Course_Title: PropTypes.string.isRequired,


  certificate_type: PropTypes.string.isRequired,
  yop: PropTypes.string.isRequired
};

export default CertificateGenerator;
