import React, { Fragment } from "react";

import { Link } from "react-router-dom";
const handleButtonClick = () => {
  window.scrollTo(0, 0);
};
const Footer = () => {
  return (
    <Fragment>
      <footer>

        <div className="container-fluid py-1">
          <div className="row py-xl-1 py-sm-1">
            <div className="col-lg-6 map">
              <h2 className="contact-title text-capitalize text-white font-weight-light mb-sm-4 mb-2">
                our
                <span className="font-weight-bold">directions</span>
              </h2>
              {/* <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15550.267934575879!2d77.6987375!3d12.9995245!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae11c247a3c025%3A0xb8afde30270c76bb!2sITDESK%20MAHADEVPURA!5e0!3m2!1sen!2sin!4v1690535063147!5m2!1sen!2sin"></iframe> */}
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.5670664397067!2d77.69618407397932!3d12.999519214267812!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae11e5beced50b%3A0x6dfbb6145c02cc8b!2sExcerpt%20Technologies%20Private%20Limited!5e0!3m2!1sen!2sin!4v1689927971916!5m2!1sen!2sin"></iframe>
              {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3023.495758873587!2d-74.0005340845242!3d40.72911557933012!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25991b7473067%3A0x59fbd02f7b519ce8!2s550+LaGuardia+Pl%2C+New+York%2C+NY+10012%2C+USA!5e0!3m2!1sen!2sin!4v1516166447283"></iframe> */}
              <div
                className="conta-posi-w3ls p-4 rounded"
                style={{ backgroundColor: "#ffc107;" }}
              >
                <h5 className="text-white font-weight-bold mb-3">Address</h5>
                <p>
                  ITI Estate, Whitefield Road, Mahadevpura, Bengaluru 560048.
                </p>
              </div>
            </div>

            <div className="col-lg-6 contact-agileits-w3layouts mt-lg-0 mt-4">
              <div style={{ marginTop: "50px" }} className="myfooter">
                {" "}
                <h2 className="contact-title text-capitalize text-white font-weight-light mb-sm-4 mb-3">
                  Get
                  <span className="font-weight-bold"> in touch</span>
                </h2>
                <p className="para-agileits-w3layouts ">
                  {" "}
                  <b>
                    {" "}
                    <i
                      className="fas fa-map-marker-alt pr-3"
                      style={{ color: "#ffc107" }}
                    >
                      {" "}
                    </i>
                    <b className="text-white" style={{ marginLeft: "5px" }}>
                      {" "}
                      CAD Desk Mahadevpura
                    </b>
                  </b>
                </p>
                <p className="para-agileits-w3layouts text-white  my-sm-3 my-2">
                  <b>
                    {" "}
                    <i className="fas fa-phone pr-3" style={{ color: "#ffc107" }}></i>
                    <b
                      className="text-white"
                      style={{ marginLeft: "5px" }}
                    ></b>{" "}
                    +91 9901371386
                  </b>
                </p>
                <p className="para-agileits-w3layouts">
                  <i className="far fa-envelope-open pr-2" style={{ color: "#ffc107" }}></i>
                  <b>
                    {" "}
                    <a
                      href="mailto:mail@example.com"
                      className=" text-blue text-white "
                    >
                      <b
                        className="text-white"
                        style={{ marginLeft: "5px" }}
                      ></b>{" "}
                      info@caddeskmahadevpura.com
                    </a>
                  </b>
                </p>
              </div>
            </div>
          </div>

          <div
            className="container-fluid copyright py-4"
            style={{ alignItems: "center" }}
          >
            <div className="container">
              <div className="row">
                <div className="col-md-4 text-center text-md-start mb-3 mb-md-0">
                  <p className="mb-0 text-white">
                    <Link className="" to="#">
                      CAD Desk Mahadevpura{" "}
                    </Link>{" "}
                    &copy; 2023 All Rights Reserved.
                  </p>
                </div>
                <div className="col-md-4 copyright-btn text-center text-md-start mb-3 mb-md-0 flex-shrink-0">
                  <Link
                    className="btn btn-primary rounded-circle me-3 copyright-icon"
                    to="https://www.instagram.com/it_desk_cad_desk_mahadevpura/?igshid=MzRlODBiNWFlZA%3D%3D"
                  >
                    <i className="fab fa-instagram"></i>
                  </Link>
                  <Link
                    className="btn btn-primary rounded-circle me-3 copyright-icon"
                    to="https://www.facebook.com/CADDeskMahadevpura/?paipv=0&eav=Afa6zv96PoPRdnOYHc1nq_Z187ltdbcgBfBvKYjr5PybUF9VmfQn6oW-jWFQdvckUYk&_rdr"
                  >
                    <i className="fab fa-facebook-f"></i>
                  </Link>
                  <Link
                    className="btn btn-primary rounded-circle me-3 copyright-icon"
                    to="#"
                  >
                    <i className="fab fa-youtube"></i>
                  </Link>
                </div>
                <div className="col-md-4 my-auto text-center text-md-end text-white">
                  Designed By{" "}
                  <Link
                    className="border-bottom"
                    to="https://www.excerptech.com/"
                    target="_blank"
                  >
                    Excerpt Technologies PVT.LTD.
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="copyright-agiles py-3">
                 
                        <div className="row">
                            <p className="col-lg-8 copy-right-grids text-white text-lg-left text-center mt-lg-1">© info@itdeskmahadevpura.com
                                <a href="https://w3layouts.com/" target="_blank">  IT Desk</a>
                            </p>

                            <div className="social-icons text-lg-right text-center col-lg-4 mt-lg-0 mt-3">
                                <ul className="list-unstyled">
                                    <li>
                                        <a href="https://www.facebook.com/ITDESKMahadevpura/" className="fab fa-facebook-f icon-border facebook rounded-circle"> </a>
                                    </li>
                                    <li className="mx-2">
                                        <a href="#" className="fab fa-instagram icon-border instagram rounded-circle"> </a>
                                    </li>
                                    <li>
                                        <a href="https://itdeskmahadevpura.com/" className="fab fa-google-plus-g icon-border googleplus rounded-circle"> </a>
                                    </li>
                                    <li className="ml-2">
                                        <a href="#" class="fab fa-youtube icon-border youtube rounded-circle"> </a>
                                    </li>
                                </ul>
                            </div>

                        </div> */}

        <Link
          to="#"
          className="btn btn-primary rounded-circle border-3 back-to-top"
          onClick={handleButtonClick}
        >
          <i className="fa fa-arrow-up"></i>
        </Link>
      </footer>
    </Fragment>
  );
};

export default Footer;
