import { Component } from "react";
import Internshipcard from '../Components/Internshipcard'
import '../internshipcard.css'
import { Link } from "react-router-dom";

const internshipCard = [{


    id: 1,
    title: "Auto Cad",
    image: "img/Auto.jpg",
    description: "AutoCAD, often abbreviated as CAD is a powerful software tool widely employed in engineering and architecture...",
    link:"/autocad"
  },
  {
    id: 2,
    title: "Solid Works",
    image: "img/solid.jpg",
    description: "SolidWorks is a 3D parametric design software any other like product you can think of...",
    link:"/solidworks"
  },
  
  {
    id: 3,
    title: "Catia",
    image: "img/catio.jpg",
    description: "Manufacturing industries the software to enhance designing, analyzing, and managing new products...",
    link:"/catia"
  },
  
  {
    id: 4,
    title: "Nx Cad",
    image: "img/nx cad.jpg",
    description: "NX CAD, also known as “unigraphics”, Digital Industries Software...",
    link:"/nx"
  },
  
  {
    id: 5,
    title: "Ansys",
    image: "img/ansys.jpg",
    description: "Ansys is a CAE/multiphysics engineering simulation software for product design, testing and operation...",
    link:"/ansys"
  },
  
  {
    id: 6,
    title: "Creo",
    image: "img/creo.jpg",
    description: "Creo is the 3D CAD solution that helps you accelerate product innovation to build better products faster...",
    link:"/creo"
  },
  
  {
    id: 7,
    title: "Fusion 360",
    image: "img/fusion 360.jpg",
    description: "Fusion 360 offers various 3D design tools that include sketching, direct, surface, parametric, mesh...",
    link:'/fusion'
  },
  
  
  {
    id: 8,
    title: "GD&T",
    image: "img/gd&t.jpg",
    description: "GD&T symbols used on a drawing to communicate the intent of a design, focusing on the function of the part...",
    link:'/gd'
  },
  
  {
    id: 9,
    title: "Inventer",
    image: "img/inventer.jpg",
    description: "Inventor 3D CAD software provides professional-grade mechanical design and product simulation tools...",
    link:'/inventer'
  },
  
  {
    id: 10,
    title: "Civil 3D",
    image: "img/civil 3d.jpg",
    description: "the automation, collaboration and machine-learning features Architects, engineers and construction professionals use AutoCAD...",
    link:"/civil3d"
  },
  
  {
    id: 11,
    title: "STAAD Pro",
    image: "img/staad pro.jpg",
    description: "You can design, analyze, and document structural projects – anywhere in the world, with any material using STAAD... ",
    link:"/staadpro"
  },
  
  {
    id: 12,
    title: "Revit Structure",
    image: "img/revit structure.jpg",
    description: "Streamline projects, from design concept to fabrication, with Revit® Building Information Modelling software...",
    link:"/revits"
  },
  
  {
    id: 13,
    title: "Etabs",
    image: "img/etabs.jpg",
    description: "The ETABS is the ultimate integrated software package for the structural analysis and design of buildings...",
    link:"/etabs"
  },
  
  {
    id: 14,
    title: "V-rays",
    image: "img/v-rays.jpg",
    description: "V-Ray is a rendering engine that uses global illumination, tracing, photon mapping, maps and directly computed global illumination...",
    link:"/vrays"
  },
  
  {
    id: 15,
    title: "Revit Architecture",
    image: "img/revit architecture.jpg",
    description: "Design, detail, analyze, and document structural systems quickly and effectively...",
    link:"/revita"
  },
  
  {
    id: 16,
    title: "Photo shop",
    image: "img/photo shop.jpg",
    description: "Gorgeous images, rich graphics and incredible art — you can do it all with Photoshop...",
    link:"/photoshop"
  },
  
  {
    id: 17,
    title: "3DS MAX",
    image: "img/3d-max.jpg",
    description: "3D modeling, rendering, and animation software enables you to create expansive worlds and premium designs. ..",
    link:"/max3d"
  },
  
  {
    id: 18,
    title: "Lumion",
    image: "img/lumion.jpg",
    description: "Product 3D rendering software...",
    link:"/lumion"
  },
  
  {
    id: 19,
    title: "Google Sketchup",
    image: "img/google sketchup.jpg",
    description: "The joy of drawing by hand. The ease of super-smart 3D modeling software...",
    link:"google"
  }
  
  ]


class InternshipStore extends Component{
    
    render(){
        return(
          <div>
          <img
            src="img/interns3.jpg"
            alt="Slide Image"
            className="img-fluid w-100"
            style={{ height: '400px',  width:"100%" }}
          />
          <nav aria-label="breadcrumb" className="mt-3">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/home">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Internship
              </li>
            </ol>
          </nav>
        
            <div className="wcu-section pt-5 pb-5">
                 <div className="container">
                 <div className="row">

                 <div className="col-12">
                 <h1 className="breadcrumb-item active" style={{textAlign:'center'}} aria-current="page">
                Internship
              </h1>
                </div>

                
                {internshipCard.map(eachItem => (
                    <Internshipcard details = {eachItem} key={eachItem.id} />
                ))}
                
                 </div>
                 </div>
            </div></div>
        )
    }
}

export default InternshipStore