import React, { useState, Fragment } from "react";
import "../style.scss";
const ListToggle = () => {
  const [showList1, setShowList1] = useState("");
  const [showList2, setShowList2] = useState("");
  const [showList3, setShowList3] = useState("");
  const [showList4, setShowList4] = useState("");
  const [showList5, setShowList5] = useState("");
  const [showList6, setShowList6] = useState("");
  const [showList7, setShowList7] = useState("");
  const [showList8, setShowList8] = useState("");
  const toggleList = (listNumber) => {
    if (listNumber === 1) {
      setShowList1(!showList1);
      setShowList2(false);
      setShowList3(false);
      setShowList4(false);
      setShowList5(false);
      setShowList6(false);
      setShowList7(false);
      setShowList8(false);
    } else if (listNumber === 2) {
      setShowList2(!showList2);
      setShowList1(false);
      setShowList3(false);
      setShowList4(false);
      setShowList5(false);
      setShowList6(false);
      setShowList7(false);
      setShowList8(false);
    } else if (listNumber === 3) {
      setShowList3(!showList3);
      setShowList2(false);
      setShowList1(false);
      setShowList4(false);
      setShowList5(false);
      setShowList6(false);
      setShowList7(false);
      setShowList8(false);
    } else if (listNumber === 4) {
      setShowList4(!showList4);
      setShowList1(false);
      setShowList3(false);
      setShowList2(false);
      setShowList5(false);
      setShowList6(false);
      setShowList7(false);
      setShowList8(false);
    } else if (listNumber === 5) {
      setShowList5(!showList5);
      setShowList1(false);
      setShowList3(false);
      setShowList4(false);
      setShowList2(false);
      setShowList6(false);
      setShowList7(false);
      setShowList8(false);
    } else if (listNumber === 6) {
      setShowList6(!showList6);
      setShowList1(false);
      setShowList3(false);
      setShowList4(false);
      setShowList2(false);
      setShowList7(false);
      setShowList8(false);
      setShowList5(false);
    } else if (listNumber === 7) {
      setShowList7(!showList7);
      setShowList1(false);
      setShowList3(false);
      setShowList4(false);
      setShowList2(false);
      setShowList5(false);
      setShowList6(false);
      setShowList8(false);
    } else if (listNumber === 8) {
      setShowList8(!showList8);
      setShowList1(false);
      setShowList3(false);
      setShowList4(false);
      setShowList2(false);
      setShowList5(false);
      setShowList7(false);
      setShowList6(false);
    }
  };
  return (
    <Fragment>
      {/* <div className="botam-navbar">
        <p style="margin-left: 15px; color: #fff">To avail the weekly free training offer <br />Sign up Now.</p>
        <a href="#corseform" className="active">To know course fee.</a>
        <a href="#corseform" className="active">Know More</a>
      </div> */}

      <div id="main-wrapper">
        <div className="site-wrapper-reveal">
          <div
            className="about-banner-wrap vision-space bg-img responsive_banner_courses"
            
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-6 section-space--pt_100">
                  <h1  className="mobile-page-heading">
                    {" "}
                    Revit Architecture
                  </h1>
                  <p className="mobile-page-paragraph">
                    It allows users to design a building, and components in 3D,
                    annotate the model with 2D drafting.
                  </p>
                </div>
                <div className="col-lg-2"></div>
                <div className="col-lg-4">
                  <div
                    className="form-container mx-auto mx-xl-0 ml-xl-auto border"
                    id="corseform"
                    style={{
                      marginTop: "20px",
                      backgroundColor: "white",
                      alignItems: "center",
                      marginLeft: "100px",
                    }}
                  >
                    <div id="signup-form">
                      {/* <p style={{ color: "black" }}>Your Enquiry Successfully Submit</p> */}
                      <form
                        id="basic-form"
                        name="yoursite_wp_mail_from_name"
                        method="post"
                      />
                      <input type="hidden" name="courseId" value="753" />
                      <input type="hidden" name="category" value="16" />
                      <div
                        className="form-group"
                        
                      >
                        <label>
                          Name:
                          <input
                            type="text"
                            id="fname"
                            name="fname"
                            className="form-control"
                            placeholder="Enter Your Name"
                            value=""
                            required=""
                          />
                        </label>
                      </div>
                      <div className="form-group" >
                        <label>Email:</label>
                        <input
                          type="email"
                          id="email"
                          name="email"
                          className="form-control"
                          placeholder="Enter Your Email Address"
                          value=""
                          required=""
                        />
                      </div>
                      <div className="form-group" >
                        <label>Mobile:</label>
                        <input
                          type="number"
                          id="phone"
                          name="phone"
                          className="form-control"
                          placeholder="Enter Your mobile number"
                          value=""
                          required=""
                        />
                      </div>
                      <div
                        className="form-group mb-0"
                        
                      >
                        <div className="form-row">
                          <div className="form-group col-6">
                            <label className="control-label">State</label>
                            <select
                              id="states"
                              name="states"
                              className="form-control"
                              required=""
                            >
                              <option value="">SELECT STATE</option>
                              <option value="2">Andhra Pradesh</option>
                              <option value="5">Bihar</option>
                              <option value="12">Gujarat</option>
                              <option value="13">Haryana</option>
                              <option value="14">Himachal Pradesh</option>
                              <option value="16">Jharkhand</option>
                              <option value="17">Karnataka</option>
                              <option value="21">Maharashtra</option>
                              <option value="28">Punjab</option>
                              <option value="29">Rajasthan</option>
                              <option value="31">Tamil Nadu</option>
                              <option value="32">Telangana</option>
                              <option value="35">Uttar Pradesh</option>
                            </select>
                          </div>
                          <div className="form-group col-6 city">
                            <label className="control-label">Center</label>
                            <select
                              name="city"
                              id="city"
                              className="form-control center"
                              required=""
                            >
                              <option value="">SELECT CENTRE</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div
                        className="form-group mb-0"
                        style={{ marginLeft: "130px", paddingBottom: "30px" }}
                      >
                        <button
                          type="submit"
                          id="btn"
                          className="btn btn-primary contest_register_now"
                          name="register_users"
                        >
                          Apply Now
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a href="/">Home</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Revit Architecture
          </li>
        </ol>
      </nav>

      <div className="vision-overview  " style={{ marginTop: "-180px" }}>
        <div className="container">
          <div className="section-title-wrap text-center">
            <br />
            <br />
            <h3 className="heading">
              <span
                className="text-color-primary"
                style={{ fontSize: "25px", color: "#ffc107" }}
              >
                {" "}
                Training Highlights
              </span>
            </h3>
          </div>
          <div className="row">
            <div className="col-12 col-lg-4">
              <div className="item-container d-flex align-items-center">
                <div className="image-container">
                  <img
                    src="https://itdeskindia.com/wp-content/uploads/2021/02/1.png"
                    className="lazy-load"
                  />
                </div>
                <div className="content-container">
                  <h3 className="title">Instructor Led Training</h3>
                  <p className="description"></p>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <div className="item-container d-flex align-items-center">
                <div className="image-container">
                  <img
                    src="https://itdeskindia.com/wp-content/uploads/2021/02/2.png"
                    className="lazy-load"
                  />
                </div>
                <div className="content-container">
                  <h3 className="title">Weekly Doubt clearing Session</h3>
                  <p className="description"></p>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <div className="item-container d-flex align-items-center">
                <div className="image-container">
                  <img
                    src="https://itdeskindia.com/wp-content/uploads/2021/02/offline-revision-min.png"
                    className="lazy-load"
                  />
                </div>
                <div className="content-container">
                  <h3 className="title">Lifetime Assistance for Revision</h3>
                  <p className="description"></p>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <div className="item-container d-flex align-items-center">
                <div className="image-container">
                  <img
                    src="https://itdeskindia.com/wp-content/uploads/2021/02/LOGIN.jpg"
                    className="lazy-load"
                  />
                </div>
                <div className="content-container">
                  <h3 className="title">
                    Lifetime Login Access to Student Dashboard
                  </h3>
                  <p className="description"></p>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <div className="item-container d-flex align-items-center">
                <div className="image-container">
                  <img
                    src="https://itdeskindia.com/wp-content/uploads/2021/02/certificate-icon-10.png"
                    className="lazy-load"
                  />
                </div>
                <div className="content-container">
                  <h3 className="title">World-Wide Valid Certification</h3>
                  <p className="description"></p>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <div className="item-container d-flex align-items-center">
                <div className="image-container">
                  <img
                    src="https://itdeskindia.com/wp-content/uploads/2021/02/books-min.png"
                    className="lazy-load"
                  />
                </div>
                <div className="content-container">
                  <h3 className="title">
                    Study Material, Assignments and Projects
                  </h3>
                  <p className="description"></p>
                </div>
              </div>
            </div>
          </div>
          <br /> <br />
          <div
            className="section-title-wrap text-center"
            style={{ marginTop: "-140px" }}
          >
            <h3 className="heading">
              <span
                className="text-color-primary"
                style={{ fontSize: "30px", color: "#ffc107" }}
              >
                {" "}
                Course Highlights
              </span>
            </h3>
          </div>
          <div className="row">
            <div className="d-flex flex-row flex-lg-column align-items-lg-center mx-auto">
              <div className="dashed-border">
                <img
                  className="d-block d-lg-none"
                  src="https://itdeskindia.com/wp-content/themes/itdesk_theme/assets/images/dotted_line_mobile.svg"
                />
                <img
                  className="d-none d-lg-block"
                  src="https://itdeskindia.com/wp-content/themes/itdesk_theme/assets/images/dotted_line_desktop.svg"
                  width={1000}
                />
              </div>
              <div className="d-flex flex-column  align-items-center flex-lg-row justify-content-lg-center">
                <div className="item-container  justify-content-between d-flex flex-lg-column align-items-center ">
                  <div
                    className="number-container"
                    style={{ marginLeft: "10px" }}
                  >
                    <div className="number-box">1</div>
                    <div className="arrow-container">
                      <div className="arrow arrow-left"></div>
                      <div className="arrow arrow-right"></div>
                    </div>
                  </div>
                  <div className="content-container">
                    <p className="activity">
                      Feature based Parametric 3D Modelling
                    </p>
                  </div>
                </div>
                <div className="item-container d-flex flex-lg-column align-items-center">
                  <div className="number-container">
                    <div className="number-box">2</div>
                    <div className="arrow-container">
                      <div className="arrow arrow-left"></div>
                      <div className="arrow arrow-right"></div>
                    </div>
                  </div>
                  <div className="content-container">
                    <p className="activity">Fully Associative (Bi-Directional)</p>
                  </div>
                </div>
                <div className="item-container d-flex flex-lg-column align-items-center">
                  <div className="number-container">
                    <div className="number-box">3</div>
                    <div className="arrow-container">
                      <div className="arrow arrow-left"></div>
                      <div className="arrow arrow-right"></div>
                    </div>
                  </div>
                  <div className="content-container">
                    <p className="activity">
                      Massing, Rendering and Site Development
                    </p>
                  </div>
                </div>
                <div className="item-container d-flex flex-lg-column align-items-center">
                  <div className="number-container">
                    <div className="number-box">4</div>
                    <div className="arrow-container">
                      <div className="arrow arrow-left"></div>
                      <div className="arrow arrow-right"></div>
                    </div>
                  </div>
                  <div className="content-container">
                    <p className="activity">Interior & Exterior Design</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row" style={{ marginTop: "-120px" }}>
            <div className="col-lg-12">
              <br />
              <br />
              {/* <!-- <h3 className="heading" style="font-size: 21px;">Course Overview</h3> --> */}
              <h3 className="heading">
                <span
                  className="text-color-primary"
                  style={{ fontSize: "30px", color: "#ffc107" }}
                >
                  {" "}
                  Course Overview
                </span>
              </h3>
              <br />
              <br />
              <div>
                <p style={{ marginTop: "-70px", fontSize: "15px",textAlign:'justify' }}>
                  Autodesk Revit is the industry-standard tool designed for
                  building information modeling or BIM. Revit Architecture
                  training helps in enhancing the efficiency and accuracy across
                  the project lifecycle, from concept to construction and later
                  maintenance. As the model develops, Revit automatically
                  updates the floor plans, elevations, and sections. CAD DESK
                  Mahadevpura offers the best classNameroom training in India that
                  helps the learner to get in-depth knowledge of various tools
                  and features available in the Revit Architecture software.
                  Revit Architecture is a 3D designing software that enables the
                  design of building structure and its components and further
                  effectively analyzes the structure. CAD DESK Mahadevpura
                  provides the best quality training to its students in the
                  field of CAD, CAM & CAE. Moreover, while taking the coaching
                  classNamees for Revit Architecture, students are encouraged to do
                  Internship and also gets an opportunity to go for industrial
                  visits as well. CAD DESK Mahadevpura provide 100 % Placement
                  assistance to their students through which student get an
                  opportunity to be placed in various companies in India. While
                  doing CAD coaching classNamees, students also avail many other
                  facilities including books, e-books, tutorials, and projects
                  along with their student panel. They further get an additional
                  benefit of a transfer facility through which they can move to
                  any of our CAD franchise centers in India; even if they are
                  enrolled at CAD DESK Jaipur or at any other center. After
                  finishing the Revit Architecture training, CAD DESK
                  Mahadevpura students get a certificate of course completion
                  that helps them to escalate their careers.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
        }}
      >
        <div style={{ flex: 1 }}>
          <h3 className="heading_course_content">
            <span
              className="text-color-primary responsive_course_content"
              
            >
              {" "}
              Course Contents
            </span>
          </h3>
          <div classNameName="row">
            {" "}
            <div className="responsive_courese_image_container_mobile_view">
              <img
                src="img/Revitap.jpg"
                alt="Course Image"
                className="responsive_course_image_mobile_view"
              />
            </div>

            <div
              className="responsive_introduction_container"
            >
              <button
                className="collapsed responsive_introduction_button"
                data-toggle="collapse"
                data-target="#collapse__111"
                aria-expanded="false"
                aria-controls="collapse__111"
                onClick={() => toggleList(1)}
                
              >
                {/* {showList1 ? 'Close List 1' : 'Show List 1'}Button */}{" "}
                {showList1 ? (
                  <i classNameName="fas fa-arrow-up"></i>
                ) : (
                  <i
                    classNameName="fas fa-arrow-down"
                    style={{ color: "black" }}
                  ></i>
                )}{" "}
                Introduction
              </button>
              {showList1 && (
                <ul>
                  <li> Introduction to CAD DESK</li>
                  <li> Introduction to Revit Architecture</li>
                  <li> Project Templates</li>
                  <li> Exploring the User Interface</li>
                  <li> Starting a Project</li>
                  <li> Project Units, Dimensions, Levels, etc.</li>
                </ul>
              )}
            </div>
            <div className="responsive_introduction_container">
              <button
                className=" collapsed responsive_introduction_button"
                data-toggle="collapse"
                data-target="#collapse__111"
                aria-expanded="false"
                aria-controls="collapse__111"
                onClick={() => toggleList(2)}
                
              >
                {/* {showList1 ? 'Close List 1' : 'Show List 1'}Button */}{" "}
                {showList2 ? (
                  <i classNameName="fas fa-arrow-up"></i>
                ) : (
                  <i
                    classNameName="fas fa-arrow-down"
                    style={{ color: "black" }}
                  ></i>
                )}
                Building Panel
              </button>
              {showList2 && (
                <ul>
                  <li> Create Wall & Curtain Walls</li>
                  <li> Doors & Windows</li>
                  <li> New Component</li>
                  <li> Floors & Roofs</li>
                  <li> Ceiling & Lightening</li>
                </ul>
              )}
            </div>
            <div className="responsive_introduction_container">
              <button
                className=" collapsed responsive_introduction_button"
                data-toggle="collapse"
                data-target="#collapse__111"
                aria-expanded="false"
                aria-controls="collapse__111"
                onClick={() => toggleList(3)}
                
              >
                {/* {showList1 ? 'Close List 1' : 'Show List 1'}Button */}{" "}
                {showList3 ? (
                  <i classNameName="fas fa-arrow-up"></i>
                ) : (
                  <i
                    classNameName="fas fa-arrow-down"
                    style={{ color: "black" }}
                  ></i>
                )}
                Annotation Module
              </button>
              {showList3 && (
                <ul>
                  <li> Dimensions</li>
                  <li> Texts</li>
                  <li> Model Texts</li>
                  <li> Model Line etc.</li>
                </ul>
              )}
            </div>
            <div className="responsive_introduction_container">
              <button
                className=" collapsed responsive_introduction_button"
                data-toggle="collapse"
                data-target="#collapse__111"
                aria-expanded="false"
                aria-controls="collapse__111"
                onClick={() => toggleList(4)}
                
              >
                {/* {showList1 ? 'Close List 1' : 'Show List 1'}Button */}{" "}
                {showList4 ? (
                  <i classNameName="fas fa-arrow-up"></i>
                ) : (
                  <i
                    classNameName="fas fa-arrow-down"
                    style={{ color: "black" }}
                  ></i>
                )}
                Openings
              </button>
              {showList4 && (
                <ul>
                  <li>Vertical Opening</li>
                  <li>Shaft opening</li>
                  <li>Multiple floor Shaft opening</li>
                  <li>Dormer opening</li>
                  <li>Wall opening</li>
                  <li>Opening by Face</li>
                </ul>
              )}
            </div>
            <div className="responsive_introduction_container">
              <button
                className=" collapsed responsive_introduction_button"
                data-toggle="collapse"
                data-target="#collapse__111"
                aria-expanded="false"
                aria-controls="collapse__111"
                onClick={() => toggleList(5)}
                
              >
                {/* {showList1 ? 'Close List 1' : 'Show List 1'}Button */}{" "}
                {showList5 ? (
                  <i classNameName="fas fa-arrow-up"></i>
                ) : (
                  <i
                    classNameName="fas fa-arrow-down"
                    style={{ color: "black" }}
                  ></i>
                )}
                Room and Area Panel
              </button>
              {showList5 && (
                <ul>
                  <li> Room Plans and Separation</li>
                  <li>Area Boundaries</li>
                  <li>Area Tags</li>
                  <li>Colour fill legend</li>
                  <li>Keynotes</li>
                </ul>
              )}
            </div>
          </div>
        </div>

        <div className="responsive_courese_image_container">
          <img
            src="img/Revitap.jpg"
            alt="Course Image"
            className="responsive_course_image"
          />
        </div>
      </div>

      <div className="contact-us-section-wrappaer ">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7 col-lg-7">
              <div className="conact-us-wrap-one">
                <br />
                <br />
                <h5 className="heading mb-20">
                  How will you get your certificate?
                </h5>
                <div className="center_details">
                  {/* <!--   <h5 className="heading mb-20">CERTIFICATE FAQ</h5> --> */}
                  <p>
                    Upon successful completion of the program, each intern
                    receives a prestigious Digital Marketing Internship
                    Certificate. This credential not only recognizes their
                    achievements but also enhances their employability.
                  </p>
                  <p style={{ textAlign: "justify" }}>
                    CAD DESK Mahadevpura is a pioneer in Mechanical/Civil
                    training and most of the industries give great recognition
                    to its certificate throughout India and abroad. CAD DESK
                    Mahadevpura Certificates are ISO verified which makes it
                    highly reputed comparing to the other local brands and helps
                    the student get prioritize while going for job opportunities
                    in the industry. CAD DESK Mahadevpura Software Training is
                    conducted by highly knowledgeable trainers which help
                    students to enhance their skill set by putting great
                    emphasizes on project-based learning.
                  </p>
                  {/* <!-- <a className="btn btn-primary">Get Certificate</a> --> */}
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-lg-5">
              <div className="contact-form-wrap">
                {/* <!-- <h5 className="heading mb-20">How will you get your certificate?</h5> --> */}
                <img
                  src="img/cert.jpg"
                  style={{
                    width: " 100%",
                    marginTop: "100px",
                    height: "370px",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
    </Fragment>
  );
};

export default ListToggle;
