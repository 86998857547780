import React from "react";
import "../App.css";
import { Link } from "react-router-dom";
function Service() {
  return (
    <div className="">
      <div className="container-fluid services al py-1">
        <div className="container text-center py-5">
          <div className="text-center mb-5 wow fadeInUp" data-wow-delay=".3s">
            <h5 className="mb-2 px-3 py-1 text-dark rounded-pill d-inline-block border border-2 border-primary">
              CAD Desk Mahadevpura
            </h5>
            <h1 className="display-5">Courses</h1>
          </div>
          <div className="row  py-1 g-5">
            <div
              className="service bg-light col-xxl-3 col-lg-6 col-md-6 col-sm-12 wow fadeInUp"
              data-wow-delay=".3s"
            >
              <div className="  rounded p-5 services-item">
                <div
                  className="d-flex"
                  style={{
                    "align-items": "center",
                    "justify-content": "center",
                  }}
                >
                  <div className="mb-4 rounded-circle services-inner-icon">
                    <i className="fa fas fa-book-reader  fa-3x text-primary"></i>
                  </div>
                </div>
                <h4 className="text-center">AutoCad</h4>
                <p className="text-center fs-5">
                  It is a powerful software tool widely employed in
                  architecture.
                </p>
                <Link to="/autocad">
                  {" "}
                  <button
                    type="button"
                    className="btn btn-primary border-0 rounded-pill px-4 py-3"
                  >
                    Learn More
                  </button>
                </Link>
              </div>
            </div>

            <div
              className="service col-xxl-3 col-lg-6 col-md-6 col-sm-12 wow fadeInUp"
              data-wow-delay=".5s"
            >
              <div className="bg-light rounded p-5 services-item">
                <div
                  className="d-flex"
                  style={{
                    "align-items": "center",
                    "justify-content": "center",
                  }}
                >
                  <div className="mb-4 rounded-circle services-inner-icon">
                    <i className="fa fas fa-book-reader  fa-3x text-primary"></i>
                  </div>
                </div>
                <h4 className="text-center">NX CAD</h4>
                <p className="text-center fs-5">
                  NX CAD, also known as “unigraphics”, Digital Industries
                  Software.
                </p>
                <Link to="/nx">
                  {" "}
                  <button
                    type="button"
                    className="btn btn-primary border-0 rounded-pill px-4 py-3"
                  >
                    Learn More
                  </button>
                </Link>
              </div>
            </div>

            <div
              className=" service col-xxl-3 col-lg-6 col-md-6 col-sm-12 wow fadeInUp"
              data-wow-delay=".9s"
            >
              <div className="bg-light rounded p-5 services-item">
                <div
                  className="d-flex"
                  style={{
                    "align-items": "center",
                    "justify-content": "center",
                  }}
                >
                  <div className="mb-4 rounded-circle services-inner-icon">
                    <i className="fa fas fa-book-reader  fa-3x text-primary"></i>
                  </div>
                </div>
                <h4 className="text-center">Creo</h4>
                <p className="text-center fs-5">
                  Creo is the 3D CAD solution that helps you to build better
                  products faster
                </p>
                <Link to="/creo">
                  {" "}
                  <button
                    type="button"
                    className="btn btn-primary border-0 rounded-pill px-4 py-3"
                  >
                    Learn More
                  </button>
                </Link>
              </div>
            </div>
            <div
              className=" service col-xxl-3 col-lg-6 col-md-6 col-sm-12 wow fadeInUp"
              data-wow-delay=".9s"
            >
              <div className="bg-light rounded p-5 services-item">
                <div
                  className="d-flex"
                  style={{
                    "align-items": "center",
                    "justify-content": "center",
                  }}
                >
                  <div className="mb-4 rounded-circle services-inner-icon">
                    <i className="fa fas fa-book-reader  fa-3x text-primary"></i>
                  </div>
                </div>
                <h4 className="text-center">Civil</h4>
                <p className="text-center fs-5">
                  3ds Max is a computer graphics program for creating 3D models
                  etc.
                </p>
                <Link to="/civil3d">
                  {" "}
                  <button
                    type="button"
                    className="btn btn-primary border-0 rounded-pill px-4 py-3"
                  >
                    Learn More
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <Link to="/cardlist">
            <button
              type="button"
              className="btn btn-primary border-0 rounded-pill px-4 py-3 mt-4 wow fadeInUp"
              data-wow-delay=".3s"
            >
              More Courses
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Service;
