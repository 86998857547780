import React, { useState } from "react";
import { Router } from "react-router-dom";
import { Link } from "react-router-dom";

const Onlinecourses = () => {
  return (
    <div>
      <div>
        <img
          src="img/online.jpg"
          alt="Slide Image"
          className="img-fluid w-100"
          style={{ height: "500px", maxWidth: "1500px", objectFit: "cover" }}
        />
        <nav aria-label="breadcrumb" className="mt-3">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/home">Home</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Onlinecourses
            </li>
          </ol>
        </nav>
      </div>
      <div className="course-w3ls py-5">
        <div className="container py-xl-5 py-lg-3">
          <h1 style={{ marginTop: "-40px", textAlign: "center" }}>
            Online-Courses
          </h1>
          {/* Autocad */}
          <div className="row cource-list-agile  pt-md-4 mb-4">
            <div className="col-lg-7 agile-course-main">
              <div className="w3ls-cource-first">
                <img
                  src="img/1.png"
                  alt=""
                  className="imgg-fluid img-poiscour mx-auto d-block mt-2"
                />
                <div className="px-md-5 px-4  pb-md-5 pb-4">
                  <h3 className="text-dark">Auto Cad</h3>
                  <p className="mt-3 mb-4 pr-lg-5">
                    AutoCAD, often abbreviated as CAD is a powerful software
                    tool widely employed in engineering and architecture.
                  </p>
                  <ul className="list-unstyled text-capitalize">
                    <li>
                      <i className="fas fa-calendar-alt mr-3"></i>Jan - March
                      2024
                    </li>
                    <li className="my-3">
                      <i className="fas fa-clock mr-3"></i>4 - 6 hours
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-5 agile-course-main-2 mt-4">
              <img src="img/auto.jpg" alt="" className="imgg-fluid" />
            </div>
            <div className="buttons-w3ls">
              <Link
                className="btn button-cour-w3ls text-white"
                href=""
                to="/autocad"
                role="button"
              >
                Learn More
              </Link>
              <Link
                className="btn bg-dark text-white"
                href=""
                to="/Contact"
                role="button"
              >
                Apply Now
              </Link>
            </div>
          </div>

          {/* Solidworks */}
          <div className="row cource-list-agile cource-list-agile-2 pt-md-4 mb-4">
            <div className="col-lg-5 agile-course-main-3 mt-4">
              <img src="img/Solidp.jpg" alt="" className="imgg-fluid" />
            </div>
            <div className="col-lg-7 agile-course-main text-right">
              <div className="w3ls-cource-second">
                <img
                  src="img/2.png"
                  alt=""
                  className="imgg-fluid img-poiscour mx-auto d-block mt-2"
                />
                <div className="px-md-5 px-4  pb-md-5 pb-4 text-right">
                  <h3 className="text-dark">Solid Works</h3>

                  <p className="mt-3 mb-4 pl-lg-4">
                    SolidWorks is a 3D parametric design software any other like
                    product you can think of.
                  </p>
                  <ul className="list-unstyled text-capitalize">
                    <li>
                      Jan-Mar 2024
                      <i className="fas fa-calendar-alt ml-3"></i>
                    </li>
                    <li className="my-3">
                      3 - 6 hours
                      <i className="fas fa-clock ml-3"></i>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="buttons-w3ls-2">
                <Link
                  className="btn button-cour-w3ls text-white"
                  href=""
                  to="/solidworks"
                  role="button"
                >
                  Learn More
                </Link>
                <Link
                  className="btn bg-dark text-white"
                  to="/Contact"
                  role="button"
                >
                  Apply Now
                </Link>
              </div>
            </div>
          </div>
          {/* Catia */}
          <div className="row cource-list-agile pt-4">
            <div className="col-lg-7 agile-course-main">
              <div className="w3ls-cource-first">
                <img
                  src="img/1.png"
                  alt=""
                  className="imgg-fluid img-poiscour mx-auto d-block mt-2"
                />
                <div className="px-md-5 px-4  pb-md-5 pb-4">
                  <h3 className="text-dark">Catia</h3>
                  <p className="mt-3 mb-4 pr-lg-5">
                    Manufacturing industries the software to enhance designing,
                    analyzing, and managing new products.
                  </p>
                  <ul className="list-unstyled text-capitalize">
                    <li>
                      <i className="fas fa-calendar-alt mr-3"></i>Jan-Mar 2024
                    </li>
                    <li className="my-3">
                      <i className="fas fa-clock mr-3"></i>4 - 6 hours
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-5 agile-course-main-2 mt-4">
              <img src="img/Catiop.jpg" alt="" className="imgg-fluid" />
            </div>
            <div className="buttons-w3ls">
              <Link
                className="btn button-cour-w3ls text-white"
                href=""
                to="/catia"
                role="button"
              >
                Learn More
              </Link>
              <Link
                className="btn bg-dark text-white"
                to="/Contact"
                role="button"
              >
                Apply Now
              </Link>
            </div>
            <br></br>
          </div>

          {/* NXCAD */}

          <div className="row cource-list-agile cource-list-agile-2 pt-md-4 mb-4">
            <div className="col-lg-5 agile-course-main-3 mt-4">
              <img src="img/Nxcadp.jpg" alt="" className="imgg-fluid" />
            </div>
            <div className="col-lg-7 agile-course-main text-right">
              <div className="w3ls-cource-second">
                <img
                  src="img/2.png"
                  alt=""
                  className="imgg-fluid img-poiscour mx-auto d-block mt-2"
                />
                <div className="px-md-5 px-4  pb-md-5 pb-4 text-right">
                  <h3 className="text-dark">NX CAD</h3>

                  <p className="mt-3 mb-4 pl-lg-4">
                    NX CAD, also known as “unigraphics”, Digital Industries
                    Software.
                  </p>
                  <ul className="list-unstyled text-capitalize">
                    <li>
                      Jan-Mar 2024
                      <i className="fas fa-calendar-alt ml-3"></i>
                    </li>
                    <li className="my-3">
                      3 - 6 hours
                      <i className="fas fa-clock ml-3"></i>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="buttons-w3ls-2">
                <Link
                  className="btn button-cour-w3ls text-white"
                  href=""
                  to="/nxcad"
                  role="button"
                >
                  Learn More
                </Link>
                <Link
                  className="btn bg-dark text-white"
                  to="/Contact"
                  role="button"
                >
                  Apply Now
                </Link>
              </div>
            </div>
          </div>
          {/* Ansys*/}

          <div className="row cource-list-agile pt-4">
            <div className="col-lg-7 agile-course-main">
              <div className="w3ls-cource-first">
                <img
                  src="img/1.png"
                  alt=""
                  className="imgg-fluid img-poiscour mx-auto d-block mt-2"
                />
                <div className="px-md-5 px-4  pb-md-5 pb-4">
                  <h3 className="text-dark">Ansys</h3>
                  <p className="mt-3 mb-4 pr-lg-5">
                    Ansys is a CAE/multiphysics engineering simulation software
                    for product design, testing and operation.
                  </p>
                  <ul className="list-unstyled text-capitalize">
                    <li>
                      <i className="fas fa-calendar-alt mr-3"></i>Jan-Mar
                    </li>
                    <li className="my-3">
                      <i className="fas fa-clock mr-3"></i>4 - 6 hours
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-5 agile-course-main-2 mt-4">
              <img src="img/Ansysp.jpg" alt="" className="imgg-fluid" />
            </div>
            <div className="buttons-w3ls">
              <Link
                className="btn button-cour-w3ls text-white"
                href=""
                to="/ansys"
                role="button"
              >
                Learn More
              </Link>
              <Link
                className="btn bg-dark text-white"
                to="/Contact"
                role="button"
              >
                Apply Now
              </Link>
            </div>
          </div>

          {/* "Creo"*/}
          <div className="row cource-list-agile cource-list-agile-2 pt-md-4 mb-4">
            <div className="col-lg-5 agile-course-main-3 mt-4">
              <img src="img/Creop.jpg" alt="" className="imgg-fluid" />
            </div>
            <div className="col-lg-7 agile-course-main text-right">
              <div className="w3ls-cource-second">
                <img
                  src="img/2.png"
                  alt=""
                  className="imgg-fluid img-poiscour mx-auto d-block mt-2"
                />
                <div className="px-md-5 px-4  pb-md-5 pb-4 text-right">
                  <h3 className="text-dark">Creo</h3>

                  <p className="mt-3 mb-4 pl-lg-4">
                    Creo is the 3D CAD solution that helps you accelerate
                    product innovation to build better products faster.
                  </p>
                  <ul className="list-unstyled text-capitalize">
                    <li>
                      Jan-Mar 2024
                      <i className="fas fa-calendar-alt ml-3"></i>
                    </li>
                    <li className="my-3">
                      3 - 6 hours
                      <i className="fas fa-clock ml-3"></i>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="buttons-w3ls-2">
                <Link
                  className="btn button-cour-w3ls text-white"
                  href=""
                  to="/creo"
                  role="button"
                >
                  Learn More
                </Link>
                <Link
                  className="btn bg-dark text-white"
                  to="/Contact"
                  role="button"
                >
                  Apply Now
                </Link>
              </div>
            </div>
          </div>

          {/* Fusion'*/}

          <div className="row cource-list-agile pt-4">
            <div className="col-lg-7 agile-course-main">
              <div className="w3ls-cource-first">
                <img
                  src="img/1.png"
                  alt=""
                  className="imgg-fluid img-poiscour mx-auto d-block mt-2"
                />
                <div className="px-md-5 px-4  pb-md-5 pb-4">
                  <h3 className="text-dark">Fusion</h3>
                  <p className="mt-3 mb-4 pr-lg-5">
                    {" "}
                    360 offers various 3D design tools that include sketching,
                    direct, surface, parametric, mesh.
                  </p>
                  <ul className="list-unstyled text-capitalize">
                    <li>
                      <i className="fas fa-calendar-alt mr-3"></i>Jan-Mar 2024
                    </li>
                    <li className="my-3">
                      <i className="fas fa-clock mr-3"></i>4 - 6 hours
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-5 agile-course-main-2 mt-4">
              <img src="img/Fusionp.jpg" alt="" className="imgg-fluid" />
            </div>
            <div className="buttons-w3ls">
              <Link
                className="btn button-cour-w3ls text-white"
                href=""
                to="/fusion"
                role="button"
              >
                Learn More
              </Link>
              <Link
                className="btn bg-dark text-white"
                to="/Contact"
                role="button"
              >
                Apply Now
              </Link>
            </div>
          </div>

          {/* title: "GD&T", */}

          <div className="row cource-list-agile cource-list-agile-2 pt-md-4 mb-4">
            <div className="col-lg-5 agile-course-main-3 mt-4">
              <img
                src="img/gd&t.jpg"
                width={600}
                height={100}
                alt=""
                className="imgg-fluid"
              />
            </div>
            <div className="col-lg-7 agile-course-main text-right">
              <div className="w3ls-cource-second">
                <img
                  src="img/2.png"
                  alt=""
                  className="imgg-fluid img-poiscour mx-auto d-block mt-2"
                />
                <div className="px-md-5 px-4  pb-md-5 pb-4 text-right">
                  <h3 className="text-dark">GD&T</h3>

                  <p className="mt-3 mb-4 pl-lg-4">
                    GD&T symbols used on a drawing to communicate the intent of
                    a design, focusing on the function of the part.
                  </p>
                  <ul className="list-unstyled text-capitalize">
                    <li>
                      Jan-Mar 2024
                      <i className="fas fa-calendar-alt ml-3"></i>
                    </li>
                    <li className="my-3">
                      3 - 6 hours
                      <i className="fas fa-clock ml-3"></i>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="buttons-w3ls-2">
                <Link
                  className="btn button-cour-w3ls text-white"
                  href=""
                  to="/Gd&t"
                  role="button"
                >
                  Learn More
                </Link>
                <Link
                  className="btn bg-dark text-white"
                  to="/Contact"
                  role="button"
                >
                  Apply Now
                </Link>
              </div>
            </div>
          </div>

          {/* title: "Inventer",
           */}
          {/* <div className="row cource-list-agile pt-4">
            <div className="col-lg-7 agile-course-main">
              <div className="w3ls-cource-first">
                <img
                  src="img/1.png"
                  alt=""
                  className="imgg-fluid img-poiscour mx-auto d-block mt-2"
                />
                <div className="px-md-5 px-4  pb-md-5 pb-4">
                  <h3 className="text-dark">Inventor</h3>
                  <p className="mt-3 mb-4 pr-lg-5">
                    Inventor 3D CAD software provides professional-grade
                    mechanical design and product simulation tools.
                  </p>
                  <ul className="list-unstyled text-capitalize">
                    <li>
                      <i className="fas fa-calendar-alt mr-3"></i>Jan-Mar 2024
                    </li>
                    <li className="my-3">
                      <i className="fas fa-clock mr-3"></i>4 - 6 hours
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-5 agile-course-main-2 mt-4">
              <img src="img/Inventerp.jpg" alt="" className="imgg-fluid" />
            </div>
            <div className="buttons-w3ls">
              <Link
                className="btn button-cour-w3ls text-white"
                href=""
                to="/inventer"
                role="button"
              >
                Learn More
              </Link>
              <Link
                className="btn bg-dark text-white"
                to="/Contact"
                role="button"
              >
                Apply Now
              </Link>
            </div>
          </div> */}

          {/*  title: "Civil 3D",*/}

          <div className="App">
            <div>
              <Link to="/Onlinecourse">
                <button style={{ marginTop: "40px", marginLeft: "1300px" }}>
                  Next
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>{" "}
    </div>
  );
};

export default Onlinecourses;
