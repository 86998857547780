import React, { useState } from "react";
import ReactPlayer from "react-player";
import { BiCheck } from "react-icons/bi";

const Enquiry = () => {
  const [selectedOption, setSelectedOption] = useState(null);

  // Replace this function with your actual logic to get options based on the selected category
  const getOptions = () => {
    switch (selectedOption) {
      case "Architecture CAD":
        return [
          "Autocad",
          "Ansys",
          "Creo",
          "Catia",
          "Fusion",
          "Solidworks",
          "GD&T",
          "NX CAD",
          "Inventer",
        ];
      case "Civil CAD":
        return ["Civil 3d", "Staad Pro", "Revit structure", "Etabs"];
      case "Mechanical CAD":
        return [
          "V-ray",
          "Revit Architecture  ",
          "Photoshop",
          "Max3D",
          "Google sketchup",
          "Lumion",
        ];

      default:
        return [];
    }
  };

  return (
    <div className="container-fluid py-1">
      <div className="container py-1">
        <div className="row">
          <div className="col-md-6">
            <div className="embed-responsive embed-responsive-16by9">
              <ReactPlayer
                url={"img/Caddesk.mp4"}
                width="100%"
                height="90"
                controls={true}
                className="embed-responsive-item"
                style={{ marginTop: "60px" }}
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="vertical_scroll_cards-container">
              {/* Add your content here */}

              <div className="vertical_scroll_card">
                <div className="vertical_scroll_content">
                  <p>
                    <b style={{ textAlign: "center" }}>
                      <BiCheck /> Course offers
                    </b>
                    <img src=" img/offers.gif" width={100} height={50} style={{alignItems:'center'}}></img>
                  </p>
                  <b style={{ textAlign: "center" }}>
                    {" "}
                    <p>Register and get 25% Offer in Cad</p>
                    <p> Take combo course then get 40%  Offer in Cad</p>
                  </b>
                </div>
              </div>

              <div className="vertical_scroll_card">
                <div className="vertical_scroll_content">
                  <p>
                    <b style={{ textAlign: "center" }}>
                      {" "}
                      <BiCheck /> Placement
                    </b>
                    <img src=" img/job.gif" width={100} height={50}></img>
                  </p>
                  <b style={{ textAlign: "center" }}>
                    {" "}
                    <p>Interior designer</p>
                  </b>
                  <b style={{ textAlign: "center" }}>
                    {" "}
                    <p>CAD designer</p>  <p>Internship</p>
                  </b> <b style={{ textAlign: "center" }}>
                    {" "}
                  
                  </b>
                </div>
              </div>

              {/* Repeat the structure for other content */}
              {/* Add your content here */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Enquiry;
