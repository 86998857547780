import React, { useState, useEffect } from "react";
const divStyle = {
  // background image commented out for now
  // backgroundImage: 'url("https://media.istockphoto.com/id/1394894776/photo/mechanical-project-blueprint.jpg?s=612x612&w=0&k=20&c=qjKrT1Va2dVhRPscwHAy-ua_tnpq09LJkhhiMu78XAk=")',
  backgroundPosition: "center",
  transform: "0.7s",
  animation: "sliderZoomEffect 12s linear infinite",
};
const Slider = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const slides = [
    {
      image: "img/slide2.jpeg",
      title: "CAD Desk Services",
      content: " Where imagination meets precision",
    },
    {
      image: "img/slide1.jpeg",
      title: "CAD Desk Services",
      content: "Transforming ideas into reality",
    },
    {
      image: "img/slide3.jpeg",
      title: "CAD Desk Services",
      content: "A step towards refining engineering skills",
    },
    {
      image: "img/slide5.jpeg",
      title: "CAD Desk Services",
      content: "Designing the future with CAD",
    },
    {
      image: "img/slide4.jpeg",
      title: "CAD Desk Services",
      content: "Empowering creativity with CAD",
    },
    // Add more slides as needed
  ];

  const nextSlide = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % slides.length);
  };

  useEffect(() => {
    const intervalId = setInterval(nextSlide, 5000); // Change 5000 to the desired interval in milliseconds (e.g., 5000 for 5 seconds)

    return () => clearInterval(intervalId); // Clear the interval when the component is unmounted or updated
  }, []);

  return (
    <div className="container-fluid carousel px-0 mb-5 pb-5">
      <div id="carouselId" className="carousel slide" data-bs-ride="carousel">
        {/* ... Other carousel elements ... */}
        <div className="carousel-inner" role="listbox">
          {slides.map((slide, index) => (
            <div
              key={index}
              className={`carousel-item ${
                index === activeIndex ? "active" : ""
              }`}
            >
              <div className="zoom_container">
                <div style={divStyle}>
                  <img
                    style={{ height: 600, width: 1500 }}
                    src={slide.image}
                    alt={`Slide ${index + 1}`}
                  />
                  <h1 className="bg_heading"></h1>
                </div>
              </div>
              <div className="carousel-caption">
                <div className="container carousel-content">
                  <h4
                    className="text-white mb-4 animated  slideInDown"
                    style={{ textAlign: "center" }}
                  >
                    {slide.title}
                  </h4>
                  <h1
                    className="text-white display-1 mb-4 animated slideInDown"
                    style={{ textAlign: "center" }}
                  >
                    {slide.content}
                  </h1>
                  <a href="" className="me-2">
                    {/* <button type="button" className="px-5 py-3 btn btn-primary border-2 rounded-pill animated slideInDown">
                      Read More
                    </button> */}
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
        <button
          className="carousel-control-prev btn btn-primary border border-2 border-start-0 border-primary"
          type="button"
          data-bs-target="#carouselId"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next btn btn-primary border border-2 border-end-0 border-primary"
          type="button"
          data-bs-target="#carouselId"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
        {/* ... Other carousel elements ... */}
      </div>
    </div>
  );
};

export default Slider;
