import React, { useState } from "react";

const Slide = () => {
  const [isDemoOptionOpen, setDemoOptionOpen] = useState(false);

  const handleQuickOptionClick = () => {
    setDemoOptionOpen(!isDemoOptionOpen);
  };

  const [status, setStatus] = useState("Submit");

  const [info, setInfo] = useState({
    name: "",
    mobilenumber: "",
    email: "",
    selectedOption: "",
    subOption: "",
  });

  const handleChange = (event) => {
    setInfo({
      ...info,
      [event.target.name]: event.target.value,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(info);
    setStatus("Sending...");

    let response = await fetch(`/api/inquiry`, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        Accept: "application/json",
        //'Content-Type': 'application/json',
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(info),
      //body: JSON.stringify(details),
    });
    //console.log(details);
    console.log(info);
    setStatus("Submit");
    setInfo({
      name: "",
      mobilenumber: "",
      email: "",
      selectedOption: "",
      subOption: "",
    });
    let result = await response.json();
    //alert(result.status);
  };
  const getOptions = () => {
    switch (info.selectedOption) {
      case "Architecture CAD":
        return [
          "Autocad",
          "3D MAX",
      
          "Lumion",
          "Revit Architecture ",
          "Solidworks",
          "V-RAYS",
        ];
      case "Civil CAD":
        return [
          "Civil 3d",
          "Etabs",
          "Google sketchup",
          "Lumion",
          "Staad Pro",
          "Revit structure",
          "Revit Architecture ",
          "V-RAYS",
        ];
      case "Mechanical CAD":
        return [
          "Ansys",
          "Catia",
          "Fusion  360",
          "GD&T",
          "Google sketchup",
          "Max3D",
          "NX CAD",
          "Photoshop",
          "Premevera",
          "Revit Architecture  ",
          "SolidWorks",
        ];

      default:
        return [];
    }
  };
  const nameRegex = /^[a-zA-Z\s]+$/;
  const phoneRegex = /^\d{10}$/;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleNameChange = (e) => {
    const inputValue = e.target.value;
    if (nameRegex.test(inputValue) || inputValue === "") {
      info.name(inputValue);
    }
  };

  const handlePhoneChange = (e) => {
    const inputValue = e.target.value;
    if (phoneRegex.test(inputValue) || inputValue === "") {
      info.phone(inputValue);
    }
  };

  const handleEmailChange = (e) => {
    const inputValue = e.target.value;
    if (emailRegex.test(inputValue) || inputValue === "") {
      info.email(inputValue);
    }
  };

  return (
    <>
      <div
        className={`demo-option-container ${isDemoOptionOpen ? "open" : ""}`}
      >
        <div className="aeroland__toolbar">
          <div
            className="inner"
            style={{ background: "orange", textAlign: "right" }}
          >
            <button
              className="quick-option hint--bounce hint--left hint--black primary-color-hover-important"
              style={{
                color: "White",
                transform: `rotate(180deg) ${
                  isDemoOptionOpen ? "rotate(90deg)" : "rotate(90deg)"
                }`,
                fontSize: "17px", // Adjust the font size as needed
                border: "none",
                // Remove the border
                background: "transparent",
                fontWeight: "bold",
                outline: "none" /* Remove default focus outline */,
                border: "none",
                // Optional: Adjust the negative margin to fine-tune the vertical position
                marginLeft: "-38px",
                lineHeight: "8.15",
                color: "black",
                textAlign: "center",
              }}
              onClick={handleQuickOptionClick}
            >
              GetInTouch
            </button>
          </div>
        </div>

        <div className="demo-option-wrapper">
          <button
            className="quick-option hint--bounce hint--left hint--black primary-color-hover-important"
            onClick={handleQuickOptionClick}
            style={{ backgroundColor: "white" }}
          >
            <img
              src="img/closeicon.png"
              style={{
                width: "40px",
                marginLeft: "-10px",
                marginTop: "10px",
                backgroundColor: "white",
              }}
              alt="Close"
            />
          </button>

          <div className="business-solution-form-wrap mr-auto ml-auto">
            <div className="contact-title text-center section-space--mb_40">
              <b>
                <h3 className="mb-10">Contact Us</h3>
              </b>
            </div>

            <form id="applyOnlyEnqury" onSubmit={handleSubmit}>
              <input type="hidden" name="valid" value="false" />
              <div class="contact-form__two">
                <br />

                <div className="contact-inner" style={{ width: "260px" }}>
                  <input
                    name="name"
                    type="text"
                    placeholder="Name *"
                    required
                    id="name"
                    pattern="[a-zA-Z\s]+"
                    title="Please enter only alphabets"
                    value={info.name}
                    onChange={handleChange}
                  />
                </div>

                <div className="contact-inner" style={{ width: "260px" }}>
                  <input
                    name="email"
                    type="text"
                    placeholder="Email id *"
                    required
                    id="email"
                    pattern="[^\s@]+@[^\s@]+\.[^\s@]+"
                    title="Please enter a valid email address"
                    value={info.email}
                    onChange={handleChange}
                  />
                </div>
                {/* <!-- <div class="contact-inner">
                  <input name="con_email" type="email" placeholder="Email *" required=""/>
               </div> --> */}
                {/* <div class="contact-inner" style={{ width: "260px" }}>
                  <input
                    name="mobilenumber"
                    type="text"
                    placeholder="Contact No *"
                    required
                    id="mobilenumber"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </div> */}

                <div className="contact-inner" style={{ width: "260px" }}>
                  <input
                    name="mobilenumber"
                    type="text"
                    placeholder="Contact No *"
                    required
                    id="mobilenumber"
                    pattern="\d{10}"
                    title="Please enter a 10-digit phone number"
                    value={info.mobilenumber}
                    onChange={handleChange}
                  />
                </div>

                <div className="mb-3">
                  <select
                    className="form-control p-3 border-0"
                    value={info.selectedOption || ""}
                    name="selectedOption"
                    required
                    onChange={handleChange}
                  >
                    <option value="">Select Category</option>
                    <option value="Architecture CAD">Architecture CAD</option>
                    <option value="Civil CAD">Civil CAD</option>
                    <option value="Mechanical CAD">Mechanical CAD</option>
                  </select>
                </div>
                <div className="mb-3">
                  {info.selectedOption && (
                    <select
                      className="form-control p-3 border-0"
                      required
                      name="subOption"
                      value={info.subOption}
                      onChange={handleChange}
                    >
                      <option value="">Select {info.selectedOption}</option>
                      {getOptions().map((subOption) => (
                        <option key={subOption} value={subOption}>
                          {subOption}
                        </option>
                      ))}
                    </select>
                  )}
                </div>

                <br />

                <div
                  class="comment-submit-btn text-center"
                  style={{ color: "#ffc107" }}
                >
                  <button
                    class="ht-btn ht-btn-md"
                    type="submit"
                    name="submitEnqury"
                    id="submitEnqury"
                    style={{ backgroundColor: "#ffc107" }}
                  >
                    Submit
                  </button>
                  {/* <!--  <p class="form-messege"></p> --> */}
                </div>

                {/* <div class="contact-select">
                                    <div class="form-item contact-inner">
                                        <span class="inquiry">
                                            <select id="course" name="course" required="">
                                                <option value="">Select Course</option>
                                            </select>
                                        </span>
                                    </div>
                                </div> */}

                {/* <div class="contact-select">
                                    <div class="form-item contact-inner">
                                        <span class="inquiry">
                                            <select id="states" name="states" required="">
                                                <option value="">Select State</option>
                                                <option value="2">Andhra Pradesh</option>
                                                <option value="4">Assam</option>
                                                <option value="5">Bihar</option>
                                                <option value="7">Chhattisgarh</option>
                                                <option value="10">Delhi (NCR)</option>
                                                <option value="12">Gujarat</option>
                                                <option value="13">Haryana</option>
                                                <option value="14">Himachal Pradesh</option>
                                                <option value="15">Jammu and Kashmir</option>
                                                <option value="16">Jharkhand</option>
                                                <option value="17">Karnataka</option>
                                                <option value="18">Kerala</option>
                                                <option value="20">Madhya Pradesh</option>
                                                <option value="21">Maharashtra</option>
                                                <option value="26">Odisha</option>
                                                <option value="28">Punjab</option>
                                                <option value="29">Rajasthan</option>
                                                <option value="31">Tamil Nadu</option>
                                                <option value="32">Telangana</option>
                                                <option value="33">Tripura</option>
                                                <option value="35">Uttar Pradesh</option>
                                                <option value="36">West Bengal</option>
                                            </select>
                                        </span>
                                    </div>
                                </div> */}

                {/* <div class="contact-select">
                                    <div class="form-item contact-inner">
                                        <span class="inquiry">
                                            <select class="center" name="center" required="">
                                                <option value="">Select Center</option>
                                            </select>
                                        </span>
                                    </div>
                                </div> */}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default Slide;
