const data = [
  {
    id: 1,
    //   name: 'John Doe',
    //   course: 'Web Development',
    //   companyName: 'Tech Co.',
    image: "https://caddeskindia.com/wp-content/uploads/2021/01/eight.png", // Replace with actual image URL
  },
  {
    id: 2,
    // name: 'John Doe',
    // course: 'Web Development',
    // companyName: 'Tech Co.',
    image: "https://caddeskindia.com/wp-content/uploads/2021/01/five.png", // Replace with actual image URL
  },
  {
    id: 3,
    // name: 'John Doe',
    // course: 'Web Development',
    // companyName: 'Tech Co.',
    image: "https://caddeskindia.com/wp-content/uploads/2021/01/four.png", // Replace with actual image URL
  },
  {
    id: 4,
    // name: 'John Doe',
    // course: 'Web Development',
    // companyName: 'Tech Co.',
    image: "https://caddeskindia.com/wp-content/uploads/2021/01/nine.png", // Replace with actual image URL
  },
  {
    id: 5,
    // name: 'John Doe',
    // course: 'Web Development',
    // companyName: 'Tech Co.',
    image: "https://caddeskindia.com/wp-content/uploads/2021/01/one.png", // Replace with actual image URL
  },
  {
    id: 6,
    // name: 'John Doe',
    // course: 'Web Development',
    // companyName: 'Tech Co.',
    image: "https://caddeskindia.com/wp-content/uploads/2021/01/rec1.png", // Replace with actual image URL
  },
  {
    id: 7,
    // name: 'John Doe',
    // course: 'Web Development',
    // companyName: 'Tech Co.',
    image: "https://caddeskindia.com/wp-content/uploads/2021/01/rec3.png", // Replace with actual image URL
  },
  {
    id: 8,
    // name: 'John Doe',
    // course: 'Web Development',
    // companyName: 'Tech Co.',
    image: "https://caddeskindia.com/wp-content/uploads/2021/01/rec2.png", // Replace with actual image URL
  },
  {
    id: 9,
    // name: 'John Doe',
    // course: 'Web Development',
    // companyName: 'Tech Co.',
    image: "https://caddeskindia.com/wp-content/uploads/2021/01/rec4.png", // Replace with actual image URL
  },
  {
    id: 10,
    // name: 'John Doe',
    // course: 'Web Development',
    // companyName: 'Tech Co.',
    image: "https://caddeskindia.com/wp-content/uploads/2021/01/rec6.png", // Replace with actual image URL
  },

  {
    id: 11,
    // name: 'John Doe',
    // course: 'Web Development',
    // companyName: 'Tech Co.',
    image: "https://caddeskindia.com/wp-content/uploads/2021/01/six.png", // Replace with actual image URL
  },
  {
    id: 12,
    // name: 'John Doe',
    // course: 'Web Development',
    // companyName: 'Tech Co.',
    image: "https://caddeskindia.com/wp-content/uploads/2021/01/ten.png", // Replace with actual image URL
  },
  {
    id: 13,
    // name: 'John Doe',
    // course: 'Web Development',
    // companyName: 'Tech Co.',
    image: "https://caddeskindia.com/wp-content/uploads/2021/01/two.png", // Replace with actual image URL
  },
  {
    id: 14,
    // name: 'John Doe',
    // course: 'Web Development',
    // companyName: 'Tech Co.',
    image: "https://caddeskindia.com/wp-content/uploads/2021/01/two.png", // Replace with actual image URL
  },

  // Repeat similar data for other cards
];

const Recruiter = ({ id, image }) => (
  <div className="recruiter_card" key={id}>
    <img src={image} alt="company" className="Recruiter-image" />
    {/* <div className="">
        <h3 className="">{name}</h3>
        <p className="">{course}</p>
        <p className="card-details">{companyName}</p>
      </div> */}
  </div>
);

const Ourrecruiter = () => {
  return (
    <div className="our_recruiter-container">
      {data.map((cardData) => (
        <Recruiter {...cardData} />
      ))}
    </div>
  );
};

export default Ourrecruiter;
