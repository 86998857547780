import React from 'react';
import { FaArrowRight } from "react-icons/fa6";
import '../internshipcard.css'

const Internshipcard = (props) => {
    const { details } = props;
    const { title, image, description,link} = details;
    
    return (
        <>
            <div className="col-12 col-md-3">

                <div className='internship_container'>
                    <div>
                        <img src={image} alt={title} className="internship_card_image"/>
                    </div>
                    <h1 className='internship_heading'>
                        {title}
                    </h1>

                    <div>
                        <p className='internship_paragraph'>{description}</p>
                    </div>

                    <div>
                        <a href={link} target='_blank'>Read more...
                        <FaArrowRight />
                        </a>
                    </div>

                </div>
            </div>
        </>
    );
};

export default Internshipcard;
