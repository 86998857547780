import React, { useState } from "react";
import { Link } from "react-router-dom";

function Project() {
  const [showAllProjects, setShowAllProjects] = useState(false);

  const projects = [
    {
      image: "img/Autocad.jpg",
      link: "/Autocad",
      title: "Auto CAD",
    },
    {
      image: "img/Nxcadp.jpg",
      link: "/Nxcad",
      title: "NX CAD",
    },
    {
      image: "img/Catiop.jpg",
      link: "/Catia",
      title: "Catia",
    },
    {
      image: "img/Creop.jpg",
      link: "/Creo",
      title: "Creo",
    },
    {
      image: "img/Etabs.jpg",
      link: "/Etabs",
      title: "Etabs",
    },
    {
      image: "img/Fusionp.jpg",
      link: "/Fusion",
      title: "Fusion",
    },
    {
      image: "img/Solidp.jpg",
      link: "/Solid",
      title: "Solid works",
    },
    {
      image: "img/Revitap.jpg",
      link: "/Revit Architecture",
      title: "Revit Arch",
    },
    {
      image: "img/Vrayp.jpg",
      link: "/Vray",
      title: "V-rays",
    },
    // ... add more project data
  ];

  // Set the number of projects to display initially
  const projectsToDisplay = showAllProjects ? projects : projects.slice(0, 3);

  const handleViewMoreToggle = () => {
    setShowAllProjects(!showAllProjects);
  };

  return (
    <div className="container-fluid py-1">
      <div className="container py-1">
        <div className="text-center mb-5 wow fadeInUp" data-wow-delay=".3s">
          <h5 className="mb-2 px-3 py-1 text-dark rounded-pill d-inline-block border border-2 border-primary">
            CAD Desk Mahadevpura
          </h5>
          <h1 className="display-5">Our Courses</h1>
        </div>
        <div className="row g-5">
          {projectsToDisplay.map((project, index) => (
            <div
              key={index}
              className="col-xxl-4 col-lg-6 col-md-6 col-sm-12 wow fadeInUp"
              data-wow-delay=".3s"
            >
              <div className="project-item">
                <div className="project-left bg-dark"></div>
                <div className="project-right bg-dark"></div>
                <img
                  src={project.image}
                  className="img-fluid h-100"
                  alt="img"
                />
                <Link
                  to={project.link}
                  className="fs-4 fw-bold text-center"
                  style={{ height: "15px" }}
                >
                  {project.title}
                </Link>
              </div>
            </div>
          ))}
        </div>
        <div className="text-center py-1 mt-1">
          <button
            className="btn  bg-warning"
            onClick={handleViewMoreToggle}
            style={{ marginTop: "10px" }}
          >
            {showAllProjects ? "Show Less" : "View More"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default Project;
