import React, { useState } from 'react';
import axios from 'axios';
import './AddDataForm.css'; // Import CSS file for styling

function AddDataForm() {
    const [name, setName] = useState('');
    const [age, setAge] = useState('');
    const [collegeName, setCollegeName] = useState('');
    const [fatherName, setFatherName] = useState('');
    const [course, setCourse] = useState('');
    const [certificateType, setCertificateType] = useState('');
    const [yop, setYop] = useState('');
    const [date, setDate] = useState('');
    const [softwarecovered, setsoftwarecovered] = useState('');
    const [regno, setregno] = useState('');
    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            await axios.post('/api/addCourseStudent', {
                name,
                age,
                collegeName,
                fatherName,
                course,
                certificateType,
                yop,
                date,
                softwarecovered,
                regno
            });

            setName('');
            setAge('');
            setCollegeName('');
            setFatherName('');
            setCourse('');
            setCertificateType('');
            setYop('');
            setDate('');
            setsoftwarecovered('');
            setregno('')

            window.alert('Data added successfully');
        } catch (error) {
            console.error('Error adding data:', error.message);
        }
    };

    return (
        <div className="form-container">
            <div className="card shadow form-card">
                <div className="form-content">
                    <h2>Add Data</h2>
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label htmlFor="name">Name:</label>
                            <input
                                type="text"
                                id="name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="age">Age:</label>
                            <input
                                type="number"
                                id="age"
                                value={age}
                                onChange={(e) => setAge(e.target.value)}
                                required
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="collegeName">College Name:</label>
                            <input
                                type="text"
                                id="collegeName"
                                value={collegeName}
                                onChange={(e) => setCollegeName(e.target.value)}
                                required
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="fatherName">Father's Name:</label>
                            <input
                                type="text"
                                id="fatherName"
                                value={fatherName}
                                onChange={(e) => setFatherName(e.target.value)}
                                required
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="course">Course:</label>
                            <input
                                type="text"
                                id="course"
                                value={course}
                                onChange={(e) => setCourse(e.target.value)}
                                required
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="certificateType">Certificate Type:</label>
                            <input
                                type="text"
                                id="certificateType"
                                value={certificateType}
                                onChange={(e) => setCertificateType(e.target.value)}
                                required
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="yop">Year of Passing:</label>
                            <input
                                type="number"
                                id="yop"
                                value={yop}
                                onChange={(e) => setYop(e.target.value)}
                                required
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="date">Date:</label>
                            <input
                                type="date"
                                id="date"
                                value={date}
                                onChange={(e) => setDate(e.target.value)}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="softwarecovered">softwarecovered</label>
                            <input
                                type="text"
                                id="softwarecovered"
                                value={softwarecovered}
                                onChange={(e) => setsoftwarecovered(e.target.value)}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="Regno">Regno</label>
                            <input
                                type="text"
                                id="regno"
                                value={regno}
                                onChange={(e) => setregno(e.target.value)}
                                required
                            />
                        </div>

                        <button type="submit">Submit</button>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default AddDataForm;
