import { Link } from "react-router-dom";
import "../AppItem.css";

const AppItem = (props) => {
  const { appDetails } = props;
  const { appName, imageUrl, link, description } = appDetails;

  return (
    <li className="app-item">
      <div class="card-inner">
        <div class="card-front">
          <img className="app-image" src={imageUrl} alt={appName} />
          <p className="app-name">{appName}</p>
        </div>

        <div class="card-back">
          <p className="description_appitem">{description}</p>

          <Link to={link}>
            <button className="learm_more_button">Learn more</button>
          </Link>
        </div>
      </div>
    </li>
  );
};

export default AppItem;
