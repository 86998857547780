import React, { useState,Fragment } from 'react'
import '../style.scss';
const ListToggle = () => {
  const [showList1, setShowList1] = useState('');
  const [showList2, setShowList2] = useState('');
  const [showList3, setShowList3] = useState('');
  const [showList4, setShowList4] = useState('');
  const [showList5, setShowList5] = useState('');
  const [showList6, setShowList6] = useState('');
  const [showList7, setShowList7] = useState('');
  const [showList8, setShowList8] = useState('');
  const toggleList = (listNumber) => {
    if (listNumber === 1) {
      setShowList1(!showList1);
      setShowList2(false);
      setShowList3(false);
      setShowList4(false);
      setShowList5(false);setShowList6(false); setShowList7(false); setShowList8(false);
    } else if (listNumber === 2) {
      setShowList2(!showList2);
      setShowList1(false);
      setShowList3(false);
      setShowList4(false);
      setShowList5(false);setShowList6(false); setShowList7(false); setShowList8(false);
    }
    else if (listNumber === 3) {
      setShowList3(!showList3);
      setShowList2(false);
      setShowList1(false);
      setShowList4(false);
      setShowList5(false);setShowList6(false); setShowList7(false); setShowList8(false);
  }else if (listNumber === 4) {
    setShowList4(!showList4);
    setShowList1(false);
    setShowList3(false);
    setShowList2(false);
    setShowList5(false);setShowList6(false); setShowList7(false); setShowList8(false);
  }else if (listNumber === 5) {
    setShowList5(!showList5);
    setShowList1(false);
      setShowList3(false);
      setShowList4(false);
      setShowList2(false);
      setShowList6(false); setShowList7(false); setShowList8(false);
  }else if (listNumber === 6) {
    setShowList6(!showList6);
    setShowList1(false);
      setShowList3(false);
      setShowList4(false);
      setShowList2(false); setShowList7(false); setShowList8(false); setShowList5(false);
  }
  else if (listNumber === 7) {
    setShowList7(!showList7);
    setShowList1(false);
      setShowList3(false);
      setShowList4(false);
      setShowList2(false); setShowList5(false); setShowList6(false); setShowList8(false);
    }
      else if (listNumber === 8) {
        setShowList8(!showList8);
        setShowList1(false);
          setShowList3(false);
          setShowList4(false);
          setShowList2(false);
          setShowList5(false);setShowList7(false);setShowList6(false);}}
  return (
    <Fragment>
      
      {/* <div class="botam-navbar">
        <p style="margin-left: 15px; color: #fff">To avail the weekly free training offer <br />Sign up Now.</p>
        <a href="#corseform" class="active">To know course fee.</a>
        <a href="#corseform" class="active">Know More</a>
      </div> */}


      <div id="main-wrapper">
        <div class="site-wrapper-reveal">
          <div class="about-banner-wrap vision-space bg-img responsive_banner_courses">
            <div class="container">
              <div class="row">
                <div class="col-lg-6 section-space--pt_100">
                  <h1 className='mobile-page-heading'>Solidworks</h1>
                  <p className="mobile-page-paragraph">SolidWorks is a 3D parametric design software any other like product you can think of..</p>
                </div>
                <div class="col-lg-2">
                </div>
                <div class="col-lg-4">
                  <div class="form-container mx-auto mx-xl-0 ml-xl-auto border" id="corseform" style={{marginTop:'20px',backgroundColor:'white',alignItems:'center',marginLeft:'100px'}}>
                    <div id="signup-form">
                      {/* <p style={{ color: "black" }}>Your Enquiry Successfully Submit</p> */}
                      <form id="basic-form" name="yoursite_wp_mail_from_name" method="post" />
                      <input type="hidden" name="courseId" value="753" />
                      <input type="hidden" name="category" value="16" />
                      <div class="form-group"  >
                        <label>Name:
                        <input type="text" id="fname" name="fname" class="form-control" placeholder="Enter Your Name" value="" required="" /></label>
                      </div>
                      <div class="form-group">
                        <label>Email:</label>
                        <input type="email" id="email" name="email" class="form-control" placeholder="Enter Your Email Address" value="" required="" />
                      </div>
                      <div class="form-group">
                        <label>Mobile:</label>
                        <input type="number" id="phone" name="phone" class="form-control" placeholder="Enter Your mobile number" value="" required="" />
                      </div>
                      <div class="form-group mb-0" >
                        <div class="form-row">
                          <div class="form-group col-6">
                            <label class="control-label">State</label>
                            <select id="states" name="states" class="form-control" required="">
                              <option value="">SELECT STATE</option>
                              <option value="2">Andhra Pradesh</option>
                              <option value="5">Bihar</option>
                              <option value="12">Gujarat</option>
                              <option value="13">Haryana</option>
                              <option value="14">Himachal Pradesh</option>
                              <option value="16">Jharkhand</option>
                              <option value="17">Karnataka</option>
                              <option value="21">Maharashtra</option>
                              <option value="28">Punjab</option>
                              <option value="29">Rajasthan</option>
                              <option value="31">Tamil Nadu</option>
                              <option value="32">Telangana</option>
                              <option value="35">Uttar Pradesh</option>
                            </select>
                          </div>
                          <div class="form-group col-6 city">
                            <label class="control-label">Center</label>
                            <select name="city" id="city" class="form-control center" required="">
                              <option value="">SELECT CENTRE</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="form-group mb-0"style={{marginLeft:'130px',paddingBottom:'30px'}}>
                        <button type="submit" id="btn" class="btn btn-primary contest_register_now" name="register_users">Apply Now</button>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <nav aria-label="breadcrumb">
        <ol class="breadcrumb" >
          <li class="breadcrumb-item" >
            <a href="/" >Home</a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">Solidworks</li>
        </ol>
      </nav>



      <div class="vision-overview  " style={{marginTop:'-180px'}}>
        <div class="container">
          <div class="section-title-wrap text-center">
            <br />
            <br />
            <h3 class="heading"><span class="text-color-primary" style={{ fontSize: "25px" ,color:'#ffc107' }}> Training Highlights</span></h3>
          </div>
          <div class="row">
            <div class="col-12 col-lg-4">
              <div class="item-container d-flex align-items-center">
                <div class="image-container">
                  <img src="https://itdeskindia.com/wp-content/uploads/2021/02/1.png" class="lazy-load" />
                </div>
                <div class="content-container">
                  <h3 class="title">Instructor Led Training</h3>
                  <p class="description"></p>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-4">
              <div class="item-container d-flex align-items-center">
                <div class="image-container">
                  <img src="https://itdeskindia.com/wp-content/uploads/2021/02/2.png" class="lazy-load" />
                </div>
                <div class="content-container">
                  <h3 class="title">Weekly Doubt clearing Session</h3>
                  <p class="description"></p>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-4">
              <div class="item-container d-flex align-items-center">
                <div class="image-container">
                  <img src="https://itdeskindia.com/wp-content/uploads/2021/02/offline-revision-min.png" class="lazy-load" />
                </div>
                <div class="content-container">
                  <h3 class="title">Lifetime Assistance for Revision</h3>
                  <p class="description"></p>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-4">
              <div class="item-container d-flex align-items-center">
                <div class="image-container">
                  <img src="https://itdeskindia.com/wp-content/uploads/2021/02/LOGIN.jpg" class="lazy-load" />
                </div>
                <div class="content-container">
                  <h3 class="title">Lifetime Login Access to Student Dashboard</h3>
                  <p class="description"></p>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-4">
              <div class="item-container d-flex align-items-center">
                <div class="image-container">
                  <img src="https://itdeskindia.com/wp-content/uploads/2021/02/certificate-icon-10.png" class="lazy-load" />
                </div>
                <div class="content-container">
                  <h3 class="title">World-Wide Valid Certification</h3>
                  <p class="description"></p>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-4">
              <div class="item-container d-flex align-items-center">
                <div class="image-container">
                  <img src="https://itdeskindia.com/wp-content/uploads/2021/02/books-min.png" class="lazy-load" />
                </div>
                <div class="content-container">
                  <h3 class="title">Study Material, Assignments and Projects</h3>
                  <p class="description"></p>
                </div>
              </div>
            </div>

          </div>
          <br /> <br />





          <div class="section-title-wrap text-center" style={{marginTop:'-140px'}}>
            <h3 class="heading"><span class="text-color-primary" style={{ fontSize: "30px",color:'#ffc107' }}> Course Highlights</span></h3>
          </div> 
           <div class="row">
            <div class="d-flex flex-row flex-lg-column align-items-lg-center mx-auto">
              <div class="dashed-border">
                <img class="d-block d-lg-none" src="https://itdeskindia.com/wp-content/themes/itdesk_theme/assets/images/dotted_line_mobile.svg"/>
                <img class="d-none d-lg-block" src="https://itdeskindia.com/wp-content/themes/itdesk_theme/assets/images/dotted_line_desktop.svg" width={1000}/>
              </div>
              <div class="d-flex flex-column  align-items-center flex-lg-row justify-content-lg-center">

                <div class="item-container  justify-content-between d-flex flex-lg-column align-items-center ">
                  <div class="number-container" style={{marginLeft: '1px'}}  >
                    <div class="number-box">1</div>
                    <div class="arrow-container" >
                      <div class="arrow arrow-left"></div>
                      <div class="arrow arrow-right"></div>
                    </div>
                  </div>
                  <div class="content-container">
                    <p class="activity"  >World's Most Popular  2D-Drafting Software</p>
                  </div>
                </div>
                <div class="item-container d-flex flex-lg-column align-items-center" >
                  <div class="number-container">
                    <div class="number-box">2</div>
                    <div class="arrow-container">
                      <div class="arrow arrow-left"></div>
                      <div class="arrow arrow-right"></div>
                    </div>
                  </div>
                  <div class="content-container">
                    <p class="activity">Widely used in all  Types of Industries</p>
                  </div>
                </div>
                <div class="item-container d-flex flex-lg-column align-items-center">
                  <div class="number-container">
                    <div class="number-box">3</div>
                    <div class="arrow-container">
                      <div class="arrow arrow-left"></div>
                      <div class="arrow arrow-right"></div>
                    </div>
                  </div>
                  <div class="content-container">
                    <p class="activity">Productivity Tools and Design Center</p>
                  </div>
                </div>
                <div class="item-container d-flex flex-lg-column align-items-center">
                  <div class="number-container">
                    <div class="number-box">4</div>
                    <div class="arrow-container">
                      <div class="arrow arrow-left"></div>
                      <div class="arrow arrow-right"></div>
                    </div>
                  </div>
                  <div class="content-container">
                    <p class="activity">Quick to grasp and easily understood</p>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <div class="row" style={{marginTop:'-120px'}}>
            <div class="col-lg-12">
              <br />
              <br />
              {/* <!-- <h3 class="heading" style="font-size: 21px;">Course Overview</h3> --> */}
              <h3 class="heading"><span class="text-color-primary" style={{ fontSize: "30px",color:'#ffc107' }}> Course Overview</span></h3>
              <br />
              <br />
              <div >
               <p style={{marginTop:'-70px',fontSize:'15px', textAlign:'justify'}}>SOLIDWORKS is powerful 3D CADD software that helps in shortening the design cycle and increases productivity. SOLIDWORKS is the world’s leading product design software that facilitates us to deliver innovative solutions to the engineering or technical industries. CAD DESK offers the best classroom training in India that helps the learner to get in-depth knowledge of various tools and features available in the Solidworks software.

Being a 2D and 3D parametric modeler, SOLIDWORKS is essential to most engineering and drafting systems. CAD DESK provides software training on a wide range of courses in the field of CAD, CAM & CAE and also they put great emphasis on delivering quality training to students. Further, while taking the coaching classes for SOLIDWORKS, students are encouraged to do Internship and also gets an option to go for industrial visits.

Further while taking the SOLIDWORKS training, students get many benefits like student panel, study materials, e-books, tutorials, and projects which gives them a better understanding of the concepts of software and also helps them to grow in their career. CAD DESK students get an option to transfer themselves to any of our CAD Franchise Centre in India, even if they have been registered at CAD DESK Jaipur or at any other CAD center. After the course completion, the student receives a SOLIDWORKS certificate and also gets placement assistance through which they can be placed in any of the companies all over India.</p>
              </div>



            </div>
          </div>
        </div>
      </div>

      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
      <div style={{ flex: 1 }}>

      <h3 class="heading"><span class="text-color-primary responsive_course_content" > Course Contents</span></h3>

      <div className='row'  >   {" "}

      <div className="responsive_courese_image_container_mobile_view">
        <img
          src="img/Solidp.jpg"
          alt="Course Image"
          className="responsive_course_image_mobile_view"
        />
      </div>
      
      <div className="responsive_introduction_container">

            <button class=" collapsed responsive_introduction_button" data-toggle="collapse" data-target="#collapse__111" aria-expanded="false" aria-controls="collapse__111" onClick={() => toggleList(1)} >

        {/* {showList1 ? 'Close List 1' : 'Show List 1'}Button */}{" "} {showList1 ? <i className="fas fa-arrow-up"></i> : <i className="fas fa-arrow-down" style={{color:'black'}}></i>} Introduction
      </button>
      {showList1 && (
        <ul>
         <li> Introduction to PDC & CAD/CAM/CAE</li>
         <li>Parametric and Non-Parametric Modelling</li>
         <li>Introduction to Solidworks</li>
         <li>GUI of Solidworks</li>
          
        </ul>
      )}</div>

      <div className='responsive_introduction_container'>
      <button class=" collapsed responsive_introduction_button" data-toggle="collapse" data-target="#collapse__111" aria-expanded="false" aria-controls="collapse__111" onClick={() => toggleList(2)} >
        {/* {showList1 ? 'Close List 1' : 'Show List 1'}Button */} {showList2 ? <i className="fas fa-arrow-up"></i> : <i className="fas fa-arrow-down" style={{color:'black'}}></i>} Sketch Module
      </button>
      {showList2 && (
        <ul>
         <li>Sketch Entities</li>
         <li>Sketch Modifying Tools</li>
         <li>Parametric Sketching</li>
         <li>Geometric Relations</li>
         <li>Dimensional Constraints</li>
        </ul>
      )}
      </div>

      <div className="responsive_introduction_container">
      <button class=" collapsed responsive_introduction_button" data-toggle="collapse" data-target="#collapse__111" aria-expanded="false" aria-controls="collapse__111" onClick={() => toggleList(3)} >
        {/* {showList1 ? 'Close List 1' : 'Show List 1'}Button */} {showList3 ? <i className="fas fa-arrow-up"></i> : <i className="fas fa-arrow-down" style={{color:'black'}}></i>} Solid Modelling
      </button>
      {showList3 && (
        <ul>
        <li> Creating Base Features</li>
        <li>Creating Cut Features</li>
        <li>Creating Reference Geometries</li>
        <li>Curves in Solidworks</li>
        <li>Advanced Modelling Tools</li>
        <li>Advanced Fastening Features</li>
        </ul>
      )}</div>
      <div className="responsive_introduction_container">
      <button class=" collapsed responsive_introduction_button" data-toggle="collapse" data-target="#collapse__111" aria-expanded="false" aria-controls="collapse__111" onClick={() => toggleList(4)} >
        {/* {showList1 ? 'Close List 1' : 'Show List 1'}Button */} {showList4 ? <i className="fas fa-arrow-up"></i> : <i className="fas fa-arrow-down" style={{color:'black'}}></i>} Sheet Metal Design
      </button>
      {showList4 && (
        <ul>
         <li>Creating the Base Flange</li>
         <li>Understanding the Feature Manager Design tree of a Sheet Metal Component</li>
         <li>Creating the Edge Flange</li>
         <li>Creating Tabs</li>
         <li>Creating the Sketched Bend</li>
         <li>Creating the Miter Flange</li>
         <li>Creating Closed Corners</li>
         <li>Creating Hems</li>
         <li>Creating the Jog Bend</li>
         <li>Breaking the Corners</li>
         <li>Creating Cuts on the Planar Faces of the Sheet Metal Components</li>
         <li>Creating Lofted Bends</li>
         <li>Converting a Solid Body into a Sheet Metal Part</li>
         <li>Designing a sheet Metal Part from a Solid Shelled model</li>
         <li>Ripping the Edges</li>
         <li>Forming Tool design</li>
        </ul>
      )}</div>
      <div className='responsive_introduction_container'>
      <button class=" collapsed responsive_introduction_button" data-toggle="collapse" data-target="#collapse__111" aria-expanded="false" aria-controls="collapse__111" onClick={() => toggleList(5)} >
        {/* {showList1 ? 'Close List 1' : 'Show List 1'}Button */} {showList5 ? <i className="fas fa-arrow-up"></i> : <i className="fas fa-arrow-down" style={{color:'black'}}></i>} 
     {" "}Surface Modelling </button>
      {showList5 && (
        <ul>
       <li> Creating an Extruded Surface</li>
       <li>Creating a Revolved Surface</li>
       <li>Creating a Swept Surface</li>
       <li>Creating a Lofted Surface</li>
       <li>Creating a Boundary Surface</li>
       <li>Creating a Planar Surface</li>
       <li>Creating a Fill Surface</li>
       <li>Creating a Radiated Surface</li>
       <li>Offsetting Surfaces</li>
       <li>Trimming Surface</li>
       <li>Untrimming Surfaces</li>
       <li>Extending Surfaces</li>
       <li>Knitting Surfaces</li>
       <li>Filleting Surfaces</li>
       <li>Creating a Mid-Surface</li>
       <li>Deleting Holes from Surfaces</li>
       <li>Replacing Faces</li>
       <li>Deleting Faces</li>
       <li>Moving and Coping Surfaces</li>
       <li>Mirroring Surface Bodies</li>
       <li>Adding Thickness to Surface Bodies</li>
       <li>Creating a Thicken Surface Cut</li>
       <li>Creating a Surface Cut</li>
        </ul>
      )}</div>
      <div className="responsive_introduction_container">
      <button class=" collapsed responsive_introduction_button" data-toggle="collapse" data-target="#collapse__111" aria-expanded="false" aria-controls="collapse__111" onClick={() => toggleList(6)} >
        {/* {showList1 ? 'Close List 1' : 'Show List 1'}Button */} {showList6 ? <i className="fas fa-arrow-up"></i> : <i className="fas fa-arrow-down" style={{color:'black'}}></i>} Weldment Design
      </button>
      {showList6 && (
        <ul>
        <li>3D Sketching</li>
        <li>Assign Structural Members</li>
        <li>Performing end operations</li>
        <li>Using End Cap, Gusset</li>
        </ul>
      )}</div>

<div className="responsive_introduction_container">
      <button class=" collapsed responsive_introduction_button" data-toggle="collapse" data-target="#collapse__111" aria-expanded="false" aria-controls="collapse__111" onClick={() => toggleList(7)} >
        {/* {showList1 ? 'Close List 1' : 'Show List 1'}Button */} {showList7 ? <i className="fas fa-arrow-up"></i> : <i className="fas fa-arrow-down" style={{color:'black'}}></i>} Drawing Module
      </button>
      {showList7 && (
        <ul>
      <li>  Adding New Sheets</li>
      <li>Editing the Sheet Format</li>
      <li>Creating different views from the 3D Model</li>
      <li>Create Drawing from Exploded View</li>
      <li>Editing and Modifying Drawing Views</li>
      <li>Adding Annotations to Drawing Views</li>
      <li>Adding Balloons to the Drawing Views</li>
      <li>Adding the Bill of Materials (BOM) to a Drawing
      Output as PDF</li>
        </ul>
      )}</div>
      <div className="responsive_introduction_container">
      <button class=" collapsed responsive_introduction_button" data-toggle="collapse" data-target="#collapse__111" aria-expanded="false" aria-controls="collapse__111" onClick={() => toggleList(8)} >
        {/* {showList1 ? 'Close List 1' : 'Show List 1'}Button */} {showList8 ? <i className="fas fa-arrow-up"></i> : <i className="fas fa-arrow-down" style={{color:'black'}}></i>} Assembly Module
      </button>
      {showList8 && (
        <ul>
      <li> Introduction to Assembly</li>
      <li>Assembly Approaches</li>
      <li>Applying Standard Mate</li>
      <li>Applying Advanced Mate</li>
      <li>Applying Mechanical Mates</li>
      <li>Advance Assembly Tools</li>
        </ul>
      )}</div>
      </div>
      </div>
      
      <div className='responsive_courese_image_container'>
      <img src="img/Solidp.jpg" alt="Course Image" className='responsive_course_image' />
      
      </div>
      
      </div> 





 
     


      <div class="contact-us-section-wrappaer ">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-7 col-lg-7">
              <div class="conact-us-wrap-one">
                <br />
                <br />
                <h5 class="heading mb-20">How will you get your certificate?</h5>
                <div class="center_details">
                  {/* <!--   <h5 class="heading mb-20">CERTIFICATE FAQ</h5> --> */}
                  <p>Upon successful completion of the program, each intern receives a prestigious Digital Marketing Internship Certificate. This credential not only recognizes their achievements but also enhances their employability.</p>
                  <p style={{ textAlign: "justify" }}>IT DESK is a pioneer in CS/IT training and most of the industries give great recognition to its certificate throughout India and abroad. IT DESK Certificates are ISO verified which makes it highly reputed comparing to the other local brands and helps the student get prioritize while going for job opportunities in the industry. IT DESK Software Training is conducted by highly knowledgeable trainers which help students to enhance their skill set by putting great emphasizes on project-based learning.</p>
                  {/* <!-- <a class="btn btn-primary">Get Certificate</a> --> */}
                </div>
              </div>
            </div>
            <div class="col-lg-5 col-lg-5">
              <div class="contact-form-wrap">
                {/* <!-- <h5 class="heading mb-20">How will you get your certificate?</h5> --> */}
                <img src="img/cert.jpg" style={{ width: " 100%", marginTop:'100px',height: "370px" }} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />

    </Fragment >
  )
}

export default ListToggle
