import React,{useEffect,useState} from "react";
import { ReactDOM } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import NavBar from "./Components/NavBar";
import Footer from "./Components/Footer";
import Header from "./Components/Header";
import Home from "./Components/Home";
import Login from "./pages/Login";

import AboutUs from "./Components/AboutUs";
import SignUp from "./pages/SignUp";

import Whatsapp from "./Components/Whatsapp";
import Ansys from "./pages/Ansys";
import Autocad from "./pages/Autocad";
import Catia from "./pages/Catia";
import Civil3d from "./pages/Civil3d";
import Creo from "./pages/Creo";
import Etabs from "./pages/Etabs";
import Fusion from "./pages/Fusion";
import GD from "./pages/GD";
import Google from "./pages/Google";
import Inventer from "./pages/Inventer";
import Lumion from "./pages/Lumion";

import NX from "./pages/NX";
import Photoshop from "./pages/Photoshop";
import Primavera from "./pages/Primavera";
import RevitA from "./pages/RevitA";
import RevitS from "./pages/RevitS";
import Solidworks from "./pages/Solidworks";
import Staadpro from "./pages/Staadpro";
import Vray from "./pages/Vray";
import Msproject from "./pages/Msproject";
import Contact from "./Components/Contact";

import Feedback from "./Components/Feedback";

import AppStore from "../src/Components/AppStore";
import Internshipstore from "./Components/Internshipstore";
import Onlinecourses from "./Components/Onlinecourses";
import Onlinecourse from "./Components/Onlinecourse";
import Placement from "./Components/Placement";
import Enquiry from "./Components/Enquiry";
import ThankYou from "./pages/ThankYou";
import Dashboard from "./pages/Dashboard";
import Certificate from "./pages/Certificate";
import Register from "./pages/Register";

const ProtectedRoute = ({ element, isLoggedIn, path }) => {
  return isLoggedIn ? element : <Navigate to="/login" />;
};





function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  useEffect(() => {
    // Check if admin is logged in from sessionStorage
    const isAdminLoggedIn = sessionStorage.getItem('isAdminLoggedIn');
    if (isAdminLoggedIn) {
      setIsAdmin(true);
      setIsLoggedIn(true); // Assuming admin login also means user is logged in
    }
  }, []);

  const handleLogin = () => {
    // Your login logic here
    setIsLoggedIn(true);
    setIsAdmin(true);
    // Store admin login state in sessionStorage
    sessionStorage.setItem('isAdminLoggedIn', 'true');
  };

  const handleLogout = () => {
    // Your logout logic here
    setIsLoggedIn(false);
    setIsAdmin(false);
    sessionStorage.removeItem('isAdminLoggedIn');
  };

  return (
    <>
      <Header />
      <NavBar isLoggedIn={isLoggedIn} isAdmin={isAdmin} onLogout={handleLogout} />
      <Routes>
        <Route path="/" element={<Home />} />

        <Route path="/Signup" element={<SignUp />} />

        <Route path="/Login"  element={<Login onLogin={handleLogin} />} />
        <Route path="/AboutUs" element={<AboutUs />} />

        {/* <Route path="/cardlist" element={<CardList />} />
  <Route path="/coursecard" element={<CourseCard />} /> */}
        {/* <Route path="/details/:id" element={<CardDetails />} /> */}

        <Route path="/Feedback" element={<Feedback />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/Courses" element={<AppStore />} />
        <Route path="/Internship" element={<Internshipstore />} />
        <Route path="/Placement" element={<Placement />} />
        <Route path="/ThankYou" element={<ThankYou />} />


        {/* pages */}
        <Route path="/Ansys" element={<Ansys />} />
        <Route path="/Autocad" element={<Autocad />} />
        <Route path="/Catia" element={<Catia />} />
        <Route path="/Civil3d" element={<Civil3d />} />
        <Route path="/Creo" element={<Creo />} />
        <Route path="/Etabs" element={<Etabs />} />
        <Route path="/Fusion" element={<Fusion />} />
        <Route path="/Gd&t" element={<GD />} />
        <Route path="/GoogleSketchup" element={<Google />} />
        <Route path="/Inventer" element={<Inventer />} />
        <Route path="/Lumion" element={<Lumion />} />

        <Route path="/Msproject" element={<Msproject />} />
        <Route path="/Nx cad" element={<NX />} />
        <Route path="/PhotoShop" element={<Photoshop />} />
        <Route path="/Primavera" element={<Primavera />} />
        <Route path="/Revit Architecture" element={<RevitA />} />
        <Route path="/Revit Structure" element={<RevitS />} />
        <Route path="/Solidworks" element={<Solidworks />} />
        <Route path="/Staadpro" element={<Staadpro />} />
        <Route path="/V-ray" element={<Vray />} />

        <Route path="/Onlinecourses" element={<Onlinecourses />} />

        <Route path="/Onlinecourse" element={<Onlinecourse />} />
        <Route path="/dashboard" element={<ProtectedRoute element={<Dashboard />} isLoggedIn={isLoggedIn} />} />
        <Route path="/certi" element={<Certificate />} />
        <Route path="/register" element={<Register />} />
      </Routes>
      <Whatsapp />
      <Enquiry />
      <Footer />
    </>
  );
}

export default App;





