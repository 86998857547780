import React from 'react'
import { Link } from 'react-router-dom';
const iconSize = 50;
function Whatsapp() {
  return (
    <div className='fixed-bottom  p-3' style={{zIndex:"6",right:"initial"}}> 
    <Link to="https://wa.me/919901371386?text=I'm Interested , Tell me more about the course ?  " target='_blank'><i className="fab fa-whatsapp green" style={{fontSize:iconSize}}></i></Link>
    {/* <img src="img/whatsapp.jpg" width={60} height={50}  /> </a> */}
    </div>
  )
}

export default Whatsapp