import React, { useState } from "react";
import OurPlacement from "./Ourplacement.js";
import PlacementProcess from "../Components/Placementprocess.js";
import Ourrecruiter from "../Components/Ourrecruiter.js";
import "../placement.css";

const App = () => {
  const [activeTab, setActiveTab] = useState("ourPlacement");

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <div className="app-container">
      {/* TAB ITEMS START */}
      <div className="tabs-container">
        <div
          className={`tab ${activeTab === "ourPlacement" ? "active" : ""}`}
          onClick={() => handleTabClick("ourPlacement")}
        >
          <img src="img/placement.png" className="placement_icon" alt="icon" />
          Our Placement
        </div>
        <div
          className={`tab ${activeTab === "placementProcess" ? "active" : ""}`}
          onClick={() => handleTabClick("placementProcess")}
        >
          <img
            src="img/placement process.jpg"
            className="placement_icon"
            alt="icon"
          />
          Placement Process
        </div>
        <div
          className={`tab ${activeTab === "ourRecruiter" ? "active" : ""}`}
          onClick={() => handleTabClick("ourRecruiter")}
        >
          <img
            src="img/recruitment.png"
            className="placement_icon"
            alt="icon"
          />
          Our Recruiter
        </div>
        <div
          className={`tab ${
            activeTab === "courseAndPlacementCell" ? "active" : ""
          }`}
          onClick={() => handleTabClick("courseAndPlacementCell")}
        >
          <img src="img/book.png" className="placement_icon" alt="icon" />
          Course & Placement Cell
        </div>
      </div>
      {/* TABS END */}

      {/*START CRAD INFORMATION*/}

      <div className="content-container">
        {activeTab === "ourPlacement" && (
          <div className="content">
            {/* Display information related to Our Placement */}
            <h1 className="placement_procss_heading">Our Placement</h1>
            <OurPlacement />
          </div>
        )}

        {activeTab === "placementProcess" && (
          <div className="content">
            {/* Display information related to Placement Process */}
            <PlacementProcess />
          </div>
        )}

        {activeTab === "ourRecruiter" && (
          <div className="content">
            {/* Display information related to Our Recruiter */}
            {/* <h2>Our Recruiter</h2>
            <div className="recruiter-card">
              <img src="recruiter-logo.png" alt="Recruiter Logo" />
              <p>Recruiter details...</p>
            </div> */}
            <Ourrecruiter />
          </div>
        )}

        {activeTab === "courseAndPlacementCell" && (
          <div className="content">
            {/* Redirect to another page for Course & Placement Cell */}
            <a href="#">Go to Course & Placement Cell Page</a>
          </div>
        )}
      </div>
      {/* END INFORMATION */}
    </div>
  );
};

export default App;
