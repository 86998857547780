import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const left = 500;
const word = 20; // Adjust the word variable to control the space between links

function NavBar({ isAdmin, onLogout, isLoggedIn }) {
  const [scrolled, setScrolled] = useState(false);
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);

  const handleScroll = () => {
    const isScrolled = window.scrollY > 100;
    setScrolled(isScrolled);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleNavbarToggle = () => {
    setIsNavbarOpen(!isNavbarOpen);
  };

  const closeNavbar = () => {
    setIsNavbarOpen(false);
  };
  const [showLogoutToast, setShowLogoutToast] = useState(false);

  useEffect(() => {
    // Check if user is logged in from sessionStorage
    const userLoggedIn = sessionStorage.getItem('isLoggedIn');
    if (userLoggedIn === 'true') {
      // Set isLoggedIn prop if user is logged in
      isLoggedIn = true;
    }
  }, []);

  const handleLogout = () => {
    // Perform logout actions
    onLogout();
    setShowLogoutToast(true);
    // Hide the toast after 3 seconds
    setTimeout(() => {
      setShowLogoutToast(false);
      // Clear login status from sessionStorage
      sessionStorage.setItem('isLoggedIn', 'false');
    }, 3000);
  };

  return (
    <div className={`container-fluid ${scrolled ? "bg-light" : ""}`}>
      <div className="container">
        <nav
          className={`navbar navbar-expand-lg navbar-light ${scrolled ? "fixed-top bg-white" : "bg-black"
            }`}
        >
          <Link to="/home" className="navbar-brand">
            <img src="./img/cad1.png" height={50} width={200} alt="Logo" />
          </Link>
          <button
            className="navbar-toggler bg-primary"
            type="button"
            onClick={handleNavbarToggle}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className={`collapse navbar-collapse ${isNavbarOpen ? "show" : ""}`}>

            <div className="navbar-nav ml-auto">
              <Link
                to="/"
                className="nav-item nav-link active myhome"
                onClick={closeNavbar}
                style={{ marginRight: word }}
              >
                Home
              </Link>
              <Link
                to="/AboutUs"
                className="nav-item nav-link "
                onClick={closeNavbar}
                style={{ marginRight: word }}
              >
                About
              </Link>
              <Link
                to="/Courses"
                className="nav-item nav-link"
                onClick={closeNavbar}
                style={{ marginRight: word }}
              >
                Courses
              </Link>
              <Link
                to="/Onlinecourses"
                className="nav-item nav-link"
                onClick={closeNavbar}
                style={{ marginRight: word }}
              >
                Online Programs
              </Link>
              <Link
                to="/Contact"
                className="nav-item nav-link"
                onClick={closeNavbar}
                style={{ marginRight: word }}
              >
                Contact
              </Link>
          
              <span className="nav-item nav-link navbar-nav ml-auto" >
                {isLoggedIn ? (
                  <a href="#" class="user-account" onClick={handleLogout}>
                    <i class="fa-solid fa-sign-out"></i> Logout
                  </a>
                ) : (
                  <a href="/Login" class="user-account">
                    <i class="fa-solid fa-user-tie"></i> Login / Register
                  </a>
                )}
              </span>
            
              {showLogoutToast && (
                <div className="logout-toast" style={{ position: 'fixed', top: '10px', right: '10px', backgroundColor: 'rgba(0, 0, 0, 0.7)', color: 'white', padding: '10px', borderRadius: '5px' }}>
                  Logout successful
                </div>
              )}
              {/* <Link
                to="/dashboard"
                className="nav-item nav-link"
                onClick={closeNavbar}
                style={{ marginRight: word }}
              >
                Dashboard
              </Link> */}
              {isAdmin && (


                <Link to="/dashboard" style={{ width: "20px", marginTop: "6px",marginLeft:"10px" }}>
                  <b>ADMIN</b>
                </Link>

              )}
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
}

export default NavBar;
