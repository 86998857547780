import React from "react";
import { Link } from "react-router-dom";

function Onlinecourse() {
  return (
    <div>
      {" "}
      <div>
        <img
          src="img/online.jpg"
          alt="Slide Image"
          className="img-fluid w-100"
          style={{ height: "500px", maxWidth: "1500px", objectFit: "cover" }}
        />
        <nav aria-label="breadcrumb" className="mt-3">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/home">Home</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Onlinecourses
            </li>
          </ol>
        </nav>
      </div>
      <div className="course-w3ls py-5">
        <div className="container py-xl-5 py-lg-3">
          <h1 style={{ marginTop: "-40px", textAlign: "center" }}>
            Online-Courses
          </h1>
          {/* Autocad */}
          <div className="row cource-list-agile cource-list-agile-2 pt-md-4 mb-4">
            <div className="col-lg-5 agile-course-main-3 mt-4">
              <img src="img/civil 3d.jpg" alt="" className="imgg-fluid" />
            </div>
            <div className="col-lg-7 agile-course-main text-right">
              <div className="w3ls-cource-second">
                <img
                  src="img/2.png"
                  alt=""
                  className="imgg-fluid img-poiscour mx-auto d-block mt-2"
                />
                <div className="px-md-5 px-4  pb-md-5 pb-4 text-right">
                  <h3 className="text-dark">Civil 3d</h3>

                  <p className="mt-3 mb-4 pl-lg-4">
                    The Civil, collaboration and machine-learning features
                    Architects, engineers and construction professionals use
                    AutoCAD.
                  </p>
                  <ul className="list-unstyled text-capitalize">
                    <li>
                      Jan-Mar 2024
                      <i className="fas fa-calendar-alt ml-3"></i>
                    </li>
                    <li className="my-3">
                      3 - 6 hours
                      <i className="fas fa-clock ml-3"></i>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="buttons-w3ls-2">
                <Link
                  className="btn button-cour-w3ls text-white"
                  href=""
                  to="/civil3d"
                  role="button"
                >
                  Learn More
                </Link>
                <Link
                  className="btn bg-dark text-white"
                  to="/Contact"
                  role="button"
                >
                  Apply Now
                </Link>
              </div>
            </div>
          </div>

          {/*  "STAAD Pro",*/}
          <div className="row cource-list-agile pt-4">
            <div className="col-lg-7 agile-course-main">
              <div className="w3ls-cource-first">
                <img
                  src="img/1.png"
                  alt=""
                  className="imgg-fluid img-poiscour mx-auto d-block mt-2"
                />
                <div className="px-md-5 px-4  pb-md-5 pb-4">
                  <h3 className="text-dark"> STAADr</h3>
                  <p className="mt-3 mb-4 pr-lg-5">
                    You can design, analyze, and document structural projects –
                    anywhere in the world, with any material using STAAD..
                  </p>
                  <ul className="list-unstyled text-capitalize">
                    <li>
                      <i className="fas fa-calendar-alt mr-3"></i>Jan-Mar 2024
                    </li>
                    <li className="my-3">
                      <i className="fas fa-clock mr-3"></i>4 - 6 hours
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-5 agile-course-main-2 mt-4">
              <img src="img/Staadp.jpg" alt="" className="imgg-fluid" />
            </div>
            <div className="buttons-w3ls">
              <Link
                className="btn button-cour-w3ls text-white"
                href=""
                to="/staadpro"
                role="button"
              >
                Learn More
              </Link>
              <Link
                className="btn bg-dark text-white"
                to="/Contact"
                role="button"
              >
                Apply Now
              </Link>
            </div>
          </div>

          {/* Revits*/}
          <div className="row cource-list-agile cource-list-agile-2 pt-md-4 mb-4">
            <div className="col-lg-5 agile-course-main-3 mt-4">
              <img src="img/Revitsp.jpg" alt="" className="imgg-fluid" />
            </div>
            <div className="col-lg-7 agile-course-main text-right">
              <div className="w3ls-cource-second">
                <img
                  src="img/2.png"
                  alt=""
                  className="imgg-fluid img-poiscour mx-auto d-block mt-2"
                />
                <div className="px-md-5 px-4  pb-md-5 pb-4 text-right">
                  <h3 className="text-dark">Revit Structure</h3>

                  <p className="mt-3 mb-4 pl-lg-4">
                    Streamline projects, from design concept to fabrication,
                    with Revit® Building Information Modelling software.
                  </p>
                  <ul className="list-unstyled text-capitalize">
                    <li>
                      Jan-Mar 2024
                      <i className="fas fa-calendar-alt ml-3"></i>
                    </li>
                    <li className="my-3">
                      3 - 6 hours
                      <i className="fas fa-clock ml-3"></i>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="buttons-w3ls-2">
                <Link
                  className="btn button-cour-w3ls text-white"
                  href=""
                  to="/revits"
                  role="button"
                >
                  Learn More
                </Link>
                <Link
                  className="btn bg-dark text-white"
                  to="/Contact"
                  role="button"
                >
                  Apply Now
                </Link>
              </div>
            </div>
          </div>

          {/*  title: "Etabs",*/}
          <div className="row cource-list-agile pt-4">
            <div className="col-lg-7 agile-course-main">
              <div className="w3ls-cource-first">
                <img
                  src="img/1.png"
                  alt=""
                  className="imgg-fluid img-poiscour mx-auto d-block mt-2"
                />
                <div className="px-md-5 px-4  pb-md-5 pb-4">
                  <h3 className="text-dark"> ETABS</h3>
                  <p className="mt-3 mb-4 pr-lg-5">
                    The ETABS is the ultimate integrated software package for
                    the structural analysis and design of buildings.
                  </p>
                  <ul className="list-unstyled text-capitalize">
                    <li>
                      <i className="fas fa-calendar-alt mr-3"></i>Jan-Mar 2024
                    </li>
                    <li className="my-3">
                      <i className="fas fa-clock mr-3"></i>4 - 6 hours
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-5 agile-course-main-2 mt-4">
              <img src="img/ETABp.jpg" alt="" className="imgg-fluid" />
            </div>
            <div className="buttons-w3ls">
              <Link
                className="btn button-cour-w3ls text-white"
                href=""
                to="/etabs"
                role="button"
              >
                Learn More
              </Link>
              <Link
                className="btn bg-dark text-white"
                to="/Contact"
                role="button"
              >
                Apply Now
              </Link>
            </div>
          </div>
          {/* title: "V-rays",*/}
          <div className="row cource-list-agile cource-list-agile-2 pt-md-4 mb-4">
            <div className="col-lg-5 agile-course-main-3 mt-4">
              <img src="img/Vrayp.jpg" alt="" className="imgg-fluid" />
            </div>
            <div className="col-lg-7 agile-course-main text-right">
              <div className="w3ls-cource-second">
                <img
                  src="img/2.png"
                  alt=""
                  className="imgg-fluid img-poiscour mx-auto d-block mt-2"
                />
                <div className="px-md-5 px-4  pb-md-5 pb-4 text-right">
                  <h3 className="text-dark">V-Ray </h3>

                  <p className="mt-3 mb-4 pl-lg-4">
                    V-Ray is a rendering engine that uses global illumination,
                    tracing, photon mapping, maps and directly computed global
                    illumination.
                  </p>
                  <ul className="list-unstyled text-capitalize">
                    <li>
                      Jan-Mar 2024
                      <i className="fas fa-calendar-alt ml-3"></i>
                    </li>
                    <li className="my-3">
                      3 - 6 hours
                      <i className="fas fa-clock ml-3"></i>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="buttons-w3ls-2">
                <Link
                  className="btn button-cour-w3ls text-white"
                  href=""
                  to="/vrays"
                  role="button"
                >
                  Learn More
                </Link>
                <Link
                  className="btn bg-dark text-white"
                  to="/Contact"
                  role="button"
                >
                  Apply Now
                </Link>
              </div>
            </div>
          </div>

          {/*  Revita*/}
          <div className="row cource-list-agile pt-4">
            <div className="col-lg-7 agile-course-main">
              <div className="w3ls-cource-first">
                <img
                  src="img/1.png"
                  alt=""
                  className="imgg-fluid img-poiscour mx-auto d-block mt-2"
                />
                <div className="px-md-5 px-4  pb-md-5 pb-4">
                  <h3 className="text-dark"> Revit Architecture</h3>
                  <p className="mt-3 mb-4 pr-lg-5">
                    Design, detail, analyze, and document structural systems
                    quickly and effectively.
                  </p>
                  <ul className="list-unstyled text-capitalize">
                    <li>
                      <i className="fas fa-calendar-alt mr-3"></i>Jan-Mar 2024
                    </li>
                    <li className="my-3">
                      <i className="fas fa-clock mr-3"></i>4 - 6 hours
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-5 agile-course-main-2 mt-4">
              <img src="img/Revitap.jpg" alt="" className="imgg-fluid" />
            </div>
            <div className="buttons-w3ls">
              <Link
                className="btn button-cour-w3ls text-white"
                href=""
                to="/revita"
                role="button"
              >
                Learn More
              </Link>
              <Link
                className="btn bg-dark text-white"
                to="/Contact"
                role="button"
              >
                Apply Now
              </Link>
            </div>
          </div>

          {/* title: "Photo shop"*/}
          <div className="row cource-list-agile cource-list-agile-2 pt-md-4 mb-4">
            <div className="col-lg-5 agile-course-main-3 mt-4">
              <img src="img/photo shop.jpg" alt="" className="imgg-fluid" />
            </div>
            <div className="col-lg-7 agile-course-main text-right">
              <div className="w3ls-cource-second">
                <img
                  src="img/2.png"
                  alt=""
                  className="imgg-fluid img-poiscour mx-auto d-block mt-2"
                />
                <div className="px-md-5 px-4  pb-md-5 pb-4 text-right">
                  <h3 className="text-dark">Photoshop </h3>

                  <p className="mt-3 mb-4 pl-lg-4">
                    Gorgeous images, rich graphics and incredible art — you can
                    do it all with Photoshop.
                  </p>
                  <ul className="list-unstyled text-capitalize">
                    <li>
                      Jan-Mar 2024
                      <i className="fas fa-calendar-alt ml-3"></i>
                    </li>
                    <li className="my-3">
                      3 - 6 hours
                      <i className="fas fa-clock ml-3"></i>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="buttons-w3ls-2">
                <Link
                  className="btn button-cour-w3ls text-white"
                  href=""
                  to="/photoshop"
                  role="button"
                >
                  Learn More
                </Link>
                <Link
                  className="btn bg-dark text-white"
                  to="/Contact"
                  role="button"
                >
                  Apply Now
                </Link>
              </div>
            </div>
          </div>

          {/*  id: 17,
  title: "3ds max""*/}
          <div className="row cource-list-agile pt-4">
            <div className="col-lg-7 agile-course-main">
              <div className="w3ls-cource-first">
                <img
                  src="img/1.png"
                  alt=""
                  className="imgg-fluid img-poiscour mx-auto d-block mt-2"
                />
                <div className="px-md-5 px-4  pb-md-5 pb-4">
                  <h3 className="text-dark"> 3DS MAX</h3>
                  <p className="mt-3 mb-4 pr-lg-5">
                    3D modeling, rendering, and animation software enables you
                    to create expansive worlds and premium designs.
                  </p>
                  <ul className="list-unstyled text-capitalize">
                    <li>
                      <i className="fas fa-calendar-alt mr-3"></i>Jan-Mar 2024
                    </li>
                    <li className="my-3">
                      <i className="fas fa-clock mr-3"></i>4 - 6 hours
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-5 agile-course-main-2 mt-4">
              <img src="img/Max3dp.jpg" alt="" className="imgg-fluid" />
            </div>
            <div className="buttons-w3ls">
              <Link
                className="btn button-cour-w3ls text-white"
                href=""
                to="/max3d"
                role="button"
              >
                Learn More
              </Link>
              <Link
                className="btn bg-dark text-white"
                to="/Contact"
                role="button"
              >
                Apply Now
              </Link>
            </div>
          </div>

          {/* "Lumion"*/}
          <div className="row cource-list-agile cource-list-agile-2 pt-md-4 mb-4">
            <div className="col-lg-5 agile-course-main-3 mt-4">
              <img src="img/Lumion.jpg" alt="" className="imgg-fluid" />
            </div>
            <div className="col-lg-7 agile-course-main text-right">
              <div className="w3ls-cource-second">
                <img
                  src="img/2.png"
                  alt=""
                  className="imgg-fluid img-poiscour mx-auto d-block mt-2"
                />
                <div className="px-md-5 px-4  pb-md-5 pb-4 text-right">
                  <h3 className="text-dark">Lumion </h3>

                  <p className="mt-3 mb-4 pl-lg-4">
                    Product 3D rendering software
                  </p>
                  <ul className="list-unstyled text-capitalize">
                    <li>
                      Jan-Mar 2024
                      <i className="fas fa-calendar-alt ml-3"></i>
                    </li>
                    <li className="my-3">
                      3 - 6 hours
                      <i className="fas fa-clock ml-3"></i>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="buttons-w3ls-2">
                <Link
                  className="btn button-cour-w3ls text-white"
                  href=""
                  to="/lumion"
                  role="button"
                >
                  Learn More
                </Link>
                <Link
                  className="btn bg-dark text-white"
                  to="/Contact"
                  role="button"
                >
                  Apply Now
                </Link>
              </div>
            </div>
          </div>

          {/*  google*/}
          <div className="row cource-list-agile pt-4">
            <div className="col-lg-7 agile-course-main">
              <div className="w3ls-cource-first">
                <img
                  src="img/1.png"
                  alt=""
                  className="imgg-fluid img-poiscour mx-auto d-block mt-2"
                />
                <div className="px-md-5 px-4  pb-md-5 pb-4">
                  <h3 className="text-dark"> Google Sketchup</h3>
                  <p className="mt-3 mb-4 pr-lg-5">
                    The joy of drawing by hand. The ease of super-smart 3D
                    modeling software..
                  </p>
                  <ul className="list-unstyled text-capitalize">
                    <li>
                      <i className="fas fa-calendar-alt mr-3"></i>Jan-Mar 2024
                    </li>
                    <li className="my-3">
                      <i className="fas fa-clock mr-3"></i>4 - 6 hours
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-5 agile-course-main-2 mt-4">
              <img src="img/Max3dp.jpg" alt="" className="imgg-fluid" />
            </div>
            <div className="buttons-w3ls">
              <Link
                className="btn button-cour-w3ls text-white"
                href=""
                to="/google"
                role="button"
              >
                Learn More
              </Link>
              <Link
                className="btn bg-dark text-white"
                to="/Contact"
                role="button"
              >
                Apply Now
              </Link>
            </div>
          </div>

          <div className="App">
            <div>
              <Link to="/Onlinecourses">
                <button style={{ marginTop: "40px" }}>Prev</button>
              </Link>
            </div>
          </div>
        </div>
      </div>{" "}
    </div>
  );
}
export default Onlinecourse;
