import { Component, Fragment } from "react";
import { Link } from "react-router-dom";

import AppItem from "../Components/AppItem";
import TabItem from "../Components/TabItem";

import "../AppStore.css";

const SEARCH_ICON_URL =
  "https://assets.ccbp.in/frontend/react-js/app-store/app-store-search-img.png";

const tabsList = [
  { tabId: "MECHANICAL CAD", displayText: "Mechanical Cad" },
  { tabId: "CIVIL CAD", displayText: "Civil Cad" },
  { tabId: "ARCHITECTURE CAD", displayText: "Architecture Cad" },
];

const appsList = [
  {
    appId: 0,
    appName: "Auto Cad",
    imageUrl: "img/Autop.jpg",
    category: "MECHANICAL CAD",
    description:
      "Auto Cad software application widely used for creating 2D and 3D drawings and models",
    link: "/Autocad",
  },
  {
    appId: 1,
    appName: "Solid Work",
    imageUrl: "img/Solidp.jpg",
    category: "MECHANICAL CAD",
    description: "3D CAD software for parametric design and simulation",
    link: "/Solidworks",
  },
  {
    appId: 2,
    appName: "Catia",
    imageUrl: "img/Catiap.jpg",
    category: "MECHANICAL CAD",
    description:
      "Catia Advanced CAD software for product design and engineering",
    link: "/Catia",
  },
  {
    appId: 3,
    appName: "Nx Cad",
    imageUrl: "img/Nxcadp.jpg",
    category: "MECHANICAL CAD",
    description:
      "Nx cad Integrated CAD/CAM/CAE software for product development.",
    link: "/Nx Cad",
  },
  {
    appId: 4,
    appName: "Ansys",
    imageUrl: "img/Ansysp.jpg",
    category: "MECHANICAL CAD",
    description:
      "The ansys used for engineering simulation, encompassing finite element analysis",
    link: "/Ansys",
  },
  {
    appId: 5,
    appName: "Creo",
    imageUrl: "img/Creop.jpg",
    category: "MECHANICAL CAD",
    description:
      "The creo Parametric 3D CAD software with a focus on product development",
    link: "/Creo",
  },
  {
    appId: 6,
    appName: "Fusion 360",
    imageUrl: "img/Fusionp.jpg",
    category: "MECHANICAL CAD",
    description:
      "The fusion 360 Cloud-based CAD/CAM/CAE tool for collaborative design",
    link: "/Fusion",
  },
  {
    appId: 7,
    appName: "GD&T",
    imageUrl: "img/gd&t.jpg",
    category: "MECHANICAL CAD",
    description:
      "Standardized symbolic language for specifying and communicating",
    link: "/Gd&t",
  },
  // {
  //   appId: 8,
  //   appName: "Inventer",
  //   imageUrl: "img/Inventerp.jpg",
  //   category: "MECHANICAL CAD",
  //   description: "Autodesk's 3D mechanical design and simulation software",
  //   link: "/Inventer",
  // },
  {
    appId: 9,
    appName: "AutoCad Civil",
    imageUrl: "img/Autocadcivilp.jpg",
    category: "CIVIL CAD",
    description:
      "CAD software tailored for civil engineering design and documentation.",
    link: "/Civilcad",
  },
  {
    appId: 10,
    appName: "Civil 3D",
    imageUrl: "img/civil 3d.jpg",
    category: "CIVIL CAD",
    description:
      "Structural analysis and design software for a wide range of structures.",
    link: "/Civil3d",
  },
  {
    appId: 11,
    appName: "Staad Pro",
    imageUrl: "img/Staadp.jpg",
    category: "CIVIL CAD",
    description:
      "Civil engineering design and documentation software with BIM capabilities.",
    link: "/Staadpro",
  },
  {
    appId: 12,
    appName: "Etabs",
    imageUrl: "img/ETABp.jpg",
    category: "CIVIL CAD",
    description:
      "Structural analysis and design software for buildings and structures",
    link: "/Etabs",
  },
  {
    appId: 13,
    appName: "V-rays",
    imageUrl: "img/Vrayp.jpg",
    category: "ARCHITECTURE CAD",
    description:
      "Photorealistic rendering plugin widely used with 3D modeling software for lifelike visualizations.",
    link: "/V-ray",
  },
  {
    appId: 14,
    appName: "3D-Max",
    imageUrl: "img/Max3dp.jpg",
    category: "ARCHITECTURE CAD",
    description:
      "3D modeling and animation software for creating stunning visual content.",
    link: "Max3d",
  },
  {
    appId: 15,
    appName: "Lumion",
    imageUrl: "img/Lumiop.jpg",
    category: "ARCHITECTURE CAD",
    description:
      "Real-time architectural visualization software for creating compelling, immersive renderings.",
    link: "/Lumion",
  },
  {
    appId: 16,
    appName: "Google SketchUp",
    imageUrl: "img/Googlep.jpg",
    category: "ARCHITECTURE CAD",
    description:
      "Intuitive 3D modeling software for conceptual design and visualization.",
    link: "/GoogleSketchup",
  },
  {
    appId: 17,
    appName: "Auto Cad Arch",
    imageUrl: "img/Autop.jpg",
    description:
      "Specialized CAD software for architectural design and drafting",
    category: "ARCHITECTURE CAD",
  },
  {
    appId: 18,
    appName: "Revit Arch",
    imageUrl: "img/Revitap.jpg",
    category: "ARCHITECTURE CAD",
    description:
      "empowering architects to design, visualize, and document building projects",
    link: "/Revit Architecture",
  },
  {
    appId: 19,
    appName: "Revit Structure",
    imageUrl: "img/Revitsp.jpg",
    category: "CIVIL CAD",
    description:
      "BIM-based software for structural design and documentation in building projects.",
    link: "/Revit Structure",
  },
  {
    appId: 20,
    appName: "PhotoShop",
    imageUrl: "img/photo shop.jpg",
    category: "ARCHITECTURE CAD",
    description:
      "Graphic design software used for enhancing and editing images, including architectural visualizations.",
    link: "/PhotoShop",
  },
];

class AppStore extends Component {
  state = {
    searchInput: "",
    activeTabId: tabsList[0].tabId,
  };

  setActiveTabId = (tabId) => {
    this.setState({ activeTabId: tabId });
  };

  onChangeSearchInput = (event) => {
    this.setState({ searchInput: event.target.value });
  };

  getActiveTabApps = (searchedApps) => {
    const { activeTabId } = this.state;
    const filteredApps = searchedApps.filter(
      (eachSearchedApp) => eachSearchedApp.category === activeTabId
    );

    return filteredApps;
  };

  getSearchResults = () => {
    const { searchInput } = this.state;
    const searchResults = appsList.filter((eachApp) =>
      eachApp.appName.toLowerCase().includes(searchInput.toLowerCase())
    );

    return searchResults;
  };

  render() {
    const { searchInput, activeTabId } = this.state;
    const searchResults = this.getSearchResults();
    const filteredApps = this.getActiveTabApps(searchResults);

    return (
      <Fragment>
        <div>
          <img
            src="img/footer1.jpg"
            alt="Slide Image"
            className="img-fluid w-100"
            style={{ height: "300px", Width: "800px" }}
          />
          <nav aria-label="breadcrumb" className="mt-3">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/home">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Courses
              </li>
            </ol>
          </nav>
        </div>

        <div className="app-container py-1" style={{ marginTop: "-50px" }}>
          <div className="app-store">
            <h1 className="heading">CAD COURSES</h1>
            <div className="search-input-container">
              <input
                type="search"
                placeholder="Search"
                className="search-input"
                value={searchInput}
                onChange={this.onChangeSearchInput}
              />
              <img
                src={SEARCH_ICON_URL}
                alt="search icon"
                className="search-icon"
              />
            </div>
            <ul className="tabs-list">
              {tabsList.map((eachTab) => (
                <TabItem
                  key={eachTab.tabId}
                  tabDetails={eachTab}
                  setActiveTabId={this.setActiveTabId}
                  isActive={activeTabId === eachTab.tabId}
                />
              ))}
            </ul>
            <ul className="apps-list">
              {filteredApps.map((eachApp) => (
                <AppItem key={eachApp.appId} appDetails={eachApp} />
              ))}
            </ul>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default AppStore;
