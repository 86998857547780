import React, { useState, Fragment } from "react";
import $ from "jquery";
import "../contact.css";
import "jquery-ui-dist/jquery-ui";
import { Link } from "react-router-dom";
import  { useNavigate } from "react-router-dom";


const Contact = () => {
  const [status, setStatus] = useState("Submit");
  const navigate = useNavigate();

 
  const [info, setInfo] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
  });
  const nameRegex = /^[a-zA-Z\s]+$/;
  const phoneRegex = /^\d{10}$/;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const handleChange = (event) => {
    setInfo({
      ...info,
      [event.target.name]: event.target.value,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(info);
    setStatus("Sending...");

    let response = await fetch(`/api/contactuspage`, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        Accept: "application/json",
        //'Content-Type': 'application/json',
        "Content-Type": "application/json;charset=utf-8",
      },
      
      body: JSON.stringify(info),
      //body: JSON.stringify(details),
    });
    navigate("/ThankYou");
    //console.log(details);
    console.log(info);
    setStatus("Submit");
    
    setInfo({ name: "", phone: "", email: "", message: "" });
    let result = await response.json();
   
    //alert(result.status);
  };

  return (
    <Fragment>
      <div>
        <img
          src="img/slide1.jpeg"
          alt="Slide Image"
          className="img-fluid w-100"
          style={{ height: "300px", maxWidth: "1500px", objectFit: "cover" }}
        />
        <nav aria-label="breadcrumb" className="mt-3">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/home">Home</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Contact
            </li>
          </ol>
        </nav>
      </div>{" "}
      <section className="contact">
        <h1
          className="section-header"
          style={{ textAlign: "center", color: "white" }}
        >
          Contact
        </h1>

        <div className="contact-wrapper">
          <form
            id="contact-form"
            className="form-horizontal"
            role="form"
            onSubmit={handleSubmit}
          >
            <div className="form-group">
              <div className="col-sm-12">
                <input
                  type="text"
                  className="control"
                  id="name"
                  name="name"
                  pattern="[a-zA-Z\s]+"
                  placeholder="Name"
                  value={info.name}
                  onChange={handleChange}
                  style={{ backgroundColor: "white", color: "black" }}
                  required
                />
              </div>
            </div>
            <div className="form-group">
              <div className="col-sm-12">
                <input
                  type="email"
                  name="email"
                  className="control"
                  placeholder="Email"
                  pattern="[^\s@]+@[^\s@]+\.[^\s@]+"
                  value={info.email}
                  onChange={handleChange}
                  style={{ backgroundColor: "white", color: "black" }}
                />
              </div>
            </div>
            <div className="form-group">
              <div className="col-sm-12">
                <input
                  type="text"
                  name="phone"
                  className="control"
                  id="phone"
                  placeholder="Phone"
                  pattern="\d{10}"
                  value={info.phone}
                  onChange={handleChange}
                  style={{ backgroundColor: "white", color: "black" }}
                  required
                />
              </div>
            </div>
           
            <textarea
              className="control"
              rows="10"
              placeholder="Message"
              name="message"
              value={info.message}
              onChange={handleChange}
              style={{
                height: "100px",
                backgroundColor: "white",
                color: "black",
                marginTop:'10px'
              }}
              required
            ></textarea>

            <button
              className="btn btn-primary send-button"
              id="submit"
              type="submit"
              value="SEND"
            >
              {status}
            </button>
          </form>

          <div className="direct-contact-container">
            <ul className="contact-list">
              <li className="list-item">
                <i className="fa fa-map-marker fa-2x">
                  <span className="contact-text text-white place">
                    {" "}
                    2nd Floor, B133/1, KSSIDC ITI Estate, Whitefield Road,
                    Mahadevpura Post Bangalore, Karnataka-560048.
                  </span>
                </i>
              </li>

              <li className="list-item ">
                <i className="fa fa-phone fa-2x ">
                  <span className="contact-text text-white phone">
                    <a
                      href="tel:1-212-555-5555"
                      title="Give me a call"
                      className="text-white"
                    >
                      {" "}
                      <b> 9901371386/9900502404</b>
                    </a>
                  </span>
                </i>
              </li>

              <li className="list-item">
                <i className="fa fa-envelope fa-2x">
                  <span className="contact-text text-white gmail">
                    <a
                      href="mailto:#"
                      title="Send me an email"
                      className="text-white"
                    >
                      info@caddeskmahadevpura.com
                    </a>
                  </span>
                </i>
              </li>
            </ul>

            <ul className="social-media-list">
              <li>
                <a
                  href="https://www.instagram.com/it_desk_cad_desk_mahadevpura/?igshid=MzRlODBiNWFlZA%3D%3D"
                  target="_blank"
                  className="contact-icon"
                >
                  <i className="fab fa-instagram" aria-hidden="true"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/CADDeskMahadevpura/?paipv=0&eav=Afa6zv96PoPRdnOYHc1nq_Z187ltdbcgBfBvKYjr5PybUF9VmfQn6oW-jWFQdvckUYk&_rdr"
                  target="_blank"
                  className="contact-icon"
                >
                  <i className="fab fa-facebook-f " aria-hidden="true"></i>
                </a>
              </li>
            </ul>
            <div></div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Contact;
