import React from "react";
import Feedback from "./Feedback";
import HomeSlider from "./HomeSlider";
import HomeAbout from "./HomeAbout";
import HomeVideo from "./HomeVideo";
import HomeCourses from "./HomeCourses";
import HomeService from "./HomeServices";


function Home() {
  return (
    <>
      <HomeSlider />

      <HomeAbout />
      <HomeService />
      <HomeCourses />
      <HomeVideo />
      <Feedback />
     
    </>
  );
}

export default Home;
