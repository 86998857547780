import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { PDFDocument, rgb, fontkit, StandardFonts } from 'pdf-lib';
import './CourseStudentList.css'; // Import CSS file for styling
import QRCode from 'qrcode';



function CourseStudentList() {
    const [courseStudents, setCourseStudents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedDateMap, setSelectedDateMap] = useState({});

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await axios.get('/api/courseStudents');
                setCourseStudents(response.data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error.message);
                setLoading(false);
            }
        }

        fetchData();
    }, []);


    const downloadCertificate = async (studentName, fatherName, course, softwarecovered, regno) => {
        const selectedDate = selectedDateMap[studentName] || '';
        if (!selectedDate) {
            alert('Please select a date before downloading the certificate.');
            return;
        }
        if (!studentName || !fatherName || !course || !softwarecovered || !regno) {
            alert('Please fill in all required fields before downloading the certificate.');
            return;
        }
        try {
            const existingPdfBytes = await fetch('/cet15.pdf').then(res => res.arrayBuffer());
            const pdfDoc = await PDFDocument.load(existingPdfBytes);

            const nunitoBoldFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);






            const pages = pdfDoc.getPages();
            const firstPage = pages[0];

            // Convert student's name, father's name, course to uppercase
            const upperCaseStudentName = studentName.toUpperCase();
            const upperCaseFatherName = fatherName.toUpperCase();
            const upperCaseCourse = course.toUpperCase();
            const uppercasesoftwarecovered = softwarecovered.toUpperCase();
            const uppercaseregno = regno.toUpperCase();
            // Draw student's name
            firstPage.drawText(upperCaseStudentName, {
                x: 310,
                y: 317,
                size: 13,
                font: nunitoBoldFont,
                color: rgb(0, 0, 0),
            });

            // Draw father's name
            firstPage.drawText(upperCaseFatherName, {
                x: 615,
                y: 317, // Adjust position as needed
                size: 13,
                font: nunitoBoldFont,
                color: rgb(0, 0, 0),
            });

            // Draw course
            firstPage.drawText(upperCaseCourse, {
                x: 310,
                y: 272, // Adjust position as needed
                size: 12,
                font: nunitoBoldFont,
                color: rgb(0, 0, 0),
            });
            firstPage.drawText(uppercasesoftwarecovered, {
                x: 310,
                y: 227,
                size: 12,
                font: nunitoBoldFont,
                color: rgb(0, 0, 0),
            });
            firstPage.drawText(uppercaseregno, {
                x: 670,
                y: 392,
                size: 12,
                font: nunitoBoldFont,
                color: rgb(0, 0, 0),
            });
            firstPage.drawText(selectedDate, {
                x: 612, // Adjust position as needed
                y: 227, // Adjust position as needed
                size: 12,
                font: nunitoBoldFont,
                color: rgb(0, 0, 0),
            });

            // Draw location (Bangalore Mahadevapura)
            const locationText = "BANGLORE MAHADEVPURA";
            firstPage.drawText(locationText, {
                x: 310,
                y: 182, // Adjust position as needed
                size: 12,
                font: nunitoBoldFont,
                color: rgb(0, 0, 0),
            });

            const duration = "120hours";
            firstPage.drawText(duration, {
                x: 610,
                y: 272, // Adjust position as needed
                size: 12,
                font: nunitoBoldFont,
                color: rgb(0, 0, 0),
            });
            const grade = "GOOD";
            firstPage.drawText(grade, {
                x: 611,
                y: 182, // Adjust position as needed
                size: 12,
                font: nunitoBoldFont,
                color: rgb(0, 0, 0),
            });

            const qrCodeData = `https://itdeskmahadevpura.com/certificate.html?REG_NO=${regno}`;


            const qrCodeDataURL = await QRCode.toDataURL(qrCodeData);

            // Convert QR code data URL to base64 string
            const base64Data = qrCodeDataURL.replace(/^data:image\/(png|jpg|jpeg);base64,/, '');

            // Embed QR code image in the PDF
            const qrCodeImage = await pdfDoc.embedPng(base64Data);

            // Define width and height for the QR code
            const qrCodeWidth = 80; // Adjust the width as needed
            const qrCodeHeight = 80; // Adjust the height as needed

            firstPage.drawImage(qrCodeImage, {
                x: 350, // Adjust position as needed
                y: 50, // Adjust position as needed
                width: qrCodeWidth,
                height: qrCodeHeight
            });

            const modifiedPdfBytes = await pdfDoc.save();
            const blob = new Blob([modifiedPdfBytes], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `Certificate_${studentName}.pdf`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);




        } catch (error) {
            console.error('Error generating certificate:', error.message);
        }
    };
    const handleDateChange = (e, studentName) => {
        const { value } = e.target;
        setSelectedDateMap(prevState => ({
            ...prevState,
            [studentName]: value,
        }));
    };

    return (
        <div>
            <h2>Course Students List</h2>
            {loading ? (
                <p>Loading...</p>
            ) : (
                <div className="card-container">
                    {courseStudents.map((student, index) => (
                        <div key={index} className="card">
                            <div>
                            <img
                                    src={`/${student.regno}.png`}
                                    alt={`Image of ${student.name}`}
                                    style={{ width: '100px', height: '100px',borderRadius:"50%",marginLeft:"-240px" }}
                                /><br />
                                <h3>Name: {student.name}</h3>
                               
                                <p><b style={{ marginLeft: "-270px" }}>Father's Name: </b></p><p style={{ marginLeft: "230px", marginTop: "-40px" }}>{student.fatherName}</p>
                                <p> <b style={{ marginLeft: "-345px" }}>Age:</b> </p><p style={{ marginLeft: "230px", marginTop: "-40px" }}>{student.age}</p>
                                {/* <p> <b style={{ marginLeft: "-272px" }}>College Name:</b> </p> <p style={{ marginLeft: "230px", marginTop: "-40px" }}>{student.collegeName}</p> */}
                                <p><b style={{ marginLeft: "-325px" }}> Course:</b></p><p style={{ marginLeft: "230px", marginTop: "-40px" }}> {student.course}</p>
                                <p > <b style={{ marginLeft: "-260px" }}>Certificate Type:</b> </p><p style={{ marginLeft: "230px", marginTop: "-40px" }}>{student.certificateType}</p>
                                <p> <b style={{ marginLeft: "-260px" }}>Year of Passing:</b></p><p style={{ marginLeft: "230px", marginTop: "-40px" }}> {student.yop}</p>

                                <p> <b style={{ marginLeft: "-250px" }}>softwarecovered: </b></p><p style={{ marginLeft: "230px", marginTop: "-40px" }}> {student.softwarecovered}</p>
                                <p> <b style={{ marginLeft: "-330px" }}>Regno: </b> </p><p style={{ marginLeft: "230px", marginTop: "-40px" }}>{student.regno}</p>
                            </div>
                            <div className="button-container">
                                <input
                                    type="date"
                                    value={selectedDateMap[student.name] || ''}
                                    onChange={(e) => handleDateChange(e, student.name)}
                                />
                                <button onClick={() => downloadCertificate(student.name, student.fatherName, student.course, student.softwarecovered, student.regno)}>
                                    Download Certificate
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}

export default CourseStudentList;
