import React, { Fragment } from "react";
// import Menu from './Menu';
import Footer from "../Components/Footer";
import Navbar from "../Components/NavBar";

import { Link } from "react-router-dom";

const About = () => {
  return (
    <Fragment>
      <div>
        <img
          src="img/slide2.jpeg"
          alt="Slide Image"
          className="img-fluid w-100"
          style={{ height: "300px", maxWidth: "1500px", objectFit: "cover" }}
        />
        <nav aria-label="breadcrumb" className="mt-3">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/home">Home</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              About Us
            </li>
          </ol>
        </nav>
      </div>

      <div className="about-page py-3">
        <div className="container py-xl-5 py-lg-3">
          <h1
            className="title text-capitalize  text-dark text-center mb-5"
            style={{ fontSize: "40px", marginTop: "-20px" }}
          >
            About Us
          </h1>
          <div className="row about-head-wthree">
            <div className="col-lg-6 left-abw3ls">
              <img
                src="img/about1.jpg"
                height={100}
                width={500}
                alt=""
                className="img-fluid"
              />
            </div>
            <div className="col-lg-6 right-abw3ls mt-lg-0 mt-sm-5 mt-4">
              <h4
                className="font-italic border-bottom text-center font-weight-bold pb-3 mb-4"
                style={{ color: "#ffc107" }}
              >
                Our History
              </h4>
              <p>
                The world is now witnessing a boom in the information technology
                industry, which has led to the emergence of a large number of
                jobs and opportunities; nevertheless, there is a gap between
                employers and workers since there are not enough skilled
                persons.{" "}
              </p>
              <p className="mt-3">
                {" "}
                I hope that everyone of our students is able to realize their
                full potential and seize many such opportunities on the market..
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="about-page-2 py-5">
        <div className="container-fluid py-xl-5 py-lg-3">
          <div className="row about-head-wthree-2">
            <div className="col-lg-4 left-abw3ls text-lg-left text-center">
              <img src="img/about3.jpg" alt="" className="img-fluid" />
            </div>
            <div className="col-lg-4 right-abw3ls my-lg-0 my-sm-5 my-4">
              <h4
                className="font-italic border-bottom text-center font-weight-bold pb-3 mb-4"
                style={{ color: "#ffc107" }}
              >
                Our Mission
              </h4>
              <p>
                In partnership with others on the team, you will create
                enhancements and/or new services and solutions based data
                acquisition and analytics. You will analyze, design, program,
                debug and modify software interface for our system.{" "}
              </p>
              <p className="mt-3">.</p>
            </div>
            <div className="col-lg-4 left-abw3ls text-lg-left text-center">
              <img src="img/about4.jpg" alt="" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>

      {/* <div className="video-choose-agile py-5">
				<div className="container py-xl-5 py-lg-3">
					<div className="row">
						<div className="col-lg-7 video">
							<h3 className="title text-capitalize font-weight-light text-dark text-center mb-5">our
								<span className="font-weight-bold">video</span>
							</h3>
							{/* <iframe src="assests/images/abtvid1.mp4"></iframe> */}
      {/* <video controls width="100%">
								<source src="assests/images/abtvid1.mp4" type="video/webm" /> */}
      {/* <source src="/video-example.mp4" type="video/mp4"/> */}

      {/* </video>
						</div>
						<div className="col-lg-5 events">
							<h3 className="title text-capitalize font-weight-light text-dark text-center mb-5">upcoming
								<span className="font-weight-bold">events</span>
							</h3>
							<div className="events-w3ls">
								<div className="d-flex">
									<div className="col-sm-2 col-3 events-up p-3 text-center">
										<h5 className="text-white font-weight-bold">1st week
											<span className="border-top font-weight-light pt-2 mt-2">Aug</span>
										</h5>
									</div>
									<div className="col-sm-10 col-9 events-right">
										<h4 className="text-dark">Admission Fair Spring </h4>
										<ul className="list-unstyled">
											<li className="my-2">
												<i className="far fa-clock mr-2"></i>10.00 am - 4.30 pm</li>
											<li>
												<i className="fas fa-map-marker mr-2"></i>Banglore</li>
										</ul>
									</div>
								</div>
							</div>
							<div className="d-flex my-4">
								<div className="col-sm-2 col-3 events-up p-3 text-center">
									<h5 className="text-white font-weight-bold">1st Week
										<span className="border-top font-weight-light pt-2 mt-2">Aug</span>
									</h5>
								</div>
								<div className="col-sm-10 col-9 events-right">
									<h4 className="text-dark">Admission Fair Spring </h4>
									<ul className="list-unstyled">
										<li className="my-2">
											<i className="far fa-clock mr-2"></i>10.00 am - 4.30 pm</li>
										<li>
											<i className="fas fa-map-marker mr-2"></i>Banglore.</li>
									</ul>
								</div>
							</div>
							<div className="d-flex">
								<div className="col-sm-2 col-3 events-up p-3 text-center">
									<h5 className="text-white font-weight-bold">1st Week
										<span className="border-top font-weight-light pt-2 mt-2">Aug</span>
									</h5>
								</div>
								<div className="col-sm-10 col-9 events-right">
									<h4 className="text-dark">Admission Fair Spring </h4>
									<ul className="list-unstyled">
										<li className="my-2">
											<i className="far fa-clock mr-2"></i>10.00 am - 4.30 pm</li>
										<li>
											<i className="fas fa-map-marker mr-2"></i>Banglore</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>


		
			<div className="news-section py-5">
				<div className="container py-xl-5 py-lg-3">
					<h3 className="title text-capitalize font-weight-light text-dark text-center mb-5">our lastest
						<span className="font-weight-bold">News</span>
					</h3>
					<div className="row news-grids-w3l pt-md-4">
						<div className="col-md-4 news-grid">
							<a href="blog.html">
								<img src="assests/images/background.jpeg" className="img-fluid" alt="" />
							</a>
							<div className="news-text">
								<div className="news-events-agile event-colo1 py-2 px-3">
									<h5 className="float-left">
										<a href="/" className="text-white">Aug</a>
									</h5>
									<div className="post-img float-right">
										<ul>
											<li>
												<a href="/">
													<i className="far fa-comments text-white"></i>
												</a>
											</li>
											<li className="mx-3">
												<a href="/">
													<i className="far fa-heart text-white"></i>
												</a>
											</li>
											<li>
												<a href="/">
													<i className="fas fa-share text-white"></i>
												</a>
											</li>
										</ul>
									</div>
									<div className="clearfix"></div>
								</div>
								<div className="detail-bottom">
									<h6 className="my-3">
										<a href="/" className="text-dark">
											Internship
										</a>
									</h6>
									<p>An internship is a structured and supervised work experience that allows individuals, typically students or recent graduates, to gain practical exposure and insights into their chosen fields of study or career paths.</p>
								</div>
							</div>
						</div>
						<div className="col-md-4 news-grid my-md-0 my-sm-5 my-4">
							<a href="blog.html">
								<img src="assests/images/background.jpeg" className="img-fluid" alt="" />
							</a>
							<div className="news-text">
								<div className="news-events-agile py-2 px-3">
									<h5 className="float-left">
										<a href="/" className="text-white">Aug</a>
									</h5>
									<div className="post-img float-right">
										<ul>
											<li>
												<a href="/">
													<i className="far fa-comments text-white"></i>
												</a>
											</li>
											<li className="mx-3">
												<a href="/">
													<i className="far fa-heart text-white"></i>
												</a>
											</li>
											<li>
												<a href="/">
													<i className="fas fa-share text-white"></i>
												</a>
											</li>
										</ul>
									</div>
									<div className="clearfix"></div>
								</div>
								<div className="detail-bottom">
									<h6 className="my-3">
										<a href="/" className="text-dark">
											Courses
										</a>
									</h6>
									<p>Courses are structured educational programs designed to impart knowledge, skills, and expertise in a specific subject or field of study. They are offered at various academic levels and cater to individuals with diverse interests and career goals.</p>
								</div>
							</div>
						</div>
						<div className="col-md-4 news-grid">
							<a href="/">
								<img src="assests/images/background.jpeg" className="img-fluid" alt="" />
							</a>
							<div className="news-text">
								<div className="news-events-agile event-colo3 py-2 px-3">
									<h5 className="float-left">
										<a href="/" className="text-white">Aug</a>
									</h5>
									<div className="post-img float-right">
										<ul>
											<li>
												<a href="/">
													<i className="far fa-comments text-white"></i>
												</a>
											</li>
											<li className="mx-3">
												<a href="/">
													<i className="far fa-heart text-white"></i>
												</a>
											</li>
											<li>
												<a href="/">
													<i className="fas fa-share text-white"></i>
												</a>
											</li>
										</ul>
									</div>
									<div className="clearfix"></div>
								</div>
								<div className="detail-bottom">
									<h6 className="my-3">
										<a href="/" className="text-dark">
											Placement
										</a>
									</h6>
									<p>Placement refers to the process of assisting individuals, usually students or job seekers, in finding suitable employment opportunities that align with their skills, qualifications, and career aspirations. </p>
								</div>
							</div> */}

      <h2 className="whyus"> Why Us</h2>
      <div className="py-2 whyus_container">
        <img
          src="img/why.jpg"
          alt="Image 1"
		  className="whyus_image"
        //   style={{ width: "40%", height: "30%", marginLeft: "105px", paddingBottom: "50px" }}
        />
        <div></div>
        <img
          src="img/reasons.jpg"
          alt="Image 2"
        //   style={{ width: "40%", height: "20%", marginLeft: "125px" }}
		className="myus_image"
        />
      </div>
    </Fragment>
  );
};

export default About;
