import React, { useState } from "react";
import { Link } from "react-router-dom";

const NavBar = () => {
  const [searchTerm, setSearchTerm] = useState("");

  // Your navigation links
  const navLinks = [
    { to: "/Ansys", label: "Ansys" },
    { to: "/Autocad", label: "Autocad" },
    { to: "/Catia", label: "Catia" },
    { to: "/Civil3d", label: "Civil3d" },
    { to: "/Creo", label: "Creo" },
    { to: "/Etabs", label: "Etabs" },
    { to: "/Fusion", label: "Fusion" },
    { to: "/Gd&t", label: "Gd&t" },
    { to: "/Google Sketchup", label: "Google Sketchup" },
    { to: "/Nxcad", label: "NxCAD" },
    { to: "/Lumion", label: "Lumion" },
    { to: "/Photoshop", label: "Photoshop" },
    { to: "/Revit Architecture", label: "Revit Architecture" },
    { to: "/Revit Structure", label: "Revit Structure" },
    { to: "/Staad Pro", label: "Staad Pro" },
    { to: "/Solidworks", label: "Solidworks" },
    { to: "/Vrays", label: "V-rays" },

    // Add more links as needed
  ];

  // Filtered links based on the search term
  const filteredLinks = navLinks.filter((link) =>
    link.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className=" header container-fluid topbar-top bg-warning">
      <div className="container">
        <div className="d-flex justify-content-between topbar py-2">
          <div className="d-flex align-items-center flex-shrink-0 topbar-info">
            <a
              href="#"
              className="me-4 text-secondary text-dark top"
              style={{ marginLeft: "-100px", fontSize: "15px" }}
            >
              <i
                className="fas fa-map-marker-alt me-2 text-dark text-bold top "
                style={{ marginTop: "-15px" }}
              ></i>
              <b> Mahadevpura, Bangalore</b>
            </a>
            <a
              href="#"
              className="me-4 text-secondary text-dark top"
              style={{ fontSize: "15px" }}
            >
              <b>
                {" "}
                <i className="fas fa-phone-alt me-2 text-dark"></i>+91
                9901371386
              </b>
              {/* <video src="img/call.mp4" width={80} height={50}>
                91 9901371386
              </video> */}
            </a>
            <a
              href="#"
              className="text-secondary text-dark top"
              style={{ fontSize: "15px" }}
            >
              <i className="fas fa-envelope me-2 text-dark"></i>
              <b> info@caddeskmahadevpura.com</b>
            </a>
          </div>

          <nav>
            <div
              className="navbar navbar-light navbar-expand-lg top"
              style={{ marginLeft: "70px", marginTop: "-10px" }}
            >
              <div className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle bg-light text-dark"
                  data-bs-toggle="dropdown"
                  style={{
                    height: "40px",
                    borderRadius: "30px",
                    textAlign: "center",
                    padding: "0,10px",
                  }}
                >
                  Search Courses
                </Link>

                <div
                  className="dropdown-menu m-0 text-dark bg-warning text-bold bg-primary"
                  style={{
                    maxHeight: "200px",
                    // Adjust the max height as needed
                    overflowY: "auto",
                  }}
                >
                  <form className="px-1 py-1">
                    <div className="mb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        value={searchTerm}
                        style={{ width: "100px" }}
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                    </div>
                  </form>

                  {filteredLinks.length > 0 ? (
                    filteredLinks.map((link, index) => (
                      <Link
                        key={index}
                        to={link.to}
                        className="nav-link nav-item text-dark py-1"
                      >
                        {link.label}
                      </Link>
                    ))
                  ) : (
                    <p className="dropdown-item text-muted">No results found</p>
                  )}
                </div>
              </div>
            </div>
          </nav>

          <Link
            to="/Internship"
            className="  me-4 text-primary text-dark text-bold top"
            style={{ marginLeft: "00px" }}
          >
            <b> Internship</b>
          </Link>
          <div className="link-with-bar"></div>
          <Link
            to="/Placement"
            className="  me-4 text-primary text-dark text-bold top"
            style={{ marginLeft: "-80px" }}
          >
            <b>Placement</b>
          </Link>

          <div className=" d-flex align-items-center justify-content-center topbar-icon ">
            <a
              href="https://www.instagram.com/it_desk_cad_desk_mahadevpura/?igshid=MzRlODBiNWFlZA%3D%3D"
              className="me-4 "
            >
              {/* <i className="fab fa-facebook-f text-dark top"></i> */}
              <img
                src="img/insta.png"
                width={40}
                height={30}
                style={{ marginTop: "-23px" }}
              ></img>
            </a>

            <a
              href="https://www.facebook.com/CADDeskMahadevpura/?paipv=0&eav=Afa6zv96PoPRdnOYHc1nq_Z187ltdbcgBfBvKYjr5PybUF9VmfQn6oW-jWFQdvckUYk&_rdr"
              className="me-4"
            >
              {/* <i className="fab fa-instagram text-dark top"></i> */}
              <img
                src="img/facebook.png"
                width={40}
                height={30}
                style={{ marginTop: "-23px" }}
              ></img>
            </a>
          </div>
        </div>
      </div>{" "}
    </div>
  );
};
export default NavBar;
