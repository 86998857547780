import React, { useState, Fragment } from "react";
import "../style.scss";
import Civil3d from "./Civil3d";
const ListToggle = () => {
  const [showList1, setShowList1] = useState("");
  const [showList2, setShowList2] = useState("");
  const [showList3, setShowList3] = useState("");
  const [showList4, setShowList4] = useState("");
  const [showList5, setShowList5] = useState("");
  const [showList6, setShowList6] = useState("");
  const [showList7, setShowList7] = useState("");
  const [showList8, setShowList8] = useState("");
  const toggleList = (listNumber) => {
    if (listNumber === 1) {
      setShowList1(!showList1);
      setShowList2(false);
      setShowList3(false);
      setShowList4(false);
      setShowList5(false);
      setShowList6(false);
      setShowList7(false);
      setShowList8(false);
    } else if (listNumber === 2) {
      setShowList2(!showList2);
      setShowList1(false);
      setShowList3(false);
      setShowList4(false);
      setShowList5(false);
      setShowList6(false);
      setShowList7(false);
      setShowList8(false);
    } else if (listNumber === 3) {
      setShowList3(!showList3);
      setShowList2(false);
      setShowList1(false);
      setShowList4(false);
      setShowList5(false);
      setShowList6(false);
      setShowList7(false);
      setShowList8(false);
    } else if (listNumber === 4) {
      setShowList4(!showList4);
      setShowList1(false);
      setShowList3(false);
      setShowList2(false);
      setShowList5(false);
      setShowList6(false);
      setShowList7(false);
      setShowList8(false);
    } else if (listNumber === 5) {
      setShowList5(!showList5);
      setShowList1(false);
      setShowList3(false);
      setShowList4(false);
      setShowList2(false);
      setShowList6(false);
      setShowList7(false);
      setShowList8(false);
    } else if (listNumber === 6) {
      setShowList6(!showList6);
      setShowList1(false);
      setShowList3(false);
      setShowList4(false);
      setShowList2(false);
      setShowList7(false);
      setShowList8(false);
      setShowList5(false);
    } else if (listNumber === 7) {
      setShowList7(!showList7);
      setShowList1(false);
      setShowList3(false);
      setShowList4(false);
      setShowList2(false);
      setShowList5(false);
      setShowList6(false);
      setShowList8(false);
    } else if (listNumber === 8) {
      setShowList8(!showList8);
      setShowList1(false);
      setShowList3(false);
      setShowList4(false);
      setShowList2(false);
      setShowList5(false);
      setShowList7(false);
      setShowList6(false);
    }
  };
  return (
    <Fragment>
      {/* <div className="botam-navbar">
        <p style="margin-left: 15px; color: #fff">To avail the weekly free training offer <br />Sign up Now.</p>
        <a href="#corseform" className="active">To know course fee.</a>
        <a href="#corseform" className="active">Know More</a>
      </div> */}

      <div id="main-wrapper">
        <div className="site-wrapper-reveal">
          <div
            className="about-banner-wrap vision-space bg-img responsive_banner_courses"
            
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-6 section-space--pt_100">
                  <h1 classNameName="mobile-page-heading">Civil3d</h1>
                  <p classNameName="mobile-page-paragraph">
                    Autodesk 3ds Max is the most powerful software among
                    modeling and rendering tools..
                  </p>
                </div>
                <div className="col-lg-2"></div>
                <div className="col-lg-4">
                  <div
                    className="form-container mx-auto mx-xl-0 ml-xl-auto border"
                    id="corseform"
                    style={{
                      marginTop: "20px",
                      backgroundColor: "white",
                      alignItems: "center",
                      marginLeft: "100px",
                    }}
                  >
                    <div id="signup-form">
                      {/* <p style={{ color: "black" }}>Your Enquiry Successfully Submit</p> */}
                      <form
                        id="basic-form"
                        name="yoursite_wp_mail_from_name"
                        method="post"
                      />
                      <input type="hidden" name="courseId" value="753" />
                      <input type="hidden" name="category" value="16" />
                      <div
                        className="form-group"
                        
                      >
                        <label>
                          Name:
                          <input
                            type="text"
                            id="fname"
                            name="fname"
                            className="form-control"
                            placeholder="Enter Your Name"
                            value=""
                            required=""
                          />
                        </label>
                      </div>
                      <div className="form-group" >
                        <label>Email:</label>
                        <input
                          type="email"
                          id="email"
                          name="email"
                          className="form-control"
                          placeholder="Enter Your Email Address"
                          value=""
                          required=""
                        />
                      </div>
                      <div className="form-group" >
                        <label>Mobile:</label>
                        <input
                          type="number"
                          id="phone"
                          name="phone"
                          className="form-control"
                          placeholder="Enter Your mobile number"
                          value=""
                          required=""
                        />
                      </div>
                      <div
                        className="form-group mb-0"
                        
                      >
                        <div className="form-row">
                          <div className="form-group col-6">
                            <label className="control-label">State</label>
                            <select
                              id="states"
                              name="states"
                              className="form-control"
                              required=""
                            >
                              <option value="">SELECT STATE</option>
                              <option value="2">Andhra Pradesh</option>
                              <option value="5">Bihar</option>
                              <option value="12">Gujarat</option>
                              <option value="13">Haryana</option>
                              <option value="14">Himachal Pradesh</option>
                              <option value="16">Jharkhand</option>
                              <option value="17">Karnataka</option>
                              <option value="21">Maharashtra</option>
                              <option value="28">Punjab</option>
                              <option value="29">Rajasthan</option>
                              <option value="31">Tamil Nadu</option>
                              <option value="32">Telangana</option>
                              <option value="35">Uttar Pradesh</option>
                            </select>
                          </div>
                          <div className="form-group col-6 city">
                            <label className="control-label">Center</label>
                            <select
                              name="city"
                              id="city"
                              className="form-control center"
                              required=""
                            >
                              <option value="">SELECT CENTRE</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div
                        className="form-group mb-0"
                        style={{ marginLeft: "130px", paddingBottom: "30px" }}
                      >
                        <button
                          type="submit"
                          id="btn"
                          className="btn btn-primary contest_register_now"
                          name="register_users"
                        >
                          Apply Now
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a href="/">Home</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Catia
          </li>
        </ol>
      </nav>

      <div className="vision-overview  " style={{ marginTop: "-180px" }}>
        <div className="container">
          <div className="section-title-wrap text-center">
            <br />
            <br />
            <h3 className="heading">
              <span
                className="text-color-primary"
                style={{ fontSize: "25px", color: "#ffc107" }}
              >
                {" "}
                Training Highlights
              </span>
            </h3>
          </div>
          <div className="row">
            <div className="col-12 col-lg-4">
              <div className="item-container d-flex align-items-center">
                <div className="image-container">
                  <img
                    src="https://itdeskindia.com/wp-content/uploads/2021/02/1.png"
                    className="lazy-load"
                  />
                </div>
                <div className="content-container">
                  <h3 className="title">Instructor Led Training</h3>
                  <p className="description"></p>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <div className="item-container d-flex align-items-center">
                <div className="image-container">
                  <img
                    src="https://itdeskindia.com/wp-content/uploads/2021/02/2.png"
                    className="lazy-load"
                  />
                </div>
                <div className="content-container">
                  <h3 className="title">Weekly Doubt clearing Session</h3>
                  <p className="description"></p>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <div className="item-container d-flex align-items-center">
                <div className="image-container">
                  <img
                    src="https://itdeskindia.com/wp-content/uploads/2021/02/offline-revision-min.png"
                    className="lazy-load"
                  />
                </div>
                <div className="content-container">
                  <h3 className="title">Lifetime Assistance for Revision</h3>
                  <p className="description"></p>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <div className="item-container d-flex align-items-center">
                <div className="image-container">
                  <img
                    src="https://itdeskindia.com/wp-content/uploads/2021/02/LOGIN.jpg"
                    className="lazy-load"
                  />
                </div>
                <div className="content-container">
                  <h3 className="title">
                    Lifetime Login Access to Student Dashboard
                  </h3>
                  <p className="description"></p>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <div className="item-container d-flex align-items-center">
                <div className="image-container">
                  <img
                    src="https://itdeskindia.com/wp-content/uploads/2021/02/certificate-icon-10.png"
                    className="lazy-load"
                  />
                </div>
                <div className="content-container">
                  <h3 className="title">World-Wide Valid Certification</h3>
                  <p className="description"></p>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <div className="item-container d-flex align-items-center">
                <div className="image-container">
                  <img
                    src="https://itdeskindia.com/wp-content/uploads/2021/02/books-min.png"
                    className="lazy-load"
                  />
                </div>
                <div className="content-container">
                  <h3 className="title">
                    Study Material, Assignments and Projects
                  </h3>
                  <p className="description"></p>
                </div>
              </div>
            </div>
          </div>
          <br /> <br />
          <div
            className="section-title-wrap text-center"
            style={{ marginTop: "-140px" }}
          >
            <h3 className="heading">
              <span
                className="text-color-primary"
                style={{ fontSize: "30px", color: "#ffc107" }}
              >
                {" "}
                Course Highlights
              </span>
            </h3>
          </div>
          <div className="row">
            <div className="d-flex flex-row flex-lg-column align-items-lg-center mx-auto">
              <div className="dashed-border">
                <img
                  className="d-block d-lg-none"
                  src="https://itdeskindia.com/wp-content/themes/itdesk_theme/assets/images/dotted_line_mobile.svg"
                />
                <img
                  className="d-none d-lg-block"
                  src="https://itdeskindia.com/wp-content/themes/itdesk_theme/assets/images/dotted_line_desktop.svg"
                  width={1000}
                />
              </div>
              <div className="d-flex flex-column  align-items-center flex-lg-row justify-content-lg-center">
                <div className="item-container  justify-content-between d-flex flex-lg-column align-items-center ">
                  <div
                    className="number-container"
                    style={{ marginLeft: "10px" }}
                  >
                    <div className="number-box">1</div>
                    <div className="arrow-container">
                      <div className="arrow arrow-left"></div>
                      <div className="arrow arrow-right"></div>
                    </div>
                  </div>
                  <div className="content-container">
                    <p className="activity">
                      3D Modelling, Animation, Rendering and Visualization on a
                      Single Software
                    </p>
                  </div>
                </div>
                <div className="item-container d-flex flex-lg-column align-items-center">
                  <div className="number-container">
                    <div className="number-box">2</div>
                    <div className="arrow-container">
                      <div className="arrow arrow-left"></div>
                      <div className="arrow arrow-right"></div>
                    </div>
                  </div>
                  <div className="content-container">
                    <p className="activity">Interior & Exterior Modelling</p>
                  </div>
                </div>
                <div className="item-container d-flex flex-lg-column align-items-center">
                  <div className="number-container">
                    <div className="number-box">3</div>
                    <div className="arrow-container">
                      <div className="arrow arrow-left"></div>
                      <div className="arrow arrow-right"></div>
                    </div>
                  </div>
                  <div className="content-container">
                    <p className="activity">Architectural Walk-Through Animation</p>
                  </div>
                </div>
                <div className="item-container d-flex flex-lg-column align-items-center">
                  <div className="number-container">
                    <div className="number-box">4</div>
                    <div className="arrow-container">
                      <div className="arrow arrow-left"></div>
                      <div className="arrow arrow-right"></div>
                    </div>
                  </div>
                  <div className="content-container">
                    <p className="activity">
                      Rendering and Architectural Visualization
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row" style={{ marginTop: "-120px" }}>
            <div className="col-lg-12">
              <br />
              <br />
              {/* <!-- <h3 className="heading" style="font-size: 21px;">Course Overview</h3> --> */}
              <h3 className="heading">
                <span
                  className="text-color-primary"
                  style={{ fontSize: "30px", color: "#ffc107" }}
                >
                  {" "}
                  Course Overview
                </span>
              </h3>
              <br />
              <br />
              <div>
                <p style={{ marginTop: "-70px", fontSize: "15px",textAlign:'justify' }}>
                  Autodesk 3DS Max is a powerful software and is highly
                  recommended among modeling and rendering tools. 3DS Max
                  especially shows its strength in architecture, manufacturing,
                  industrial design, and many more like visual graphics models.
                  CAD DESK Mahadevpura offers the best classNameroom training in
                  India that helps the learner to get in-depth knowledge of
                  various tools and features available in the 3DS Max software.
                  During the initial days, 3DS Max was known as a 3D Studio and
                  3D Studio Max. With its artist-friendly user interface, it
                  saves a lot of time and accelerates manual steps that boost
                  productivity. 3DS Max works is widely used in designing 2D
                  cross-section shapes of 3D models. CAD DESK Mahadevpura
                  provides software training on a wide range of courses in the
                  field of CAD, CAM & CAE and they put great emphasis on
                  delivering quality training to students. Further, while taking
                  the coaching classNamees for 3DS Max, students are encouraged to
                  do an Internship and also get an option to go for industrial
                  visits. Further while taking the 3DS MAX software training,
                  students get many benefits like free & lifetime access to
                  student panel, study materials, e-books, tutorials and
                  projects which gives them the better understanding of the
                  concepts of software and also help them to grow in their
                  career. CAD DESK Mahadevpura students get an option to
                  transfer themselves to any of our CAD Franchise Centre in
                  India, even if they have been registered at CAD DESK
                  Mahadevpura Jaipur or at any other CADD center. After the
                  course completion, the student receives 3DS MAX certificate
                  and also gets placement assistance through which they can be
                  placed in any of the companies all over India..
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
        }}
      >
        <div style={{ flex: 1 }}>
          <h3 className="heading_course_content">
            <span
              className="text-color-primary responsive_course_content"
              
            >
              {" "}
              Course Contents
            </span>
          </h3>
          <div className="row">
            {" "}

            <div className="responsive_courese_image_container_mobile_view">
              <img
                src="img/3dcivilp.jpg"
                alt="Course Image"
                className="responsive_course_image_mobile_view"
              />
            </div>

            <div
              className="responsive_introduction_container"
            >
              <button
                className=" collapsed responsive_introduction_button"
                data-toggle="collapse"
                data-target="#collapse__111"
                aria-expanded="false"
                aria-controls="collapse__111"
                onClick={() => toggleList(1)}
               
              >
                {/* {showList1 ? 'Close List 1' : 'Show List 1'}Button */}{" "}
                {showList1 ? (
                  <i classNameName="fas fa-arrow-up"></i>
                ) : (
                  <i
                    classNameName="fas fa-arrow-down"
                    style={{ color: "black" }}
                  ></i>
                )}{" "}
                Introduction
              </button>
              {showList1 && (
                <ul>
                  <li>Introduction to CAD DESK</li>
                  <li>Navigating the User Interface</li>
                  <li>Application workspace and preferences</li>
                  <li>Navigating in viewports</li>
                </ul>
              )}
            </div>
            <div className="responsive_introduction_container">
              <button
                className=" collapsed responsive_introduction_button"
                data-toggle="collapse"
                data-target="#collapse__111"
                aria-expanded="false"
                aria-controls="collapse__111"
                onClick={() => toggleList(2)}
               
              >
                {/* {showList1 ? 'Close List 1' : 'Show List 1'}Button */}{" "}
                {showList2 ? (
                  <i classNameName="fas fa-arrow-up"></i>
                ) : (
                  <i
                    classNameName="fas fa-arrow-down"
                    style={{ color: "black" }}
                  ></i>
                )}{" "}
                Modeling
              </button>
              {showList2 && (
                <ul>
                  <li> Standard and Extended Primitives</li>
                  <li>Modelling with primitives and modifiers</li>
                  <li>Grid and Snap</li>
                  <li>Managing Layers</li>
                  <li>Boolean & Pro-Boolean</li>
                  <li>Hierarchy & Group</li>
                  <li>Editable Poly modeling tools</li>
                </ul>
              )}
            </div>
            <div className="responsive_introduction_container">
              <button
                className=" collapsed responsive_introduction_button"
                data-toggle="collapse"
                data-target="#collapse__111"
                aria-expanded="false"
                aria-controls="collapse__111"
                onClick={() => toggleList(3)}
               
              >
                {/* {showList1 ? 'Close List 1' : 'Show List 1'}Button */}{" "}
                {showList3 ? (
                  <i classNameName="fas fa-arrow-up"></i>
                ) : (
                  <i
                    classNameName="fas fa-arrow-down"
                    style={{ color: "black" }}
                  ></i>
                )}
                Components Creation
              </button>
              {showList3 && (
                <ul>
                  <li> Creating a Bed</li>
                  <li>Sofa set with Dinning table</li>
                  <li>Table & Chair</li>
                  <li>Doors & Windows</li>
                  <li>Stairs etc.</li>
                </ul>
              )}
            </div>
            <div className="responsive_introduction_container">
              <button
                className=" collapsed responsive_introduction_button"
                data-toggle="collapse"
                data-target="#collapse__111"
                aria-expanded="false"
                aria-controls="collapse__111"
                onClick={() => toggleList(4)}
               
              >
                {/* {showList1 ? 'Close List 1' : 'Show List 1'}Button */}{" "}
                {showList4 ? (
                  <i classNameName="fas fa-arrow-up"></i>
                ) : (
                  <i
                    classNameName="fas fa-arrow-down"
                    style={{ color: "black" }}
                  ></i>
                )}
                Rendering
              </button>
              {showList4 && (
                <ul>
                  <li> Lighting with photometric and daylight</li>
                  <li>Shading surfaces with materials</li>
                  <li>Mapping textures</li>
                  <li>Scene organization with Layers and Groups</li>
                  <li>Material</li>
                  <li>Rendering</li>
                </ul>
              )}
            </div>
            <div className="responsive_introduction_container">
              <button
                className=" collapsed responsive_introduction_button"
                data-toggle="collapse"
                data-target="#collapse__111"
                aria-expanded="false"
                aria-controls="collapse__111"
                onClick={() => toggleList(5)}
               
              >
                {/* {showList1 ? 'Close List 1' : 'Show List 1'}Button */}{" "}
                {showList5 ? (
                  <i classNameName="fas fa-arrow-up"></i>
                ) : (
                  <i
                    classNameName="fas fa-arrow-down"
                    style={{ color: "black" }}
                  ></i>
                )}
                Animation{" "}
              </button>
              {showList5 && (
                <ul>
                  <li> Walk-through Animation</li>
                </ul>
              )}
            </div>
            <div className="responsive_introduction_container">
              <button
                className=" collapsed responsive_introduction_button"
                data-toggle="collapse"
                data-target="#collapse__111"
                aria-expanded="false"
                aria-controls="collapse__111"
                onClick={() => toggleList(6)}
               
              >
                {/* {showList1 ? 'Close List 1' : 'Show List 1'}Button */}{" "}
                {showList6 ? (
                  <i classNameName="fas fa-arrow-up"></i>
                ) : (
                  <i
                    classNameName="fas fa-arrow-down"
                    style={{ color: "black" }}
                  ></i>
                )}{" "}
                import and export
              </button>
              {showList6 && <ul></ul>}
            </div>
            {/* <div style={{ textAlign: 'left',marginLeft:'200px' }}>
      <button className=" collapsed responsive_introduction_button" data-toggle="collapse" data-target="#collapse__111" aria-expanded="false" aria-controls="collapse__111" onClick={() => toggleList(7)} style={{backgroundColor:"#ffc107",width:'30%',borderRadius:'1em',marginLeft:'100px',margin: '10px auto',color:'black'}}>
        // {/* {showList1 ? 'Close List 1' : 'Show List 1'}Button */}{" "}
            {/*</div>{showList7 ? <i classNameName="fas fa-arrow-up"></i> : <i classNameName="fas fa-arrow-down" style={{color:'black'}}></i>} Drawing Module
      </button>
      {showList7 && (
        <ul>
    <li>Sheet Preparation</li> 
    <li>Drawing Views</li> 
    <li>Dimensioning</li> 
    <li>Annotations</li> 
    <li>Output as PDF</li> 
        </ul>
      )}</div>
       */}
          </div>
        </div>

        <div className="responsive_courese_image_container">
          <img
            src="img/3dcivilp.jpg"
            alt="Course Image"
            className="responsive_course_image"
          />
        </div>
      </div>

      <div className="contact-us-section-wrappaer ">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7 col-lg-7">
              <div className="conact-us-wrap-one">
                <br />
                <br />
                <h5 className="heading mb-20">
                  How will you get your certificate?
                </h5>
                <div className="center_details">
                  {/* <!--   <h5 className="heading mb-20">CERTIFICATE FAQ</h5> --> */}
                  <p>
                    Upon successful completion of the program, each intern
                    receives a prestigious Digital Marketing Internship
                    Certificate. This credential not only recognizes their
                    achievements but also enhances their employability.
                  </p>
                  <p style={{ textAlign: "justify" }}>
                    CAD DESK Mahadevpura is a pioneer in Mechanical/Civil
                    training and most of the industries give great recognition
                    to its certificate throughout India and abroad. CAD DESK
                    Mahadevpura Certificates are ISO verified which makes CAD
                    highly reputed comparing to the other local brands and helps
                    the student get prioritize while going for job opportunities
                    in the industry. CAD DESK Mahadevpura Software Training is
                    conducted by highly knowledgeable trainers which help
                    students to enhance their skill set by putting great
                    emphasizes on project-based learning.
                  </p>
                  {/* <!-- <a className="btn btn-primary">Get Certificate</a> --> */}
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-lg-5">
              <div className="contact-form-wrap">
                {/* <!-- <h5 className="heading mb-20">How will you get your certificate?</h5> --> */}
                <img
                  src="img/cert.jpg"
                  style={{
                    width: " 100%",
                    marginTop: "100px",
                    height: "370px",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
    </Fragment>
  );
};

export default ListToggle;
