import React from "react";
import Header from "./Header";
import NavBar from "./NavBar";
import Footer from "./Footer";
import { Link } from "react-router-dom";

function About() {
  return (
    <div className="container-fluid py-1">
      <div className="container py-1">
        <div className="row g-5">
          <div className="col-lg-6 col-md-12 wow fadeInUp" data-wow-delay=".3s">
            <div className="about-img">
              <div className="rotate-left bg-dark"></div>
              <div className="rotate-right bg-dark"></div>
              <img
                src="img/homeabout.jpg"
                className="img-fluid h-100"
                alt="img"
              />
              <div className="bg-white experiences">
                <h3>Since</h3>
                <h1 className="fw-bold">2020</h1>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 wow fadeInUp" data-wow-delay=".6s">
            <div className="about-item overflow-hidden">
              <h5 className="mb-2 px-3 py-1 text-dark rounded-pill d-inline-block border border-2 border-primary">
                CAD Desk Mahadevpura
              </h5>
              <h1 className="display-5 mb-2">
                {" "}
                Best CAD Desk Services Since 2020
              </h1>
              <p className="fs-5" style={{ textAlign: "justify" }}>
                All our engineers are designers and all our designers are
                engineers
              </p>
              <div className="row text-center">
                <div className="col-6 col-md-3">
                  <div className="p-4 bg-dark rounded d-flex align-items-center justify-content-center">
                    <i className="fas fa-city fa-4x text-primary"></i>
                  </div>
                  <div className="my-2">
                    <h5>AutoCAD</h5>
                    <h5>SolidWorks</h5>
                  </div>
                </div>
                <div className="col-6 col-md-3">
                  <div className="p-4 bg-dark rounded d-flex align-items-center justify-content-center">
                    <i className="fas fa-school fa-4x text-primary"></i>
                  </div>
                  <div className="my-2">
                    <h5>Catia</h5>
                    <h5>Nx CAD</h5>
                  </div>
                </div>
                <div className="col-6 col-md-3">
                  <div className="p-4 bg-dark rounded d-flex align-items-center justify-content-center">
                    <i className="fas fa-warehouse fa-4x text-primary"></i>
                  </div>
                  <div className="my-2">
                    <h5>Ansys</h5>
                    <h5>Creo</h5>
                  </div>
                </div>
                <div className="col-6 col-md-3">
                  <div className="p-4 bg-dark rounded d-flex align-items-center justify-content-center">
                    <i className="fas fa-hospital fa-4x text-primary"></i>
                  </div>
                  <div className="my-2">
                    <h5>Fusion 360</h5>
                    <h5>Lumion</h5>
                  </div>
                </div>
              </div>
              <Link to="/Courses">
                <button
                  type="button"
                  className="btn btn-primary border-0 rounded-pill px-4 py-3 mt-5"
                >
                  Find Courses
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
