import React, { useState } from 'react';


import { useLocation } from 'react-router-dom';
import Certificate from '../pages/Certificate';
import StudentForm from '../Components/StudentForm'

const Dashboard = () => {
    const location = useLocation();
    const username = location.state ? location.state.username : '';
    const [showCertificate, setShowCertificate] = useState(false);
    const [showStudentForm, setShowStudentForm] = useState(false);


    return (
        <div>
            <div class="container-scroller d-flex">

                <nav class="sidebar sidebar-offcanvas" id="sidebar">
                    <ul class="nav1">
                        {/* <li class="nav-item sidebar-category">
                            <p>Navigation</p>
                            <span></span>
                        </li> */}
                        <li class="nav-item">
                            <a class="nav-link" href="">
                                <i class="mdi mdi-view-quilt menu-icon"></i>
                                <span style={{fontSize:"30px"}} class="menu-title">Dashboard</span>
                                {/* <div class="badge badge-info badge-pill">2</div> */}
                            </a>
                        </li>
                        <li class="nav-item sidebar-category">
                            {/* <p>Components</p> */}
                            <span></span>
                        </li>

                        <li className="nav-item">
                            <button className='mdi mdi-palette menu-icon menu-title menu-arrow' onClick={() => setShowCertificate(!showCertificate)}>
                                Certificate
                            </button>
                        </li>
                        <li className="nav-item">
                            <button className='mdi mdi-palette menu-icon menu-title menu-arrow' onClick={() => setShowStudentForm(!showStudentForm)}>
                                Student form
                            </button>
                        </li>

                        {/* <li className="nav-item">
                            <button className='mdi mdi-palette menu-icon menu-title menu-arrow' onClick={() => setshowResume(!showResume)}>
                                Resumes
                            </button>
                        </li> */}

                      

                        <li class="nav-item">
                            {/* <a class="nav-link" href="">
                                <i class="mdi mdi-emoticon menu-icon"></i>
                                <span class="menu-title">Profile</span>
                            </a> */}
                        </li>
                        {/* <li class="nav-item">
                            <a class="nav-link" href="/">
                                <i class="mdi mdi-emoticon menu-icon"></i>
                                <span class="menu-title">Logout</span>
                            </a>
                        </li> */}



                    </ul>
                </nav>

                <div className="container page-body-wrapper">
            <nav className="navbar col-lg-12 col-12 px-0 py-0 py-lg-4 d-flex flex-row" />
            
        
 


                    <div class="main-panel">
                      
                        <div class="content-wrapper">


                            {showCertificate && <Certificate />}
                            {showStudentForm && <StudentForm />}
                            {/* {showResume && <Resumes />} */}

                        </div>



                    </div>

                </div>

            </div>
        </div>
    )
}

export default Dashboard
