const PlacementProcess = () => (
  <div>
    <h1 className="placement_procss_heading">Placement Process</h1>
    <p>“Network has grown to more than 150+ cities in 22 states of India.”</p>
    <p>
      CAD DESK is a leading training network of India in the field of
      CAD/CAM/CAE. Incorporated in year 2007, CAD DESK is a registered trademark
      of M/S Parshi training and technical services Pvt. Ltd. Since the
      inception, CAD DESK has developed as a leading franchise network and our
      network has grown to more than 150+ cities in 22 states of India. We have
      started operations in the overseas market as well.
    </p>
    <h1 className="Cad_desk_presention_heading">CAD DESK Presentation</h1>
    <p>
      CAD DESK focuses on student and works on development of his/her knowledge
      and skills.Various facilities are provided to them i.e. student kit,
      student panel, books and course material, Live projects, industrial
      visits, certification& Placement alerts. Certification process is
      initiated only after receiving feedback and project submission at all
      level.
    </p>
    <h1 className="state_heading">
      More than 150+ cities in 22 states of India.
    </h1>
    <p>
      CAD DESK focuses on student and works on development of his/her knowledge
      and skills.Various facilities are provided to them i.e. student kit,
      student panel, books and course material, Live projects, industrial
      visits, certification& Placement alerts. Certification process is
      initiated only after receiving feedback and project submission at all
      level.
    </p>
  </div>
);

export default PlacementProcess;
