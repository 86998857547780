import React, { useState, Fragment } from "react";
// import "../style.scss";
// import Civil3d from "./Civil3d";
const MsProject = () => {
  const [showList1, setShowList1] = useState("");
  const [showList2, setShowList2] = useState("");
  const [showList3, setShowList3] = useState("");
  const [showList4, setShowList4] = useState("");
  const [showList5, setShowList5] = useState("");
  const [showList6, setShowList6] = useState("");
  const [showList7, setShowList7] = useState("");
  const [showList8, setShowList8] = useState("");
  const toggleList = (listNumber) => {
    if (listNumber === 1) {
      setShowList1(!showList1);
      setShowList2(false);
      setShowList3(false);
      setShowList4(false);
      setShowList5(false);
      setShowList6(false);
      setShowList7(false);
      setShowList8(false);
    } else if (listNumber === 2) {
      setShowList2(!showList2);
      setShowList1(false);
      setShowList3(false);
      setShowList4(false);
      setShowList5(false);
      setShowList6(false);
      setShowList7(false);
      setShowList8(false);
    } else if (listNumber === 3) {
      setShowList3(!showList3);
      setShowList2(false);
      setShowList1(false);
      setShowList4(false);
      setShowList5(false);
      setShowList6(false);
      setShowList7(false);
      setShowList8(false);
    } else if (listNumber === 4) {
      setShowList4(!showList4);
      setShowList1(false);
      setShowList3(false);
      setShowList2(false);
      setShowList5(false);
      setShowList6(false);
      setShowList7(false);
      setShowList8(false);
    } else if (listNumber === 5) {
      setShowList5(!showList5);
      setShowList1(false);
      setShowList3(false);
      setShowList4(false);
      setShowList2(false);
      setShowList6(false);
      setShowList7(false);
      setShowList8(false);
    } else if (listNumber === 6) {
      setShowList6(!showList6);
      setShowList1(false);
      setShowList3(false);
      setShowList4(false);
      setShowList2(false);
      setShowList7(false);
      setShowList8(false);
      setShowList5(false);
    } else if (listNumber === 7) {
      setShowList7(!showList7);
      setShowList1(false);
      setShowList3(false);
      setShowList4(false);
      setShowList2(false);
      setShowList5(false);
      setShowList6(false);
      setShowList8(false);
    } else if (listNumber === 8) {
      setShowList8(!showList8);
      setShowList1(false);
      setShowList3(false);
      setShowList4(false);
      setShowList2(false);
      setShowList5(false);
      setShowList7(false);
      setShowList6(false);
    }
  };
  return (
    <Fragment>
      {/* <div className="botam-navbar">
        <p style="margin-left: 15px; color: #fff">To avail the weekly free training offer <br />Sign up Now.</p>
        <a href="#corseform" className="active">To know course fee.</a>
        <a href="#corseform" className="active">Know More</a>
      </div> */}

      <div id="main-wrapper">
        <div className="site-wrapper-reveal">
          <div
            className="about-banner-wrap vision-space bg-img"
            style={{ backgroundColor: "#302500", height: "600px" }}
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-6 section-space--pt_100">
                  <h1 style={{ color: "#fff", marginTop: "100px" }}>
                    MS Project
                  </h1>
                  <p style={{ color: "#fff" }}>
                    It manages project portfolio investments and delivers
                    success with the intended business value.
                  </p>
                </div>
                <div className="col-lg-2"></div>
                <div className="col-lg-4">
                  <div
                    className="form-container mx-auto mx-xl-0 ml-xl-auto border"
                    id="corseform"
                    style={{
                      marginTop: "20px",
                      backgroundColor: "white",
                      alignItems: "center",
                      marginLeft: "100px",
                    }}
                  >
                    <div id="signup-form">
                      {/* <p style={{ color: "black" }}>Your Enquiry Successfully Submit</p> */}
                      <form
                        id="basic-form"
                        name="yoursite_wp_mail_from_name"
                        method="post"
                      />
                      <input type="hidden" name="courseId" value="753" />
                      <input type="hidden" name="category" value="16" />
                      <div
                        className="form-group"
                        style={{ marginLeft: "60px", marginTop: "30px" }}
                      >
                        <label>
                          Name:
                          <input
                            type="text"
                            id="fname"
                            name="fname"
                            className="form-control"
                            placeholder="Enter Your Name"
                            value=""
                            required=""
                          />
                        </label>
                      </div>
                      <div
                        className="form-group"
                        style={{ marginLeft: "60px" }}
                      >
                        <label>Email:</label>
                        <input
                          type="email"
                          id="email"
                          name="email"
                          className="form-control"
                          placeholder="Enter Your Email Address"
                          value=""
                          required=""
                        />
                      </div>
                      <div
                        className="form-group"
                        style={{ marginLeft: "60px" }}
                      >
                        <label>Mobile:</label>
                        <input
                          type="number"
                          id="phone"
                          name="phone"
                          className="form-control"
                          placeholder="Enter Your mobile number"
                          value=""
                          required=""
                        />
                      </div>
                      <div
                        className="form-group mb-0"
                        style={{ marginLeft: "60px" }}
                      >
                        <div className="form-row">
                          <div className="form-group col-6">
                            <label className="control-label">State</label>
                            <select
                              id="states"
                              name="states"
                              className="form-control"
                              required=""
                            >
                              <option value="">SELECT STATE</option>
                              <option value="2">Andhra Pradesh</option>
                              <option value="5">Bihar</option>
                              <option value="12">Gujarat</option>
                              <option value="13">Haryana</option>
                              <option value="14">Himachal Pradesh</option>
                              <option value="16">Jharkhand</option>
                              <option value="17">Karnataka</option>
                              <option value="21">Maharashtra</option>
                              <option value="28">Punjab</option>
                              <option value="29">Rajasthan</option>
                              <option value="31">Tamil Nadu</option>
                              <option value="32">Telangana</option>
                              <option value="35">Uttar Pradesh</option>
                            </select>
                          </div>
                          <div className="form-group col-6 city">
                            <label className="control-label">Center</label>
                            <select
                              name="city"
                              id="city"
                              className="form-control center"
                              required=""
                            >
                              <option value="">SELECT CENTRE</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div
                        className="form-group mb-0"
                        style={{ marginLeft: "130px", paddingBottom: "30px" }}
                      >
                        <button
                          type="submit"
                          id="btn"
                          className="btn btn-primary contest_register_now"
                          name="register_users"
                        >
                          Apply Now
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a href="/">Home</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Catia
          </li>
        </ol>
      </nav>

      <div className="vision-overview  " style={{ marginTop: "-180px" }}>
        <div className="container">
          <div className="section-title-wrap text-center">
            <br />
            <br />
            <h3 className="heading">
              <span
                className="text-color-primary"
                style={{ fontSize: "25px", color: "#ffc107" }}
              >
                {" "}
                Training Highlights
              </span>
            </h3>
          </div>
          <div className="row">
            <div className="col-12 col-lg-4">
              <div className="item-container d-flex align-items-center">
                <div className="image-container">
                  <img
                    src="https://itdeskindia.com/wp-content/uploads/2021/02/1.png"
                    className="lazy-load"
                  />
                </div>
                <div className="content-container">
                  <h3 className="title">Instructor Led Training</h3>
                  <p className="description"></p>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <div className="item-container d-flex align-items-center">
                <div className="image-container">
                  <img
                    src="https://itdeskindia.com/wp-content/uploads/2021/02/2.png"
                    className="lazy-load"
                  />
                </div>
                <div className="content-container">
                  <h3 className="title">Weekly Doubt clearing Session</h3>
                  <p className="description"></p>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <div className="item-container d-flex align-items-center">
                <div className="image-container">
                  <img
                    src="https://itdeskindia.com/wp-content/uploads/2021/02/offline-revision-min.png"
                    className="lazy-load"
                  />
                </div>
                <div className="content-container">
                  <h3 className="title">Lifetime Assistance for Revision</h3>
                  <p className="description"></p>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <div className="item-container d-flex align-items-center">
                <div className="image-container">
                  <img
                    src="https://itdeskindia.com/wp-content/uploads/2021/02/LOGIN.jpg"
                    className="lazy-load"
                  />
                </div>
                <div className="content-container">
                  <h3 className="title">
                    Lifetime Login Access to Student Dashboard
                  </h3>
                  <p className="description"></p>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <div className="item-container d-flex align-items-center">
                <div className="image-container">
                  <img
                    src="https://itdeskindia.com/wp-content/uploads/2021/02/certificate-icon-10.png"
                    className="lazy-load"
                  />
                </div>
                <div className="content-container">
                  <h3 className="title">World-Wide Valid Certification</h3>
                  <p className="description"></p>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <div className="item-container d-flex align-items-center">
                <div className="image-container">
                  <img
                    src="https://itdeskindia.com/wp-content/uploads/2021/02/books-min.png"
                    className="lazy-load"
                  />
                </div>
                <div className="content-container">
                  <h3 className="title">
                    Study Material, Assignments and Projects
                  </h3>
                  <p className="description"></p>
                </div>
              </div>
            </div>
          </div>
          <br /> <br />
          <div
            className="section-title-wrap text-center"
            style={{ marginTop: "-140px" }}
          >
            <h3 className="heading">
              <span
                className="text-color-primary"
                style={{ fontSize: "30px", color: "#ffc107" }}
              >
                {" "}
                Course Highlights
              </span>
            </h3>
          </div>
          <div className="row">
            <div className="d-flex flex-row flex-lg-column align-items-lg-center mx-auto">
              <div className="dashed-border">
                <img
                  className="d-block d-lg-none"
                  src="https://itdeskindia.com/wp-content/themes/itdesk_theme/assets/images/dotted_line_mobile.svg"
                />
                <img
                  className="d-none d-lg-block"
                  src="https://itdeskindia.com/wp-content/themes/itdesk_theme/assets/images/dotted_line_desktop.svg"
                  width={1000}
                />
              </div>
              <div className="d-flex flex-column  align-items-center flex-lg-row justify-content-lg-center">
                <div className="item-container  justify-content-between d-flex flex-lg-column align-items-center ">
                  <div
                    className="number-container"
                    style={{ marginLeft: "-120px" }}
                  >
                    <div className="number-box">1</div>
                    <div className="arrow-container">
                      <div className="arrow arrow-left"></div>
                      <div className="arrow arrow-right"></div>
                    </div>
                  </div>
                  <div className="content-container">
                    <p className="activity">Navigating the User Interface</p>
                  </div>
                </div>
                <div className="item-container d-flex flex-lg-column align-items-center">
                  <div className="number-container">
                    <div className="number-box">2</div>
                    <div className="arrow-container">
                      <div className="arrow arrow-left"></div>
                      <div className="arrow arrow-right"></div>
                    </div>
                  </div>
                  <div className="content-container">
                    <p className="activity">
                      Creating a work breakdown structure (WBS)
                    </p>
                  </div>
                </div>
                <div className="item-container d-flex flex-lg-column align-items-center">
                  <div className="number-container">
                    <div className="number-box">3</div>
                    <div className="arrow-container">
                      <div className="arrow arrow-left"></div>
                      <div className="arrow arrow-right"></div>
                    </div>
                  </div>
                  <div className="content-container">
                    <p className="activity">Formatting Gantt charts</p>
                  </div>
                </div>
                <div className="item-container d-flex flex-lg-column align-items-center">
                  <div className="number-container">
                    <div className="number-box">4</div>
                    <div className="arrow-container">
                      <div className="arrow arrow-left"></div>
                      <div className="arrow arrow-right"></div>
                    </div>
                  </div>
                  <div className="content-container">
                    <p className="activity">Importing and exporting projects</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row" style={{ marginTop: "-120px" }}>
            <div className="col-lg-12">
              <br />
              <br />
              {/* <!-- <h3 className="heading" style="font-size: 21px;">Course Overview</h3> --> */}
              <h3 className="heading">
                <span
                  className="text-color-primary"
                  style={{ fontSize: "30px", color: "#ffc107" }}
                >
                  {" "}
                  Course Overview
                </span>
              </h3>
              <br />
              <br />
              <div>
                <p style={{ marginTop: "-70px", fontSize: "15px",textAlign:'justify' }}>
                  Microsoft Project 2016 is a powerful project management
                  software. It is developed and sold by Microsoft, and used in
                  developing a schedule, tracking progress, budget management,
                  and analyzing workloads. CAD DESK offers the best classroom
                  trainings in India that helps the learner to get in-depth
                  knowledge of various tools and libraries available in the
                  AutoCAD software. Microsoft Project 2016 has a multiple
                  timeline view option available which helps in viewing
                  different phases of the project plan. Also, its resource
                  management tools assist you in tracking and monitoring all the
                  available resources and let you find out the best potential
                  way, in which they can be used for the project. CAD DESK has
                  various courses in the field of CAD, CAM & CAE and they always
                  prioritize giving the best quality training to students.
                  Moreover, while taking the coaching classes for MS Project,
                  students are encouraged to do Internship and also gets an
                  opportunity to go for industrial visits as well. During the MS
                  Project software training, students get many other benefits
                  like student panel, study materials, e-books, tutorials, and
                  numerous projects which gives them a better understanding of
                  the concepts of MS Project and also helps them to grow in
                  their career. CAD DESK students get an option to transfer
                  themselves to any of our CAD Franchise Centre in India, even
                  if they have been registered at CAD DESK Jaipur or at any
                  other CAD center. After the course completion, student not
                  just only get the MS Project certificate but also gets
                  placement assistance through which they can get placed in any
                  of the companies all over India.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
        }}
      >
        <div style={{ flex: 1 }}>
          <h3 className="heading">
            <span
              className="text-color-primary"
              style={{
                fontSize: "30px",
                color: "#ffc107",
                marginLeft: "200px",
              }}
            >
              {" "}
              Course Contents
            </span>
          </h3>
          <div className="row">
            {" "}
            <div
              style={{
                textAlign: "left",
                marginLeft: "200px",
                color: "black",
                marginTop: "-30px",
              }}
            >
              <button
                className="btn-link collapsed"
                data-toggle="collapse"
                data-target="#collapse__111"
                aria-expanded="false"
                aria-controls="collapse__111"
                onClick={() => toggleList(1)}
                style={{
                  backgroundColor: "#ffc107",
                  width: "30%",
                  borderRadius: "1em",
                  marginLeft: "100px",
                  margin: "10px auto",
                  color: "black",
                }}
              >
                {/* {showList1 ? 'Close List 1' : 'Show List 1'}Button */}{" "}
                {showList1 ? (
                  <i className="fas fa-arrow-up"></i>
                ) : (
                  <i
                    className="fas fa-arrow-down"
                    style={{ color: "black" }}
                  ></i>
                )}{" "}
                Introduction
              </button>

              {showList1 && (
                <ul>
                  <li> Introduction to MSP</li>
                  <li>Introduction to Project Management</li>
                  <li> Explore the Project User Interface</li>
                  <li>Configuring preferences</li>
                  <li> Creating a project with Task</li>
                  <li> Maintaining calendars</li>
                </ul>
              )}
            </div>
            <div style={{ textAlign: "left", marginLeft: "200px" }}>
              <button
                className="btn-link collapsed"
                data-toggle="collapse"
                data-target="#collapse__111"
                aria-expanded="false"
                aria-controls="collapse__111"
                onClick={() => toggleList(2)}
                style={{
                  backgroundColor: "#ffc107",
                  width: "30%",
                  borderRadius: "1em",
                  marginLeft: "100px",
                  margin: "10px auto",
                  color: "black",
                }}
              >
                {/* {showList1 ? 'Close List 1' : 'Show List 1'}Button */}{" "}
                {showList2 ? (
                  <i className="fas fa-arrow-up"></i>
                ) : (
                  <i
                    className="fas fa-arrow-down"
                    style={{ color: "black" }}
                  ></i>
                )}{" "}
                Creating a work breakdown structure (WBS)
              </button>
              {showList2 && (
                <ul>
                  {/* <li>Sketch tools</li>
                  <li>Sketch Task environment</li>
                  <li>Sketch different type of curve</li>
                  <li>Boolean & Pro-Boolean</li>
                  <li>Hierarchy & Group</li>
                  <li>Editable Poly modeling tools</li> */}
                </ul>
              )}
            </div>
            <div style={{ textAlign: "left", marginLeft: "200px" }}>
              <button
                className="btn-link collapsed"
                data-toggle="collapse"
                data-target="#collapse__111"
                aria-expanded="false"
                aria-controls="collapse__111"
                onClick={() => toggleList(3)}
                style={{
                  backgroundColor: "#ffc107",
                  width: "30%",
                  borderRadius: "1em",
                  marginLeft: "100px",
                  margin: "10px auto",
                  color: "black",
                }}
              >
                {/* {showList1 ? 'Close List 1' : 'Show List 1'}Button */}{" "}
                {showList3 ? (
                  <i className="fas fa-arrow-up"></i>
                ) : (
                  <i
                    className="fas fa-arrow-down"
                    style={{ color: "black" }}
                  ></i>
                )}
                Creating & Formatting schedule
              </button>
              {showList3 && (
                <ul>
                  {/* <li> Creating Solid Feature</li>
                  <li> Creating Cut Feature</li>                                                                                            
                  <li> Creating Boolean Operation</li>
                  <li>Create editing feature</li>
                  <li>Create pattern</li>
                  <li>Create basic block solid bodies</li>
                  <li>Create datum plane</li> */}
                </ul>
              )}
            </div>
            <div style={{ textAlign: "left", marginLeft: "200px" }}>
              <button
                className="btn-link collapsed"
                data-toggle="collapse"
                data-target="#collapse__111"
                aria-expanded="false"
                aria-controls="collapse__111"
                onClick={() => toggleList(4)}
                style={{
                  backgroundColor: "#ffc107",
                  width: "30%",
                  borderRadius: "1em",
                  marginLeft: "100px",
                  margin: "10px auto",
                  color: "black",
                }}
              >
                {/* {showList1 ? 'Close List 1' : 'Show List 1'}Button */}{" "}
                {showList4 ? (
                  <i className="fas fa-arrow-up"></i>
                ) : (
                  <i
                    className="fas fa-arrow-down"
                    style={{ color: "black" }}
                  ></i>
                )}
                Formatting Gantt charts
              </button>
              {showList4 && (
                <ul>
                  {/* <li> Lighting with photometric and daylight</li>
                  <li>Shading surfaces with materials</li>
                  <li>Mapping textures</li>
                  <li>Scene organization with Layers and Groups</li>
                  <li>Material</li>
                  <li>Rendering</li> */}
                </ul>
              )}
            </div>
            <div style={{ textAlign: "left", marginLeft: "200px" }}>
              <button
                className="btn-link collapsed"
                data-toggle="collapse"
                data-target="#collapse__111"
                aria-expanded="false"
                aria-controls="collapse__111"
                onClick={() => toggleList(5)}
                style={{
                  backgroundColor: "#ffc107",
                  width: "30%",
                  borderRadius: "1em",
                  marginLeft: "100px",
                  margin: "10px auto",
                  color: "black",
                }}
              >
                {/* {showList1 ? 'Close List 1' : 'Show List 1'}Button */}{" "}
                {showList5 ? (
                  <i className="fas fa-arrow-up"></i>
                ) : (
                  <i
                    className="fas fa-arrow-down"
                    style={{ color: "black" }}
                  ></i>
                )}
                Adding Constraints{" "}
              </button>
              {showList5 && <ul>{/* <li> Walk-through Animatio</li> */}</ul>}
            </div>
            <div style={{ textAlign: "left", marginLeft: "200px" }}>
              <button
                className="btn-link collapsed"
                data-toggle="collapse"
                data-target="#collapse__111"
                aria-expanded="false"
                aria-controls="collapse__111"
                onClick={() => toggleList(6)}
                style={{
                  backgroundColor: "#ffc107",
                  width: "30%",
                  borderRadius: "1em",
                  marginLeft: "100px",
                  margin: "10px auto",
                  color: "black",
                }}
              >
                {/* {showList1 ? 'Close List 1' : 'Show List 1'}Button */}{" "}
                {showList6 ? (
                  <i className="fas fa-arrow-up"></i>
                ) : (
                  <i
                    className="fas fa-arrow-down"
                    style={{ color: "black" }}
                  ></i>
                )}{" "}
                Setting a Baseline
              </button>
              {showList6 && (
                <ul>
                  <li> Introduction to assembly</li>
                  <li> Assembly Approach</li>
                  <li> Apply Constraint Assembly</li>
                  <li> Apply Connection Assembly</li>
                  <li> Explode View</li>
                </ul>
              )}
            </div>
            {/* <div style={{ textAlign: 'left',marginLeft:'200px' }}>
      <button className="btn-link collapsed" data-toggle="collapse" data-target="#collapse_111" aria-expanded="false" aria-controls="collapse_111" onClick={() => toggleList(7)} style={{backgroundColor:"#ffc107",width:'30%',borderRadius:'1em',marginLeft:'100px',margin: '10px auto',color:'black'}}>
        // {/* {showList1 ? 'Close List 1' : 'Show List 1'}Button */}{" "}
            {/*</div>{showList7 ? <i className="fas fa-arrow-up"></i> : <i className="fas fa-arrow-down" style={{color:'black'}}></i>} Drawing Module
      </button>
      {showList7 && (
        <ul>
    <li>Sheet Preparation</li> 
    <li>Drawing Views</li> 
    <li>Dimensioning</li> 
    <li>Annotations</li> 
    <li>Output as PDF</li> 
        </ul>
      )}</div>
       */}
          </div>
        </div>

        <div style={{ flex: 1, marginLeft: "20px", marginTop: "100px" }}>
          <img
            src="/Ms-projectp.jpg"
            alt="Course Image"
            style={{ width: "100%", marginLeft: "-80px", height: "auto" }}
          />
        </div>
      </div>

      <div className="contact-us-section-wrappaer ">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7 col-lg-7">
              <div className="conact-us-wrap-one">
                <br />
                <br />
                <h5 className="heading mb-20">
                  How will you get your certificate?
                </h5>
                <div className="center_details">
                  {/* <!--   <h5 className="heading mb-20">CERTIFICATE FAQ</h5> --> */}
                  <p>
                    Upon successful completion of the program, each intern
                    receives a prestigious Digital Marketing Internship
                    Certificate. This credential not only recognizes their
                    achievements but also enhances their employability.
                  </p>
                  <p style={{ textAlign: "justify" }}>
                    CAD DESK is a pioneer in Mechanical/Civil training and most
                    of the industries give great recognition to its certificate
                    throughout India and abroad. CAD DESK Certificates are ISO
                    verified which makes CAD highly reputed comparing to the
                    other local brands and helps the student get prioritize
                    while going for job opportunities in the industry. CAD DESK
                    Software Training is conducted by highly knowledgeable
                    trainers which help students to enhance their skill set by
                    putting great emphasizes on project-based learning.
                  </p>
                  {/* <!-- <a className="btn btn-primary">Get Certificate</a> --> */}
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-lg-5">
              <div className="contact-form-wrap">
                {/* <!-- <h5 className="heading mb-20">How will you get your certificate?</h5> --> */}
                <img
                  src="img/cert.jpg"
                  style={{
                    width: " 100%",
                    marginTop: "100px",
                    height: "370px",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
    </Fragment>
  );
};

export default MsProject;
